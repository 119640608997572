<template>
  <div class="main">
    <div id="container" tabindex="0"></div>
    <!-- <el-tree
      :data="$store.state.permit.conf"
      :props="defaultProps"
      @node-click="handleNodeClick"
      default-expand-all
    ></el-tree> -->
  </div>
</template>

<script>
// TODO:新哥看这里 路由 /testView （demo的相关代码放amap文件夹里了）
import draggable from 'yiyu-vant-ui/lib/_directive/draggable'
export default {
  name: 'testView',
  directives: {
    draggable,
  },
  data() {
    return {
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: '选项一' }, { text: '选项二' }, { text: '选项三' }],
      list: [],
      file: undefined,
      url: [
        'https://pic2.zhimg.com/v2-3f6b1cb8fddbd689e0c2753a2d2970e9_im.jpg',
        'https://pic2.zhimg.com/v2-3f6b1cb8fddbd689e0c2753a2d2970e9_im.jpg',
      ],

      activeIds: [1, 2],
      activeIndex: 0,
    }
  },

  mounted() {
    import(/* webpackChunkName: "attendance" */ '@amap/amap-jsapi-loader')
      .then((AMapLoader) => {
        AMapLoader.load({
          // key: '95a2f814b23389460087287aa6442ba9', // 申请好的Web端开发者Key，首次调用 load 时必填
          key: '6200124ab459b115a7cb863a860c5048', // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '2.0', // 指定要加载的 JSAPI 的版本
          // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          plugins: [
            'AMap.Geolocation', // 定位，提供了获取用户当前准确位置、所在城市的方法
          ],
          AMapUI: {
            version: '1.1', // AMapUI 缺省
            plugins: [
              'misc/PositionPicker', // 拖拽选址
              'misc/PoiPicker', // POI选点
              'misc/MarkerList',
            ], // 需要加载的 AMapUI ui插件
          },
          Loca: {
            // 是否加载 Loca， 缺省不加载
            version: '2.0', // Loca 版本
          },
        })
          .then((AMap) => {
            console.log(AMap, 999)
            // commit("setAMap", AMap);
            // resolve(AMap);

            let geolocation = new AMap.Geolocation({
              needAddress: true, // 返回formattedAddress // 是否需要将定位结果进行逆地理编码操作
              extensions: 'all',
              enableHighAccuracy: true, //是否使用高精度定位，默认:false
              timeout: 30000, //超过10秒后停止定位，默认：5s
              zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
              useNative: true, // 是否使用安卓定位sdk用来进行定位
              // noIpLocate: 1, // 1: 手机设备禁止使用IP定位
              GeoLocationFirst: true, // 默认为false，设置为true的时候可以调整PC端为优先使用浏览器定位，失败后使用IP定位
            })
            geolocation.getCurrentPosition((status, result) => {
              // this.geoLoading = false;
              if (status == 'complete') {
                console.log(
                  'loadAMap结束=====定位来源',
                  result.location_type,
                  result,
                )
                // let { formattedAddress, position } = result
                // this.curPosition = {
                //   addr: formattedAddress || "未知", //TODO: 文档有，但个人版没有返回
                //   lng: position.lng,
                //   lat: position.lat,
                // };
              } else {
                // this.geoResultDeal(result);
              }

              let map = new AMap.Map('container', {
                zoom: 16,
                options: {
                  // center: [this.lng, this.lat],
                },
              })

              let positionPicker = new window.AMapUI.PositionPicker({
                mode: 'dragMap', //设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
                map: map, //依赖地图对象
              })

              positionPicker.on('success', (result) => {
                console.log('拖拽选址成功!!!!', result)
                // if (window.navigator.userAgent != 'flutterApp') {
                // this.positionDeal(result.position)
                // }

                // this.regeocodeDeal(result.regeocode)
              })

              positionPicker.on('fail', (result) => {
                console.error('添加拖拽选址失败', result)
              })

              positionPicker.start()
            })
          })
          .catch((e) => {
            console.error('地图加载错误', e)
            // reject(e);
          })
      })
      .catch((e) => {
        console.error('地图导入失败', e)
        // reject(e);
      })
    //   let node = {
    //     val: 3,
    //     left: { val: 9, left: null, right: null },
    //     right: {
    //       val: 20,
    //       left: { val: 15, left: null, right: null },
    //       right: { val: 7, left: null, right: null },
    //     },
    //   }
    //   var averageOfLevels = function (root) {
    //     let res = []
    //     if (!root) return res
    //     res = [root.val]
    //     let queue = [root]
    //     while (queue.length) {
    //       let num = 0
    //       let nextLevel = []
    //       queue.forEach((item) => {
    //         if (item.left) {
    //           nextLevel.push(item.left)
    //           num += item.left.val
    //         }
    //         if (item.right) {
    //           nextLevel.push(item.right)
    //           num += item.right.val
    //         }
    //       })
    //       nextLevel.length && res.push(num / nextLevel.length)
    //       queue = nextLevel
    //     }
    //     return res
    //   }
    //   console.log(averageOfLevels(node))
    //   this.mergeTranslate()
    //   this.splitTranslate()
  },
  computed: {
    defaultProps() {
      return {
        children: 'children',
        label: (data, node) => {
          if (node.level == 1) {
            return data.title
          }
          return data.title + '==========' + this.getRoleName(data)
        },
      }
    },
    items() {
      let base = [
        {
          text: '浙江',
          children: [
            {
              id: 111,
              text: '1232',
              parentIndex: 0,
            },
            {
              id: 1,
              text: 1,
            },
            {
              id: 2,
              text: 2,
            },
          ],
        },
        { text: '江苏', children: [] },
      ]

      base.map((item) => {
        item.badge = this.getBadgeNum(item.children)
      })

      return base
    },
  },
  methods: {
    // getBadgeNum(item) {
    //   return item.filter((item1) => this.activeIds.includes(item1.id)).length
    // },
    // clickItem(data) {
    //   // if (this.activeIds.includes(data.id)) {
    //   //   this.items[data.parentIndex].badge++
    //   // } else {
    //   //   this.items[data.parentIndex].badge--
    //   // }
    // },
    getContainer() {
      // return document.querySelector('.single-tip-popver');
      // return document.querySelector('.main-content');
      return document.querySelector('.wrap')
    },
    getRoleName(data) {
      if (!data.role) {
        return '所有人'
      }
      let arr = []
      data.role &&
        data.role.forEach((item) => {
          arr.push(this.$store.state.basic.roleObj[item])
        })
      if (arr.length) {
        return arr.join('/')
      } else {
        return '只有管理员'
      }
    },
    mergeTranslate() {
      let en = { key: 'b' }
      let zh = { key: 'a' }
      let obj = {}
      Object.keys(en).forEach((key) => {
        obj[key] = {
          zh: undefined,
          en: undefined,
        }
      })
      for (let key in en) {
        obj[key]['en'] = en[key]
      }
      for (let key in zh) {
        obj[key]['zh'] = zh[key]
      }
      console.log(JSON.stringify(obj))
    },
    splitTranslate() {
      let obj = {
        key: {
          zh: 'a',
          en: 'b',
        },
      }
      let en = {}
      let zh = {}
      for (let key in obj) {
        en[key] = obj[key]['en']
        zh[key] = obj[key]['zh']
      }
      console.log('zh', JSON.stringify(zh))
      console.log('en', JSON.stringify(en))
    },
    handleNodeClick(data) {
      console.log(data)
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  overflow: auto;
  width: 100%;
}
.in {
  margin: 20px;
  background: url('../assets/images/cm_mgt.png') no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  mask: url('../assets/images/star.svg') no-repeat center;
  mask-size: 300% 300%;
  animation: maskStarIn 1s infinite alternate;
}
@keyframes maskStarIn {
  from {
    mask-size: 0 0;
  }
  to {
    mask-size: 300% 300%;
  }
}

.test {
  position: relative;
}
// .grid {
//   display: grid;
//   grid-template-columns: repeat(3, auto);
//   border: 1px solid red;
// }

.absolute {
  position: absolute;
  top: 0;
  background: #fff;
}
</style>

<template>
  <div class="data-chart" v-myLoad="isLoading">
    <section class="trend" v-myLoad="trendLoading">
      <div class="row-between">
        <div class="section-title">近期趋势</div>
        <div class="main-c" @click="toPage('larvaList')">查看更多</div>
      </div>
      <div class="date">{{ date4[0] }}-{{ date4[1] }}</div>
      <!-- 最近四天的日期 -->
      <data-field :dataObj="getDataObj('trend', form)"></data-field>
      <van-divider v-if="isTrendEmpty">暂无图表数据</van-divider>
      <div class="chart-wrap" v-if="!isTrendEmpty">
        <div id="trend-chart"></div>
      </div>
    </section>
    <section class="farm" v-myLoad="farmLoading">
      <div class="section-title">基地最近七日可投放池数估计</div>
      <van-divider v-if="!list['farm'].length">暂无数据</van-divider>
      <div class="see-more-pagination">
        <div class="grid-box">
          <div class="grid-header item"></div>
          <div class="grid-header item">成活率</div>
          <div class="grid-header item">1天</div>
          <div class="grid-header item">3天</div>
          <div class="grid-header item">5天</div>

          <template v-for="(item, index) in list['farm']">
            <template v-if="index < curNum">
              <div
                class="main-c item"
                :key="index + 'mc'"
                @click="toPage('farm', item)"
              >
                {{ item['mc_name'] }}
              </div>
              <div class="one item" :key="index + 'survival_rate'">
                {{ item['survival_rate'] }}%
              </div>
              <div class="one item" :key="index + 'one'">
                {{ item['day_1'] }}池
              </div>
              <div class="one item" :key="index + 'two'">
                {{ item['day_3'] }}池
              </div>
              <div class="one item" :key="index + 'three'">
                {{ item['day_5'] }}池
              </div>
            </template>
          </template>
        </div>

        <div v-if="Number(total) > Number(initNum)">
          <div v-if="Number(curNum) < Number(total)" class="btn-wrap">
            <van-button
              type="primary"
              class="btn more-btn"
              @click="more"
              size="small"
              >查看更多</van-button
            >
            <van-button
              type="info"
              class="btn hide-btn"
              size="small"
              @click="hide"
              v-if="Number(curNum) > Number(initNum)"
              >收起</van-button
            >
          </div>
          <van-divider v-else @click="hide">已经到底啦,点击收起 </van-divider>
        </div>
      </div>
    </section>
    <section class="quantity" v-myLoad="quantityLoading">
      <div class="section-title">往年预定量对比</div>
      <div class="row-between">
        <div class="date">{{ date30[0] }}-{{ date30[1] }}</div>
        <div class="main-c" @click="customerShow = true">客户筛选</div>
      </div>

      <div class="select-wrap">
        <select-tag
          :opts="selectTag"
          valueKey="value"
          :single="true"
          v-model="selectKey"
          @change="getSingleData('quantity')"
        ></select-tag>
      </div>
      <van-divider v-if="isQuantityEmpty">暂无图表数据</van-divider>
      <div class="chart-wrap">
        <div id="quantity-chart" v-if="!isQuantityEmpty"></div>
      </div>
    </section>
    <section class="prod" v-myLoad="prodLoading">
      <div class="section-title">产量统计</div>

      <div class="chart-wrap">
        <van-divider v-if="isProdEmpty">暂无自产/外调苗数据</van-divider>
        <div id="prod-chart-1" v-else></div>
      </div>
      <div class="chart-wrap">
        <van-divider v-if="isOutEmpty">暂无出苗/预定数据</van-divider>
        <div id="prod-chart-2" v-else></div>
      </div>
    </section>
    <tag-popup
      :show.sync="customerShow"
      v-if="customerShow"
      title="客户选择"
      :curKeys="customers"
      keyName="customer_key"
      :opts="customers_opt"
      valueKey="nickname"
      :canAll="true"
      @confirm="confirmPicker($event, 'customer')"
      @cancel="customerShow = false"
    ></tag-popup>
  </div>
</template>

<script>
import tagPopup from '@/components/popup/tag';
import { getFuture, getEveryDay } from '@/utils/date';
import dataField from '@/components/layout/dataField';
import * as echarts from 'echarts';
import selectTag from '@/components/tag/selectTag';

export default {
  name: 'dataChart',
  components: {
    // pagination,
    dataField,
    selectTag,
    tagPopup,
  },
  data() {
    return {
      activated: true,
      isLoading: false,
      trendLoading: false,
      farmLoading: false,
      quantityLoading: false,
      prodLoading: false,
      form: {},
      list: {
        trend: [],
        farm: [],
        quantity: [],
        prod: [],
        out: [],
      },
      initNum: 10,
      curNum: 10,
      pageSize: 10,
      date4: [],
      date7: [],
      date30: [],
      selectTag: [
        { key: '5', value: 'top5' },
        { key: '10', value: 'top10' },
        { key: undefined, value: '全部' },
      ],
      selectKey: ['5'],
      customerShow: false,
      customers: [],
      customers_opt: [],
      customerLoading: false,
      barColor: ['#5470c6', '#91cc75', '#fac858', '#ee6666'],
      isTrendEmpty: true,
      isQuantityEmpty: true,
      isProdEmpty: true,
      isOutEmpty: true,
    };
  },
  computed: {
    total() {
      return this.list.farm.length;
    },
    trendData() {
      let e_count_data = [];
      let launch_data = [];
      let re_count_data = [];
      // let h_count_data = [];
      let import_data = [];

      this.list.trend.forEach((item) => {
        e_count_data.push(item.e_count);
        launch_data.push(item.launch);
        re_count_data.push(Number(item.re_count) + Number(item.h_count));
        // h_count_data.push(item.h_count);
        import_data.push(item.import);
      });
      return {
        launch_data,
        e_count_data,
        re_count_data,
        import_data,
      };
    },
  },
  watch: {
    'list.trend': {
      deep: true,
      handler() {
        this.isTrendEmpty = true;

        this.list.trend.forEach((item) => {
          if (
            item.e_count ||
            item.launch ||
            item.re_count ||
            item.h_count ||
            item.import_data
          ) {
            this.isTrendEmpty = false;
          }
        });
      },
    },
  },
  mounted() {
    this.initDate();
    this.getData().then(() => {
      this.getTrendChart();
      this.getQuantityChart();
      this.getProdChart();
    });
    this.getCustomer();
  },
  methods: {
    initDate() {
      let today = new Date().toLocaleDateString();
      // 获取过去四天
      this.date4 = [
        new Date(getFuture(today, -1)).toLocaleDateString(),
        new Date(getFuture(today, 2)).toLocaleDateString(),
      ];
      // 获取过去七天
      this.date7 = [
        new Date(getFuture(today, -7)).toLocaleDateString(),
        new Date(getFuture(today, -1)).toLocaleDateString(),
      ];
      // 获取未来三十天
      this.date30 = [
        today,
        new Date(getFuture(today, 30)).toLocaleDateString(),
      ];
    },
    // 获取所有数据
    getData() {
      return new Promise((resolve) => {
        this.isLoading = true;
        this.$get('/overview/getOverview', {
          top: this.selectKey[0],
          customer_key: this.customers,
        }).then((res) => {
          if (res.code == 10000) {
            this.isLoading = false;
            this.form = res.data.trend;
            this.list.farm = res.data.pool;

            if (this.list.farm.length < 10) {
              this.curNum = this.list.farm.length;
            } else {
              this.curNum = 10;
            }

            this.list.trend = res.data.trend.list;
            this.list.out = res.data.sale;
            this.list.prod = res.data.prod;
            this.list.quantity = res.data.year;
            resolve();
          }
        });
      });
    },
    // 单独获取
    getSingleData(type) {
      switch (type) {
        //  往年预定量对比
        case 'quantity':
          this.quantityLoaidng = true;
          this.$get('/overview/getYear', {
            top: this.selectKey[0],
            customer_key: this.customers,
          }).then((res) => {
            if (res.code == 10000) {
              this.quantityLoaidng = false;
              this.list.quantity = res.data.list;
              this.getTrendChart();
            }
          });
          break;
      }
    },
    getTrendChart() {
      if (this.isTrendEmpty) return;
      // ['21/10/20（昨天）', '21/10/20（今天）', '21/10/21', '21/10/22']

      let tmp = getEveryDay(this.date4[0], this.date4[1]);
      tmp.forEach((item, index) => {
        tmp[index] = new Date(item).toLocaleDateString().slice(5);
      });
      tmp[0] += '(昨天)';
      tmp[1] += '(今天)';

      let rowRange = tmp;
      var app = {};

      var chartDom = document.getElementById('trend-chart');
      var myChart = echarts.init(chartDom);
      var option;

      var posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight',
      ];

      app.configParameters = {
        rotate: {
          min: -90,
          max: 90,
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right',
          },
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom',
          },
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {}),
        },
        distance: {
          min: 0,
          max: 100,
        },
      };

      var labelOption = {
        show: true,
        position: 'insideBottom',
        distance: 15,
        fontFamily: 'Helvetica Neue',
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        color: '#fff',
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {
            color: '#fff',
            fontSize: 16,
          },
        },
      };

      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          data: ['投放量', '估苗量', '出苗/关联', '外调苗'],
        },
        grid: {
          top: '3%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: rowRange,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '投放量',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series',
            },
            data: this.trendData.launch_data,
            itemStyle: {
              color: this.barColor[0],
            },
          },
          {
            name: '估苗量',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series',
            },
            data: this.trendData.e_count_data,
            itemStyle: {
              color: this.barColor[1],
            },
          },
          {
            name: '出苗/关联',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series',
            },
            data: this.trendData.re_count_data,
            itemStyle: {
              color: this.barColor[2],
            },
          },
          {
            name: '外调苗',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series',
            },
            data: this.trendData.import_data,
            itemStyle: {
              color: this.barColor[3],
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    getQuantityChart() {
      let year = new Date().getFullYear();
      let rowRange = [];
      let data = [];
      let last = [];
      let now = [];
      this.isQuantityEmpty = true;
      this.list.quantity.forEach((item) => {
        rowRange.push(item.title);
        item.list.forEach((item1) => {
          if (item1.quantity) this.isQuantityEmpty = false;
          if (item1.year == year) {
            now.push(item1.quantity);
          } else {
            last.push(item1.quantity);
          }
        });
      });

      if (this.isQuantityEmpty) return;
      this.$nextTick(() => {
        this.getRowChart(
          'quantity-chart',
          [String(year), String(year - 1)],
          rowRange,
          data
        );
      });
    },
    getProdChart() {
      let tmp = getEveryDay(this.date7[0], this.date7[1]);
      tmp.forEach((item, index) => {
        tmp[index] = new Date(item).toLocaleDateString().slice(5);
      });
      let rowRange = tmp;
      let prod = [[], []];
      let out = [[], []];

      this.isProdEmpty = true;
      this.list.prod.forEach((item) => {
        prod[0].push(item.sum_prod);
        prod[1].push(item.sum_import);

        if (item.sum_prod || item.sum_import) {
          this.isProdEmpty = false;
        }
      });

      this.isOutEmpty = true;
      this.list.prod.forEach((item) => {
        prod[0].push(item.sum_h);
        prod[1].push(item.sum_quantity);

        if (item.sum_h || item.sum_quantity) {
          this.isOutEmpty = false;
        }
      });

      if (!this.isProdEmpty) {
        this.$nextTick(() => {
          this.getRowChart('prod-chart-1', ['自产', '外调'], rowRange, prod);
        });
      }

      if (!this.isOutEmpty) {
        this.$nextTick(() => {
          this.getRowChart('prod-chart-2', ['出苗', '预定'], rowRange, out);
        });
      }
    },
    // 获取横向条形图表
    getRowChart(id, legend, rowRange, data) {
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;

      let series = [];

      data.forEach((item, index) => {
        series.push({
          name: legend[index],
          type: 'bar',
          itemStyle: {
            color: this.barColor[index],
          },
          data: item,
        });
      });

      option = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          data: legend,
          right: 0,
        },
        grid: {
          top: '3%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: 'category',
          data: rowRange,
        },
        series: series,
      };

      option && myChart.setOption(option);
    },
    // 获取竖向条形图
    getColChart() {},
    getDataObj(type, item = {}) {
      switch (type) {
        case 'trend':
          return [
            [
              {
                data: item.e_count,
                unitText: '万',
                descText: `估苗量`,
              },
              {
                data: item.quantity,
                unitText: '万',
                descText: `预定量`,
              },
              {
                data: item.re_count,
                unitText: '万',
                descText: `关联量`,
              },
              {
                data: item.h_count,
                unitText: '万',
                descText: `出苗量`,
              },
            ],
            [
              {
                data: item.quantity_15,
                unitText: '万',
                descText: `15后预定量`,
              },
              {
                data: item.launch_advise,
                unitText: '万',
                descText: `建议投放`,
              },
              {
                data: item.launch_real,
                unitText: '万',
                descText: `实际投放`,
              },
              {
                data: item.import,
                unitText: '万',
                descText: `外调苗`,
              },
            ],
          ];
      }
    },
    more() {
      this.curNum += this.pageSize;
    },
    hide() {
      this.curNum = this.initNum;
    },
    // 客户筛选(
    getCustomer() {
      this.customerLoading = true;
      this.$get('/overview/topCustomer').then((res) => {
        if (res.code == 10000) {
          this.customerLoading = false;
          this.customers_opt = res.data.list;
        }
      });
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'customer':
          this.customers = e;
          this.selectKey = [undefined];
          this.getSingleData('quantity');
          this.customerShow = false;
          break;
      }
    },
    toPage(type, item) {
      switch (type) {
        case 'farm':
          this.$toPage(this, 'farmDetail', item);
          break;
        case 'larvaList':
          this.$toPage(this, 'FF_produce', {
            tab: 'larva',
          });
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.data-chart {
  position: relative;
  padding: 0.5rem;

  section {
    border-bottom: 0.5rem solid @bgColor;
    padding: 0.5rem 0;
    position: relative;

    .section-title {
      font-size: 1.2rem;
      font-weight: bold;
      color: @titleColor;
      margin-bottom: 1rem;
    }

    &.trend {
      .date {
        margin-bottom: 1rem;
      }
    }

    &.quantity {
      .yiyu-select-tag {
        padding: 0;
      }
    }
  }

  .date {
    font-size: 1.2rem;
    color: @textColor;
    font-weight: 700;
    opacity: 0.7;
  }

  .grid-box {
    /* 声明一个容器 */
    display: grid;
    /*  声明列的宽度  */
    grid-template-columns: repeat(5, auto);
    /*  声明行间距和列间距  */
    // grid-gap: 20px;
    /*  声明行的高度  */
    // grid-template-rows: 100px 200px;

    .item {
      text-align: center;
      color: @textColor;
      padding: 0.5rem 0;
    }

    .grid-header {
      font-weight: bold;
      opacity: 0.7;
    }
  }

  .btn-wrap {
    padding: 1rem;
    display: flex;
    justify-content: space-around;

    .btn {
      opacity: 0.7;
    }
  }

  .chart-wrap {
    width: 100%;
    margin: 1rem 0;
    #trend-chart {
      width: 100%;
      height: 50vh;
    }

    #quantity-chart {
      width: 100%;
      height: 50vh;
    }

    #prod-chart-1,
    #prod-chart-2 {
      width: 100%;
      height: 50vh;
    }
  }

  .van-divider {
    border: 1px solid @lineColor;
  }
}
</style>

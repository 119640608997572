<template>
  <div class="mian">
    <yiyu-navbar tab-title="订单默认需求"></yiyu-navbar>
    <div class="main-content" v-myLoad="isLoading">
      <yiyu-form
        :_this="thisAlias"
        :formOpts="formOpts"
        :form="form"
        @submit="onSubmit"
        ref="form"
      >
      </yiyu-form>
      <fixed-btn
        btnTxt="保存"
        @click="toSubmit"
        :loading="btnLoading"
        :disabled="btnLoading"
      ></fixed-btn>
    </div>
    <yiyu-picker
      v-if="showBagPicker"
      :curKey="form.bag_key"
      :show.sync="showBagPicker"
      :columns="bagColumns"
      value-key="name"
      keyName="bag_key"
      @confirm="confirmPicker($event, 'bag')"
      @cancel="cancelPicker('bag')"
      :loading="bagLoading"
    >
      <template #empty>
        <div class="bag-empty" v-handle="'MORE_SETTING_LARVA'">
          <span class="btn" @click="addBag">去添加印刷袋</span>
        </div>
      </template>
    </yiyu-picker>
  </div>
</template>

<script>
import yiyuPicker from '@/components/popup/picker'
import yiyuForm from '@/components/form.vue'
import yiyuNavbar from '@/components/navbar'
import fixedBtn from '@/components/button/fixedBtn'
export default {
  name: 'orderSet',
  components: {
    yiyuNavbar,
    fixedBtn,
    yiyuForm,
    yiyuPicker,
  },
  data() {
    return {
      thisAlias: this,
      btnLoading: false,
      isLoading: true,
      bagLoading: false,
      form: {
        density: undefined,
        ice: undefined,
        bag_name: undefined,
        bag_key: undefined,
        salinity: undefined,
        temperature: undefined,
      },
      s_key: undefined, // 配置key
      showBagPicker: false,
    }
  },
  computed: {
    bagColumns() {
      return this.$store.state.setting.bags
    },
    formOpts() {
      let _this = this
      return [
        {
          title: '',
          child: [
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.density,
              name: 'density',
              label: '打包密度',
              placeholder: '请输入打包密度',
              rules: [{ required: false, message: '请输入打包密度' }],
              extra: '万/箱',
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.salinity,
              name: 'salinity',
              label: '盐度',
              placeholder: '请输入盐度',
              extra: '格数',
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.temperature,
              name: 'temperature',
              label: '温度',
              placeholder: '请输入温度',
              // rules: [{ required: true, message: "请输入温度" }],
              extra: '℃',
            },
            {
              required: false,
              colon: true,
              formType: 'select',
              value: this.form.bag_name,
              name: 'bag_name',
              label: '印刷袋',
              placeholder: '请选择印刷袋',
              click() {
                _this.showBagPicker = true
              },
            },
            {
              required: false,
              colon: true,
              formType: 'radio',
              value: this.form.ice,
              name: 'ice',
              label: '冰瓶',
              opts: [
                {
                  label: '不需要',
                  name: 0,
                },
                {
                  label: '需要',
                  name: 1,
                },
              ],
            },
          ],
        },
      ]
    },
  },
  mounted() {
    this.getData()
    this.getBagData()
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/setting/getSetting', {}).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          let data = res.data

          data.order_demand = data.order_demand || {}
          // 数据响应
          for (let key in this.form) {
            data.order_demand[key] =
              data.order_demand[key] == undefined
                ? this.form[key]
                : data.order_demand[key]
          }

          data.order_demand.ice = Number(data.order_demand.ice)

          this.form = data.order_demand
        }
      })
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    onSubmit() {
      this.btnLoading = true
      this.$post('/setting/addSetting', {
        s_key: this.s_key,
        order_demand: JSON.stringify(this.form),
      }).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.$toast.success('保存成功！')
        }
      })
    },
    confirmPicker(val) {
      this.form.bag_key = val[0].bag_key
      this.form.bag_name = val[0].name
      this.showBagPicker = false
    },
    getBagData() {
      if (!this.$store.state.setting.getBags) {
        this.bagLoaindg = true
        this.$store.dispatch('getBag', {}).then((res) => {
          if (res.code == 10000) {
            this.bagLoaindg = false
          }
        })
      }
    },
    addBag() {
      this.$push(this, { path: '/bagSet' })
    },
  },
}
</script>

<style></style>

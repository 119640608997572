export default {
  data() {
    return {
      noticeTypeObj: {
        1: {
          title: '育苗批次',
          routeName() {
            return 'larvaProduct'
          },
          keys: ['l_key', 'mc_key'],
        },
        2: {
          title: '种虾批次',
          routeName() {
            return 'sBatchDetail'
          },
          keys: ['ss_key'],
        },
        3: {
          title: '藻类批次',
          routeName() {
            return 'algaListDetail'
          },
          keys: ['algae_key'],
        },
        4: {
          title: '订单',
          routeName() {
            return 'salesDetail'
          },
          keys: ['or_id'],
        },
        5: {
          title: '养殖反馈',
          routeName() {
            return 'feedback'
          },
          keys: ['cf_key'],
        },
        6: {
          title: '到货反馈',
          routeName() {
            return 'feedback'
          },
          keys: ['cf_key'],
        },
        7: {
          title: '藻类分配',
          routeName() {
            return 'algaDistributeRecordDetail'
          },
          keys: ['rr_key', 'sign_key'],
        },
        8: {
          title: '种虾每日数据列表',
          routeName() {
            return 'sDailyList'
          },
          keys: ['ss_key'],
        },
        9: {
          title: '种虾每日数据详情',
          routeName() {
            return 'sDailyDetail'
          },
          keys: ['ss_key', 'sr_key'],
        },
        10: {
          title: '基地详情',
          routeName(obj) {
            if (obj.ws_type == 1) return 'farmDetail'
            else if (obj.ws_type == 2) return 'sBaseDetail'
            else if (obj.ws_type == 3) return 'algaeDetail'
          },
          keys: ['mc_key', 'ws_type'],
        },
        11: {
          title: '考勤申请详情',
          routeName() {
            return 'applicationDetail'
          },
          keys: ['atp_key'],
        },
        20: {
          title: '质检记录',
          routeName() {
            return 'dectDetail'
          },
          keys: ['r_key'],
        },
        21: {
          title: '投喂记录',
          routeName() {
            return 'techDetail'
          },
          keys: ['r_key'],
        },
        22: {
          title: '估苗记录',
          routeName() {
            return 'recordDetail'
          },
          keys: [{ lmce_key: 'key' }],
          obj: {
            type: 'esti',
          },
        },
        23: {
          title: '出苗记录',
          routeName() {
            return 'recordDetail'
          },
          keys: [{ lo_key: 'key' }],
          obj: {
            type: 'out',
          },
        },
        24: {
          title: '运输记录',
          routeName() {
            return 'transDetail'
          },
          keys: [{ type: 'trip_type' }, 'ha_key'],
          obj: {
            type: 'trans',
          },
        },
        25: {
          title: '关联记录',
          routeName() {
            return 'recordDetail'
          },
          keys: [{ lo_key: 'key' }],
          obj: {
            type: 'rele',
          },
        },
        26: {
          title: '搬苗记录',
          routeName() {
            return 'recordDetail'
          },
          keys: [{ lm_key: 'key' }],
          obj: {
            type: 'move',
          },
        },
        27: {
          title: '排苗记录',
          routeName() {
            return 'recordDetail'
          },
          keys: [{ lc_key: 'key' }],
          obj: {
            type: 'remove',
          },
        },
        28: {
          title: '处理记录',
          routeName() {
            return 'recordDetail'
          },
          keys: [{ ld_key: 'key' }],
          obj: {
            type: 'deal',
          },
        },
      },
    }
  },
}

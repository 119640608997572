<template>
  <div class="yiyu-table">
    <div
      class="single-ele-table sticky-el-table"
      v-myLoad="loading"
      style="position: relative"
    >
      <el-table
        ref="table"
        :header-cell-class-name="headerCellClassName"
        :cell-class-name="cellClassName"
        :row-class-name="rowClassName"
        :cell-style="{ color: '#666666' }"
        :data="allData"
        size="small"
        :height="height"
        :max-height="maxHeight"
        header-row-class-name="tableHead"
        :highlight-current-row="true"
        :key="key"
      >
        <el-table-column
          v-for="(value, index) in headerData"
          :key="index"
          :prop="value.field"
          :label="value.show"
          align="center"
          :fixed="index == 0"
          :width="colWidthList[index]"
          :sortable="value.show == '日期'"
        >
          <template slot="header">
            <span
              :class="{ 'main-c': isClickable(value.links) }"
              @click="ck(value.links)"
            >
              {{ value.show }}
            </span>
          </template>
          <template slot-scope="scope">
            <template v-if="Array.isArray(scope.row[value.field])">
              <div class="data-cell bold row">
                <div v-for="(val, idx) in scope.row[value.field]" :key="idx">
                  <span
                    v-if="val && val['value']"
                    class="bold"
                    :class="{
                      'main-c': isClickable(
                        (val && val['link']) ||
                          (val && val['links']) ||
                          Array.isArray['link']
                      ),
                    }"
                    @click="ck((val && val['link']) || val['links'])"
                  >
                    {{ val && val['value']
                    }}{{ scope.row[value.field].length > 1 ? ',' : '' }}
                  </span>
                </div>
              </div>
            </template>

            <template v-else>
              <span
                :class="{
                  'main-c': isClickable(
                    (scope.row[value.field] &&
                      scope.row[value.field]['links']) ||
                      scope.row[value.field]['link']
                  ),
                }"
                @click="
                  ck(
                    (scope.row[value.field] &&
                      scope.row[value.field]['links']) ||
                      scope.row[value.field]['link']
                  )
                "
              >
                {{
                  (scope.row[value.field] && scope.row[value.field]['value']) ||
                  '-'
                }}
              </span>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <van-dialog
      v-model="dialogShow"
      title="请选择要查看的选项"
      show-cancel-button
      class="link-dialog"
      :show-confirm-button="false"
    >
      <van-button
        plain
        v-for="(item, index) in links"
        :key="index"
        @click="ck(item.link)"
        type="primary"
      >
        {{ item.text }}
      </van-button>
    </van-dialog>
  </div>
</template>
<script>
import { table } from '@/utils/mixins';
export default {
  name: 'stickyTable',
  mixins: [table],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'row',
    },
  },
  data() {
    return {
      height: 'auto',
      columnData: [],
      colWidthList: [],
      loading: false,
      headerData: [],
      allData: [],
      key: undefined,
      timer: undefined,
      dialogShow: false,
      links: [],
    };
  },
  watch: {
    tableData: {
      deep: true,
      handler() {
        this.allData = this.getAllData();
        this.loading = true;
        this.columnData = [];
        this.header.forEach((item, index) => {
          if (index != 0) {
            let tmp = {};
            tmp['first-column'] = { value: item.show };
            this.tableData.forEach((item1, index1) => {
              tmp[index1] = item1[item.field];
            });
            this.columnData.push(tmp);
          }
        });
        this.$nextTick(() => {
          setTimeout(() => {
            this.getHeight();
            // FIXME:单元格宽度自适应
            this.colWidthList = this.adjustColumnWidth(this.$refs['table'].$el);
            this.loading = false;
          });
        });
      },
    },
    header: {
      deep: true,
      handler() {
        this.key = new Date().getTime();
        this.headerData = this.getHeaderData();
        this.columnData = [];
        this.header.forEach((item, index) => {
          if (index != 0) {
            let tmp = {};
            tmp['first-column'] = { value: item.show };
            this.tableData.forEach((item1, index1) => {
              tmp[index1] = item1[item.field];
            });
            this.columnData.push(tmp);
          }
        });
      },
    },
    type() {
      this.headerData = this.getHeaderData();
      this.allData = this.getAllData();
      this.loading = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.colWidthList = this.adjustColumnWidth(this.$refs['table'].$el);
          this.loading = false;
        });
      });
    },
  },
  computed: {
    columnHeader() {
      let arr = [];

      if (this.header && this.header.length) {
        arr = [
          {
            show: this.header[0]['show'],
            field: 'first-column',
          },
        ];

        let arrPush = true;
        this.header.forEach((item, index) => {
          if (index != 0) {
            this.tableData.forEach((item1, index1) => {
              if (arrPush) {
                arr.push({
                  show: item1[this.header[0]['field']]['value'],
                  field: String(index1),
                  link: item1[this.header[0]['field']]['link'],
                });
              }
            });

            arrPush = false;
          }
        });
      }
      return arr;
    },
    maxHeight() {
      return '90vh';
    },
  },
  mounted() {
    this.$nextTick(() => {
      // var targetNode = document.querySelector(".lhj-watch");
      // var targetNode = document.querySelector(".lhj-watch-search");
      // if (targetNode) {
      //   var observerOptions = {
      //     childList: true, // 观察目标子节点的变化，是否有添加或者删除
      //     attributes: true, // 观察属性变动
      //     subtree: true, // 观察后代节点，默认为 false
      //   };
      //   var observer = new MutationObserver(this.callback);
      //   observer.observe(targetNode, observerOptions);
      // }
    });
  },
  methods: {
    getHeaderData() {
      if (this.type == 'row') {
        return this.header;
      } else {
        return this.columnHeader;
      }
    },
    getAllData() {
      if (this.type == 'row') {
        return this.tableData;
      } else {
        return this.columnData;
      }
    },
    isClickable(href) {
      if (href) {
        if (Array.isArray(href)) {
          if (href.length == 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    ck(href) {
      if (href) {
        if (Array.isArray(href)) {
          if (href.length == 0) {
            // no deal
          } else if (href.length == 1) {
            window.location.href =
              window.location.origin + '/#/' + href[0].link;
          } else {
            this.links = href;
            this.dialogShow = true;
          }
        } else {
          window.location.href = window.location.origin + '/#/' + href;
        }
      }
    },
    getHeight(animation = false) {
      this.timer && clearInterval(this.timer);
      let tmp = 0;
      if (document.querySelector('.lhj-watch .van-icon-arrow-down')) {
        // 收起来
        tmp = 80;
      } else {
        tmp = 80;
      }

      let header = document.querySelector('.el-table__header');
      let tabbar = document.querySelector('.van-tabbar.van-tabbar--fixed');
      // 最终要的高度
      let height = this.setHeight(this.tableData, this, {
        otherHeight:
          ((header && header.getBoundingClientRect().top) || 0) +
          ((tabbar && tabbar.getBoundingClientRect().height) || 0),
      });

      if (animation && typeof this.height == 'number') {
        let initHeight = this.height;

        if (initHeight < height) {
          tmp = 128;
          this.timer = setInterval(() => {
            header = document.querySelector('.el-table__header');
            tabbar = document.querySelector('.van-tabbar.van-tabbar--fixed');
            if (initHeight + tmp < height) {
              initHeight += tmp;
              this.height = initHeight;
            } else {
              this.height = height;
              this.timer && clearInterval(this.timer);
              this.height = this.setHeight(this.tableData, this, {
                otherHeight:
                  ((header && header.getBoundingClientRect().top) || 0) +
                  ((tabbar && tabbar.getBoundingClientRect().height) || 0),
              });
            }
          }, 16);
        } else {
          tmp = -10;
          this.timer = setInterval(() => {
            if (initHeight + tmp > height) {
              initHeight += tmp;
              this.height = initHeight;
            } else {
              this.height = height;
              this.timer && clearInterval(this.timer);
            }
          }, 16);
        }
      } else {
        this.height = height;
      }
    },
    adjustColumnWidth(el) {
      let widthList = [];

      el.querySelectorAll('tr').forEach((tr) => {
        tr.querySelectorAll('span').forEach((td, i) => {
          if (!widthList[i]) {
            widthList[i] = [];
          }

          widthList[i].push(td.getBoundingClientRect().width + 16);
        });
      });

      return widthList.map((width) => Math.max(...width));
    },
    callback() {
      // this.$nextTick(() => {
      // this.time = setInterval(() => {
      //   this.getHeight();
      // }, 16);
      setTimeout(() => {
        // if (this.time) clearInterval(this.time);
        this.getHeight(true);
      }, 500);
      // })
    },
  },
};
</script>

<style lang="less" scoped>
.sticky-el-table {
  /deep/ .el-table {
    .cell {
      white-space: nowrap;
      overflow: auto;
      text-overflow: initial;
    }
  }
}

.link-dialog {
  /deep/.van-dialog__content {
    margin: 1rem 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
</style>

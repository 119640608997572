<template>
  <div class="yiyu-phone" @click="callPhone(phone)">
    <img
      src="@/assets/icons/phone.png"
      alt=""
      class="w20 h20 img-filter"
      v-if="icon && phone"
    />
    <span class="phone">
      {{ phone }}
    </span>
    <!-- <slot></slot> -->
  </div>
</template>

<script>
import { call } from '@/utils/call'
export default {
  name: 'phone',
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
    },
  },
  methods: {
    callPhone(val) {
      call(val)
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-phone {
  color: @mainColor;
  display: flex;
  align-items: center;
}
</style>

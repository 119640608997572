<template>
  <div class="feed grey-bg">
    <yiyu-navbar tab-title="投喂料设置"></yiyu-navbar>
    <yiyu-tabs v-model="tabName" width="100%" class="tab">
      <yiyu-tab-pane name="0" label="全部"></yiyu-tab-pane>
      <yiyu-tab-pane
        v-for="(item, key, index) in tabOpts"
        :key="index"
        :name="key"
        :label="item"
      ></yiyu-tab-pane>
    </yiyu-tabs>
    <div class="content" v-myLoad="isLoading" style="position: relative">
      <div
        v-for="(item, index) in list[tabName]"
        :key="index"
        class="list-item"
      >
        <div class="list-item-left" @click="edit(item)">
          <div class="name">{{ item.name }}</div>
          <div class="remark">{{ item.remark }}</div>
        </div>
        <div class="list-item-right" @click="delData(item.fs_key)">
          <img
            src="@/assets/icons/del_blue.png"
            alt=""
            width="28px"
            height="28px"
            class="img-filter"
          />
        </div>
      </div>
      <yiyu-empty
        v-if="list[tabName] && list[tabName].length == 0"
      ></yiyu-empty>
      <drag-btn @add="addData"></drag-btn>
    </div>

    <form-popup
      :show.sync="addShow"
      v-if="addShow"
      :_this="thisAlias"
      :form-opts="formOpts"
      @cancel="addShow = false"
      @confirm="editData"
    ></form-popup>

    <yiyu-picker
      v-if="showTypePicker"
      :curKey="form.type"
      value-key="value"
      :show.sync="showTypePicker"
      :columns="tabOpts"
      @confirm="confirmPicker($event, 'type')"
      @cancel="showTypePicker = false"
    ></yiyu-picker>

    <tag-popup
      v-if="showSupplierPicker"
      :curKeys="[form.supplier]"
      value-key="name"
      keyName="s_key"
      :show.sync="showSupplierPicker"
      :opts="supplierOpts"
      @confirmObj="confirmPicker($event, 'supplier')"
      @cancel="cancelPicker('supplier')"
      :loading="supplierLoading"
      :single="true"
    >
      <template #empty>
        <div class="supplier-empty">
          <span class="btn" @click="addSupplier">去添加来源</span>
        </div>
      </template>
    </tag-popup>
  </div>
</template>

<script>
import yiyuTabs from '@/components/tab/tabs.vue'
import yiyuTabPane from '@/components/tab/tabPane.vue'
import dragBtn from '@/components/button/dragBtn'
import yiyuEmpty from '@/components/layout/empty.vue'
import yiyuNavbar from '@/components/navbar'
import formPopup from '@/components/popup/formPopup'
import tagPopup from '@/components/popup/tag'
import yiyuPicker from '@/components/popup/picker'
import { Dialog } from 'vant'
import { getKeyValue } from '@/utils/utils'
export default {
  name: 'feedSet',
  components: {
    yiyuTabs,
    yiyuTabPane,
    yiyuEmpty,
    yiyuNavbar,
    dragBtn,
    formPopup,
    yiyuPicker,
    tagPopup,
  },
  data() {
    return {
      postLoading: false,
      supplierLoading: false,
      activated: false, // mounted 和 activated 只须执行一个
      toAdd: false,
      btnLoading: false,
      list: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      addShow: false,
      thisAlias: this,
      form: {
        fs_key: undefined, // 投喂料设置key，不传时候是添加，否则是修改
        name: undefined, // "品名"
        type: undefined, // 类别 1 饵料、2 饲料、3 动保
        supplier: undefined, // "供货商key"
        unit: undefined, // "单位"
        specs: undefined, // "规格"
        unit_price: undefined, // "单价",
        supplierName: undefined,
      },
      isLoading: true,
      tabName: '0',
      tabOpts: {
        1: '饲料',
        2: '饵料',
        3: '动保',
      },
      showTypePicker: false,
      showSupplierPicker: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path.indexOf('setting') !== -1) {
        vm.toAdd = false
      } else {
        vm.toAdd = true
      }
    })
  },
  activated() {
    if (!this.activated) {
      this.activated = true
      return
    }
    this.getSupplier()
    this.addShow = true
  },
  mounted() {
    this.activated = false
    this.getData()
    this.getSupplier()
    if (this.toAdd) {
      this.addData()
      if (this.$route.query) {
        // 不需要写在 activated里
        this.form.type = this.$route.query.type
      }
    }
  },
  computed: {
    formOpts() {
      let _this = this
      return [
        {
          required: true,
          colon: true,
          formType: 'input',
          value: this.form.name,
          name: 'name',
          label: '品名',
          placeholder: '请输入品名',
          rules: [{ required: true, message: '请输入品名' }],
        },
        {
          required: true,
          colon: true,
          formType: 'select',
          value: (this.form.type && this.tabOpts[this.form.type]) || undefined,
          name: 'type',
          label: '类别',
          placeholder: '请选择类别',
          click() {
            _this.showTypePicker = true
          },
          rules: [{ required: true, message: '请选择类别' }],
        },
        {
          required: false,
          colon: true,
          formType: 'input-select',
          type: 'text', // 默认，可以不写
          value: this.form.supplierName,
          name: 'supplierName',
          label: '来源',
          placeholder: '请选择来源',
          click() {
            _this.showSupplierPicker = true
          },
          rules: [{ required: false, message: '请选择来源' }],
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          value: this.form.unit,
          name: 'unit',
          label: '单位',
          placeholder: '请输入单位',
          rules: [{ required: false, message: '请输入单位' }],
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          value: this.form.specs,
          name: 'specs',
          label: '规格',
          placeholder: '请输入规格',
          rules: [{ required: false, message: '请输入规格' }],
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          value: this.form.unit_price,
          name: 'unit_price',
          label: '单价(元)',
          placeholder: '请输入单价',
          rules: [{ required: false, message: '请输入单价' }],
        },
      ]
    },
    filterSupplierOpts() {
      let type = undefined
      if (this.form.type == '0') {
        type = ['2', '3', '4']
      } else if (this.form.type == '1') {
        type = ['2']
      } else if (this.form.type == '2') {
        type = ['3']
      } else if (this.form.type == '3') {
        type = ['4']
      }
      return this.supplierOpts.filter((item) =>
        type.includes(String(item.type)),
      )
    },
    supplierOpts() {
      return this.$store.state.setting.suppliers
    },
  },
  watch: {
    'form.supplierName'() {
      if (!this.showSupplierPicker) {
        if (this.form.supplierName) {
          let key = getKeyValue(
            this.form.supplierName,
            this.supplierOpts,
            's_key',
            'name',
          )

          if (key != '未知') {
            this.form.supplier = key
          } else {
            this.form.supplier = undefined
          }
        }
      }
    },
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/feedSetting/getFeedSetting', {
        type: this.type || undefined,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.list = {
            0: [],
            1: [],
            2: [],
            3: [],
          }
          this.list[0] = res.data.list
          res.data.list.forEach((item) => {
            this.list[item.type] && this.list[item.type].push(item)
          })
        }
      })
    },
    getSupplier() {
      if (!this.$store.state.setting.getSuppliers) {
        this.supplierLoading = true
        this.$store.dispatch('getSupplier', {}).then((res) => {
          if (res.code == 10000) {
            this.supplierLoading = false
          }
        })
      }
    },
    addSupplier() {
      this.$push(this, {
        path: '/supplierSet',
        query: { type: Number(this.form.type) + 1 },
      })
    },
    tabData() {},
    delData(key) {
      Dialog.confirm({
        // title: "标题",
        message: '确认删除？',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/feedSetting/delFeedSetting', {
            fs_key: key,
          }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success('删除成功！')
              this.getData()
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    editData() {
      if (this.btnLoading) return
      this.btnLoading = true
      let form = JSON.parse(JSON.stringify(this.form))
      // if(!form.supplier) {
      form.supplier = form.supplierName
      // }
      this.$post('/feedSetting/addFeedSetting', form).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          if (this.form.fs_key) {
            this.$toast.success('修改成功！')
          } else {
            this.$toast.success('新增成功！')
          }
          this.addShow = false
          if (this.form.supplierName && !this.form.supplier) {
            this.$store.dispatch('getSupplier', {})
          }
          this.getData()
        }
      })
    },
    addData() {
      this.form = {
        fs_key: undefined, // 投喂料设置key，不传时候是添加，否则是修改
        name: undefined, // "品名"
        supplier: undefined, // "供货商key"
        supplierName: undefined,
        unit: undefined, // "单位"
        specs: undefined, // "规格"
        unit_price: undefined, // "单价",
        type: this.tabName || undefined,
      }

      this.addShow = true
    },
    edit(item) {
      item.supplierName = getKeyValue(
        item.supplier,
        this.supplierOpts,
        'name',
        's_key',
      )
      this.form = JSON.parse(JSON.stringify(item))
      this.addShow = true
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'supplier':
          this.form.supplier = (e[0] && e[0].s_key) || undefined
          this.form.supplierName = (e[0] && e[0].name) || undefined
          this.showSupplierPicker = false
          break
        case 'type':
          this.form.type = e[0].key
          this.showTypePicker = false
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.feed {
  min-height: 100%; // 100vh;
  background: #fff;
  padding: 0 1rem;
  .list-item {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;

    &-left {
      padding-right: 0.5rem;
      flex: 1;
    }

    &-right {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      margin-right: 0.5rem;
      border-left: 1px solid @lineColor;
    }

    .name {
      color: @titleColor;
      font-weight: 500px;
      font-size: 1.1rem;
    }

    .remark {
      color: @textColor;
      font-weight: 500px;
      font-size: 1rem;
    }
  }

  .list-item-right {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
}
/deep/ .yiyu-empty {
  margin-top: 2.5rem;
}

.supplier-empty {
  display: flex;
  justify-content: center;
  padding: 1rem;
  color: @mainColor;
}
</style>

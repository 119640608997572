<template>
  <div class="main">
    <div class="main-content">
      <div class="title new-pw" v-if="status == 'newPw'">忘记密码</div>
      <div class="title" v-else>{{ title }}</div>
      <van-form @submit="onSubmit" class="form" :show-error="false">
        <div class="input-area">
          <van-field
            :border="false"
            v-model="form.account"
            v-if="status == 'pw'"
            name="account"
            placeholder="手机号"
            :rules="[
              { required: true, message: '请填写手机号' },
              { validator: yiyuPhone, message: '请输入正确的手机号' },
            ]"
          />
          <proof v-if="status != 'pw'" :form="form"></proof>
          <van-field
            v-if="status == 'pw'"
            key="pw"
            :border="false"
            v-model="form.password"
            :type="showPwd ? 'text' : 'password'"
            name="password"
            placeholder="密码"
            :rules="pwRules"
            autocomplete="on"
          >
            <template #button>
              <img
                class="icon-img"
                src="@/assets/icons/eye_open.png"
                alt=""
                v-if="showPwd"
                @click="showPwd = !showPwd"
              />
              <img
                class="icon-img"
                src="@/assets/icons/eye_close.png"
                alt=""
                v-if="!showPwd"
                @click="showPwd = !showPwd"
              />
            </template>
          </van-field>

          <van-field
            v-if="status == 'newPw'"
            :border="false"
            v-model="form.new_password"
            name="new_password"
            :type="showNewPwd ? 'text' : 'password'"
            placeholder="新密码"
            :rules="pwRules"
          >
            <template #button>
              <img
                class="icon-img"
                src="@/assets/icons/eye_open.png"
                alt=""
                v-if="showNewPwd"
                @click="showNewPwd = !showNewPwd"
              />
              <img
                class="icon-img"
                src="@/assets/icons/eye_close.png"
                alt=""
                v-if="!showNewPwd"
                @click="showNewPwd = !showNewPwd"
              />
            </template>
          </van-field>

          <van-field
            v-if="status == 'newPw'"
            :border="false"
            v-model="form.confirm_password"
            :type="showConPwd ? 'text' : 'password'"
            name="确认新密码"
            placeholder="确认新密码"
            :rules="pwRules"
          >
            <template #button>
              <img
                class="icon-img"
                src="@/assets/icons/eye_open.png"
                alt=""
                v-if="showConPwd"
                @click="showConPwd = !showConPwd"
              />
              <img
                class="icon-img"
                src="@/assets/icons/eye_close.png"
                alt=""
                v-if="!showConPwd"
                @click="showConPwd = !showConPwd"
              />
            </template>
          </van-field>
        </div>

        <div class="msg-tip" v-if="status != 'newPw'">
          温馨提示：企业内部系统，无账号者请联系您的企业管理层！
        </div>
        <van-button
          :disabled="btnLoading"
          class="login-btn"
          block
          type="info"
          native-type="submit"
          :loading="btnLoading"
          >{{
            status == 'newPw' ? '确认' : status == 'code' ? '登录' : '密码登录'
          }}</van-button
        >
      </van-form>

      <div class="row-between" v-if="status == 'code' || status == 'newPw'">
        <div></div>
        <div class="main-c" @click.prevent="status = 'pw'">密码登录</div>
      </div>
      <div class="row-between" v-if="status == 'pw'">
        <div class="main-c" @click.prevent="status = 'code'">验证码登录</div>
        <div class="main-c" @click.prevent="status = 'newPw'">忘记密码？</div>
      </div>
    </div>
  </div>
</template>

<script>
import { setToken } from '@/utils/cookie'
import { rules } from '@/utils/mixins'
import proof from '@/components/proof/proof.vue'
import getCompConf from '@/conf/comp'
export default {
  components: { proof },
  name: 'FF_login',
  mixins: [rules],
  data() {
    return {
      form: {
        account: undefined, // 账号
        code: undefined, // 验证码
        password: undefined, // 密码
        new_password: undefined, // 新密码（6-16位）
        confirm_password: undefined, // 确认密码
      },
      btnLoading: false,
      codeLoading: false,
      status: 'pw', // code newPw
      computeTime: 0,
      redirect: undefined, // 重定向
      timer: undefined,
      showPwd: false,
      showNewPwd: false,
      showConPwd: false,
    }
  },
  computed: {
    pwRules() {
      return [
        { required: true, message: '请填写密码' },
        { validator: this.vertifyPw, message: '请输入6至16位的密码' },
      ]
    },
    title() {
      return getCompConf().title
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true,
    },
  },
  mounted() {
    this.form.account = localStorage['yiyu-mc-phone']
  },
  methods: {
    vertifyPw(val) {
      return val && val.length >= 6 && val.length <= 16
    },
    editPasswd() {
      this.btnLoading = true
      this.$post('/user/editPasswd', this.form).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.loginSuccess(res.data.token)
        }
      })
    },
    regOrLoginForPasswd() {
      this.btnLoading = true
      this.$post('/user/regOrLoginForPasswd', this.form).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.loginSuccess(res.data.token)
        }
      })
    },
    regOrLoginForCode() {
      this.btnLoading = true
      this.$post('/user/regOrLoginForCode', this.form).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.loginSuccess(res.data.token)
        }
      })
    },
    loginSuccess(token) {
      setToken(token)
      this.$replace(this, { path: this.redirect || '/' })
    },
    onSubmit() {
      switch (this.status) {
        case 'pw':
          this.regOrLoginForPasswd()
          break
        case 'newPw':
          this.editPasswd()
          break
        case 'code':
          this.regOrLoginForCode()
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  height: 100vh;
  .FlexCenter;

  .main-content {
    width: 80%;
    margin: auto;

    .title {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      color: @mainColor;
      margin-bottom: 1rem;

      &.new-pw {
        text-align: left;
        // color: @titleColor;
        color: @textColor;
        padding-left: 1rem;
      }
    }

    .form {
      .input-area {
        /deep/.van-cell.van-field {
          // margin-bottom: 1rem;
          .van-field__body {
            border: 1px solid @lineColor;
            padding: 0.5rem;
          }
        }
      }

      .icon-img {
        width: 1rem;
        height: 1rem;
      }
    }

    .msg-tip {
      color: @textColor;
      opacity: 0.7;
      margin-bottom: 1rem;
    }

    .login-btn {
      background: @mainColor;
      border: none;
      margin-bottom: 1rem;
    }
  }

  .row-between {
    display: flex;
    justify-content: space-between;
  }
}
</style>

<template>
  <div class="main">
    <yiyu-navbar :tab-title="tabTitle"> </yiyu-navbar>
    <section class="info"></section>
    <div class="row-between">
      <div class="title-wrap">
        <div class="title">{{ detail['title'] }}</div>
        <notice-tag v-model="detail.priority"></notice-tag>
      </div>
      <div class="handles">
        <span class="handle" @click="handle">删除</span>
        <span class="handle" @click="edit">编辑</span>
      </div>
    </div>

    <div class="label">
      <template v-if="type == 1"> 接收人： </template>
    </div>

    <div class="row">
      <span class="value">
        <template v-if="type == 0"> 发布人： </template>
        <template v-if="type == 0">
          {{ detail['nickname'] }}
        </template>
        <template v-if="type == 1">
          <div
            v-for="(value, key, index) in detail['classfiy_role']"
            :key="index"
          >
            <span class="tag-label"></span>
            {{ $store.state.basic.roleObj[key] }}:

            <span
              class="tag"
              v-for="(item1, index1) in value.child"
              :key="index1"
              :class="{ active: Number(item1.is_read) }"
            >
              {{ item1.nickname
              }}{{ index1 != value.child.length - 1 ? '、' : '' }}
            </span>
          </div>
        </template>
      </span>
    </div>

    <div class="row">
      <span class="label">时间：</span>
      <span class="value">{{ detail['time'] }}</span>
    </div>

    <div class="content" v-if="remark.length">
      <div v-for="(item, index) in remark" :key="index">{{ item }}</div>
    </div>
    <section class="msg" v-if="detail.re_list && detail.re_list.length">
      <div class="title">关联信息</div>
      <rele-info :list="detail.re_list"></rele-info>
    </section>
    <section class="msg">
      <div class="title">留言</div>
      <div class="msg-content">
        <div
          class="row-between"
          v-for="(item, index) in detail.msg"
          :key="index + 'msg'"
        >
          <div
            class="left"
            v-if="item.user_key != $store.state.basic.userInfo.user_key"
          >
            <yiyu-avator
              v-model="item.nickname"
              size="2.8rem"
              fontSize="1rem"
            ></yiyu-avator>
            <div>
              <div class="top">
                <div class="nickname">{{ item.nickname }}</div>
                <div class="time text-c7">
                  {{ $dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss') }}
                </div>
              </div>
              <div class="bottom">{{ item.content }}</div>
            </div>
          </div>
          <div></div>
          <div
            class="right"
            v-if="item.user_key == $store.state.basic.userInfo.user_key"
          >
            <yiyu-avator
              v-model="item.nickname"
              size="2.8rem"
              fontSize="1rem"
            ></yiyu-avator>
            <div>
              <div class="top">
                <div class="nickname">{{ item.nickname }}</div>
                <div class="time text-c7">
                  {{ $dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss') }}
                </div>
              </div>
              <div class="bottom">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <van-divider v-if="!detail.msg || detail.msg.length == 0"
          >暂无留言</van-divider
        >
      </div>

      <fixed-btn
        btnTxt="发表留言"
        @click="toAddMsg"
        :loading="btnLoading"
        :disabled="btnLoading"
      ></fixed-btn>
    </section>

    <van-dialog
      v-model="addShow"
      title="留言"
      show-cancel-button
      @confirm="addMsg"
      @cancel="addShow = false"
      :close-on-click-overlay="false"
    >
      <van-field
        autosize
        v-model="content"
        placeholder="请输入留言"
        type="textarea"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import releInfo from '@/views/notice/components/releInfo';
import yiyuAvator from '@/components/show/avator';
import fixedBtn from '@/components/button/fixedBtn';
import noticeTag from '@/bComponents/noticeTag.vue';
import { Dialog } from 'vant';
import yiyuNavbar from '@/components/navbar';
import { classfiyRole } from '@/utils/business.js';
export default {
  name: 'detail',
  components: {
    yiyuNavbar,
    noticeTag,
    fixedBtn,
    yiyuAvator,
    releInfo,
  },
  data() {
    return {
      btnLoading: false,
      detail: {},
      isLoading: false,
      content: undefined,
      addShow: false,
    };
  },
  created() {
    if (this.$route.query) {
      let query = this.$route.query;
      this.detail.notice_key = query.k;
    }
    if (this.detail.notice_key) {
      this.read();
    } else {
      this.$toast('该链接无效哦');
    }
  },
  computed: {
    type() {
      return Number(this.$store.state.basic.nickname == this.detail.nickname);
    },
    tabTitle() {
      if (this.type == 0) {
        return '通知详情';
      } else {
        return '发布详情';
      }
    },
    remark() {
      return this.detail.remark?.split('\n') || [];
    },
  },
  methods: {
    toAddMsg() {
      this.addShow = true;
    },
    addMsg() {
      if (!this.content) {
        this.$toast.fail('留言不能为空');
        return;
      }
      if (this.btnLoading) return;
      this.btnLoading = true;
      this.$post('/notice/addMsg', {
        notice_key: this.detail.notice_key,
        content: this.content,
      }).then((res) => {
        this.btnLoading = false;
        if (res.code == 10000) {
          this.addShow = false;
          this.content = undefined;
          this.$toast.success('发表留言成功');
          this.read();
        }
      });
    },
    read() {
      this.isLoading = true;
      this.$get('/notice/getNotice', {
        notice_key: this.detail.notice_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          res.data.notice_key = this.notice_key;
          let notice = res.data.notice;
          notice.re_list = notice.re_list.filter((item) => item.re_status == 2);
          this.detail = notice;
          this.detail.classfiy_role = classfiyRole(this.detail.receive_user);
        }
      });
    },
    handle() {
      Dialog.confirm({
        message: '确认删除？',
      })
        .then(() => {
          this.$post('/notice/delNotice', {
            notice_key: this.detail.notice_key,
          }).then((res) => {
            if (res.code == 10000) {
              this.$toast.success('删除成功！');
              this.$go(-1, this, {
                name: 'notice',
              });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    getReceiver(arr) {
      let str = '';
      if (Array.isArray(arr)) {
        arr.forEach((item) => {
          str += item.nickname;
        });
      }
      return str;
    },
    edit() {
      this.$toPage(this, 'publish', this.detail);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  padding: 12px;

  .row-between {
    display: flex;
    justify-content: space-between;
  }

  .title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .title {
    color: @titleColor;
    font-size: 1.2rem;
    font-weight: 600;
    margin-right: 0.5rem;
  }

  .handles {
    .handle {
      color: @mainColor;
      margin: 0 0.2rem;
    }
  }

  .content {
    font-size: 1.1rem;
    color: @textColor;
    padding: 0.5rem;
    background: @bgColor;
    border-radius: 0.5rem;
  }

  .label {
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  .row {
    display: flex;
    align-items: center;
    margin: 8px 0 16px;
  }

  .tag-label {
    display: flex;
    flex-wrap: wrap;

    .tag {
      border-radius: 8px;
      display: inline-block;
      margin: 8px;
      padding: 10px 16px;
      white-space: nowrap;
      font-size: 1rem;
      font-family: PingFang SC;
      font-weight: 500;
      background: @bgColor;
    }
  }

  .active {
    color: rgb(6, 168, 6);
  }

  .msg {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 0.5rem solid @bgColor;

    .row-between {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;
      .top {
        display: flex;
        align-items: center;
        .nickname {
          color: @textColor;
          line-height: 1rem;
          margin: 0 0.5rem;
        }
        .time {
          line-height: 1rem;
        }
      }

      .bottom {
        background: rgba(@mainRgb, 0.1);
        margin: 0.1rem 0.5rem;
        border-radius: 0.4rem;
        padding: 0.4rem;
        max-width: 60vw;
      }

      .left {
        display: flex;
      }

      .right {
        display: flex;
        flex-direction: row-reverse;

        .top {
          flex-direction: row-reverse;
        }

        .bottom {
          flex-direction: row-reverse;
          display: flex;
        }
      }
    }
  }
}
</style>

<template>
  <div class="supplier grey-bg">
    <yiyu-navbar :tab-title="tabTitle"></yiyu-navbar>
    <div class="content" v-myLoad="isLoading" style="position: relative">
      <div
        v-for="(item, index) in $store.state.setting.userConf[this.type].list"
        :key="index"
        class="list-item"
      >
        <div class="list-item-left" @click="edit(item)">
          <div class="title">
            {{ type == 'larvaType' ? item.title : item.name }}
            <span v-if="item.role">({{ item.role }})</span>
            <span v-if="item.number || item.number == 0"
              >({{ item.number }})</span
            >
          </div>
          <div class="msg" v-if="item.remark">描述：{{ item.remark }}</div>
        </div>
        <div
          v-if="!isDefault(item)"
          class="list-item-right"
          @click="delData(item)"
        >
          <img
            src="@/assets/icons/del_blue.png"
            alt=""
            width="28px"
            height="28px"
            class="img-filter"
          />
        </div>
      </div>
      <yiyu-empty
        v-if="this.$store.state.setting.userConf[this.type].list.length == 0"
      ></yiyu-empty>
      <drag-btn @add="addData"></drag-btn>
    </div>
    <keep-alive>
      <b-person-picker
        ref="personPick"
        keyName="user_key"
        :single="true"
        :curKey.sync="form['examine_man']"
        @confirm="handleReponsible($event)"
      ></b-person-picker>
    </keep-alive>
    <form-popup
      :show.sync="addShow"
      v-if="addShow"
      :_this="thisAlias"
      :form-opts="formOpts"
      @cancel="addShow = false"
      @confirm="editData"
    ></form-popup>
  </div>
</template>

<script>
import dragBtn from '@/components/button/dragBtn';
import yiyuEmpty from '@/components/layout/empty.vue';
import yiyuNavbar from '@/components/navbar';
import formPopup from '@/components/popup/formPopupNew';
import bPersonPicker from '@/bComponents/bPersonPicker';

export default {
  name: 'confSet',
  components: {
    yiyuEmpty,
    yiyuNavbar,
    dragBtn,
    formPopup,
    bPersonPicker,
  },
  data() {
    return {
      type: 'larvaType',
      typeObj: {
        larvaType: '育苗类型',
        seedName: '种虾名称',
        seedAlias: '种虾别名',
        seedStrain: '种虾品系',
        dectConfType: '质检指标类别',
        growQualityTargetType: '育苗质检指标类别',
        growFeedTargetType: '育苗投喂指标类别',
        seedQualityTargetType: '种虾质检指标类别',
        seedFeedTargetType: '种虾投喂指标类别',
        larvalStage: '育苗阶段',
        seedStage: '种虾阶段',
        algalSpecies: '藻类品种',
        algaeSource: '藻类来源',
        dectObjType: '质检类型',
        goodsType: '物品类别',
        supplier: '供应商',
        examineMan: '采购审核人',
        section: '申请部门',
      },
      storeConfList: ['goodsType', 'supplier', 'examineMan', 'section'],
      postLoading: false,
      toAdd: false,
      btnLoading: false,
      addShow: false,
      defaultList: [
        'z1',
        'z2',
        'z3',
        'm1',
        'm2',
        'm3',
        'p1',
        'p2',
        'p3',
        '默认指标',
        '无类别',
      ],
      thisAlias: this,
      form: {
        name: undefined,
        key: undefined,
        remark: undefined,
        num: undefined,
        examine_man: '',
      },
      isLoading: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from.path.indexOf('setting') !== -1 ||
        from.path.indexOf('storeMgt') !== -1
      ) {
        vm.toAdd = false;
      } else {
        vm.toAdd = true;
      }
    });
  },
  // 不能用created，因为beforeRouteEnter-next在后执行
  mounted() {
    console.log('confset-mounted');
    if (this.$route.query) {
      let query = this.$route.query;
      this.type = query.type || 'larvaType';
      this.getData();
    }
    if (this.toAdd) {
      this.addData();
    }
  },
  computed: {
    formOpts() {
      return [
        {
          title: '',
          child: [
            {
              required: true,
              colon: true,
              formType: 'input',
              value: this.form.name,
              name: 'name',
              label: this.typeObj[this.type],
            },
            {
              required: false,
              value: this.form.remark,
              name: 'remark',
              label: '描述',
              show: this.type == 'dectObjType',
            },
            {
              required: false,
              disabled: true,
              value: this.form.num,
              name: 'num',
              label: '类别编号',
              show: this.type == 'goodsType',
            },
          ],
        },
      ];
    },

    tabTitle() {
      return this.typeObj[this.type] + '设置';
    },
  },
  methods: {
    // FIXME:获取用户自定义配置数据抄这里
    getData() {
      let obj = {
        yy_type: this.type,
      };
      if (this.storeConfList.includes(this.type)) {
        obj['url'] = '/entrepot_conf/getNewList';
      }
      this.isLoading = true;
      this.$store.dispatch('getUserConfList', obj).then((res) => {
        this.isLoading = false;
        if (res.code == 10000) {
          this.isLoading = false;
        }
      });
    },
    delData(item) {
      let key = this.type == 'larvaType' ? item.lt_key : item.key;
      console.log(item);
      let obj = {
        key,
        yy_type: this.type,
      };
      if (this.storeConfList.includes(this.type)) {
        obj['url'] = '/entrepot_conf/delNewOne';
      }
      this.$dialog
        .confirm({
          // title: "标题",
          message: '确认删除？',
        })
        .then(() => {
          if (this.postLoading) return;
          this.postLoading = true;
          this.$store.dispatch('delUserConfList', obj).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.$toast.success('删除成功！');
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    editData() {
      let obj = {
        key: this.form.key,
        content: {
          name: this.form.name,
          type: this.type == 'dectObjType' ? 'other' : undefined,
          remark: this.form.remark,
          number: this.form.num,
        },
        yy_type: this.type,
      };

      if (this.storeConfList.includes(this.type)) {
        obj['url'] = '/entrepot_conf/addNewOne';
      }
      if (this.btnLoading) return;
      this.btnLoading = true;
      this.$store.dispatch('editUserConfList', obj).then((res) => {
        this.btnLoading = false;
        if (res.code == 10000) {
          if (this.form.key) {
            this.$toast.success('修改成功！');
          } else {
            this.form.key = res.data?.key;
            this.$toast.success('新增成功！');
          }

          if (this.IS_BACK) {
            this.$go(-1, this);
            sessionStorage['yy_back_' + this.type] = JSON.stringify({
              ...obj.content,
              key: this.form.key,
              yy_type: this.type,
            });
          }
          this.addShow = false;
        }
      });
    },
    handleReponsible(e) {
      console.log(e);
      let obj = {
        content: {
          key: this.form.examine_man,
          name: e.obj.nickname,
          role: e.obj.roleName,
        },

        yy_type: this.type,
      };
      if (this.storeConfList.includes(this.type)) {
        obj['url'] = '/entrepot_conf/addNewOne';
      }
      this.$store.dispatch('editUserConfList', obj).then((res) => {
        this.btnLoading = false;
        if (res.code == 10000) {
          this.$refs.personPick.show = false;
          this.$toast.success('新增成功!');
        }
      });
    },
    addData() {
      if (this.type == 'goodsType') {
        this.$get('/entrepot_good/getCate').then((res) => {
          if (res.code == 10000) {
            this.form = {
              name: undefined,
              remark: undefined,
              num: res.number,
            };
            this.addShow = true;
          }
        });
      } else if (this.type == 'examineMan') {
        this.$refs.personPick.show = true;
      } else {
        this.form = {
          name: undefined,
          remark: undefined,
        };
        this.addShow = true;
      }
    },
    edit(item) {
      if (this.type == 'larvaType') {
        this.form = {
          name: item.title,
          key: item.lt_key,
        };
      } else if (this.type == 'examineMan') {
        return;
      } else if (this.type == 'goodsType') {
        if (item.name == '无类别') return;
        this.addShow = true;
        this.form = {
          name: item.name,
          key: item.key,
          num: item.number,
        };
      } else {
        this.form = {
          name: item.name,
          key: item.key,
          remark: item.remark,
          num: item.number,
        };
      }
      if (this.isDefault(item)) return;

      this.addShow = true;
    },
    isDefault(item) {
      switch (this.type) {
        case 'dectObjType':
          return item.type != 'other';
        case 'goodsType':
          return true;
        default:
          return this.defaultList.indexOf(item.name) != -1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.supplier {
  min-height: 100%; // 100vh;

  .list-item {
    background: #fff;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    &-left {
      flex: 1;
    }

    .title {
      word-break: break-all;
      color: @titleColor;
      font-weight: 500px;
      font-size: 1.1rem;
    }

    .msg {
      color: @textColor;
    }
  }

  .list-item-right {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
}
/deep/ .yiyu-empty {
  margin-top: 2.5rem;
}
</style>

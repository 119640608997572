<template>
  <list-container
    @more="getMoreData"
    @refresh="refreshData"
    class="list-container"
    :pullDisable="true"
  >
    <yiyu-navbar :data-title="typeObj[type]" :tab-title="tabTitle">
    </yiyu-navbar>

    <y-filter
      :data="filterData"
      ref="filter"
      @search="fetchFilterData"
      @reset="resetData"
    >
      <YFilterItemDatetimePicker
        :value.sync="sDate"
        :slot="dateName"
        :onChange="dateChange"
        type="date"
      />
    </y-filter>
    <div class="list relative" v-myLoad="isLoading">
      <div
        class="list-item-wrap"
        v-for="(item, index) in list"
        :key="index + 'list-item'"
        @click="toPage('detail', item)"
        :class="{ chosed: curKey.includes(getDataKey(item)) }"
      >
        <van-badge class="unread" :dot="item.read == 2">
          <div class="row-between">
            <div class="list-title">{{ item.batch }}-{{ item.mc_name }}</div>
            <div
              class="result"
              v-if="item.h_status"
              :class="{ green: item.h_status == 1, red: item.h_status == 0 }"
            >
              {{ item.h_status == 1 ? '已出苗' : '未出苗' }}
            </div>
          </div>
          <data-field type="row" :data-obj="[getDataObj(item)]"></data-field>

          <YLayoutBottomInfo
            :key="index"
            :name="item.opt_user"
            :time="getDate(item)"
          ></YLayoutBottomInfo>
        </van-badge>
      </div>
      <yiyu-empty v-if="!list.length"></yiyu-empty>
      <van-divider v-if="overShow">我是有底线的</van-divider>
      <van-divider v-if="isLoading">加载数据中...</van-divider>
    </div>
  </list-container>
</template>

<script>
import { getWs } from '@/utils/business'
import yiyuNavbar from '@/components/navbar'
import yiyuEmpty from '@/components/layout/empty.vue'
import listContainer from '@/components/layout/listContainer'
import { YLayoutBottomInfo } from 'yiyu-vant-ui'
import { YFilter, YFilterItemDatetimePicker } from 'yiyu-vant-ui/lib/filter'
import { mapState } from 'vuex'
import dataField from '@/components/layout/dataField'
import mcMixin from '@/mixin/mcMixin.js'
import { strToArr } from '@/utils/utils'
export default {
  name: 'recordList',
  mixins: [mcMixin],
  components: {
    listContainer,
    yiyuNavbar,
    yiyuEmpty,
    YLayoutBottomInfo,
    YFilter,
    YFilterItemDatetimePicker,
    dataField,
  },
  data() {
    return {
      isLoading: false,
      kw: undefined,
      tabName: '1',
      list: [],
      page: 1,
      count: 10,
      total: 0,
      scroll: 0,
      typeObj: {
        esti: '估苗',
        deal: '处理',
        remove: '排苗',
        move: '搬苗',
        rele: '关联',
        out: '出苗',
      },
      type: undefined,
      trip_type: '1',
      mc: [], // 基地key
      result: [], // 质检结果 1 正常 2 异常
      mc_opts: [],
      lm_id: undefined,
      person: {},
      personList: [],
      opt_user: [],
      date: [],
      sDate: new Date(),
      overShow: false,
      curKey: [], // 着色要看的key
      activated: false, // 用来标识第一次进入页面
      filterParams: {},
      baseFileterData: [
        {
          label: '操作人',
          remote: async () => {
            const { data } = await this.$get('/member/getMember')

            let memberList = {}
            const filterVale = (item) => ({
              label: item.nickname,
              key: item.uid,
            })

            data.list.forEach((item) => {
              const _roleLabel = this.roleMap[item.role]
              Array.isArray(memberList[_roleLabel])
                ? memberList[_roleLabel].push(filterVale(item))
                : (memberList[_roleLabel] = [filterVale(item)])
            })

            return Object.keys(memberList).map((key) => {
              return {
                label: key,
                keyName: 'opt_user',
                children: memberList[key],
              }
            })
          },
        },
        {
          label: '养殖地',
          keyName: 'mc',
          remote: async () => {
            const {
              data: { MC_WS },
            } = await this.$get('/conf/getSelectConf', {
              select_items: ['MC_WS'],
              params: {
                ws_type: 1,
              },
            })
            return MC_WS.value.map((mc) => ({
              label: mc.mc_name,
              key: mc.mc_key,
              keyName: 'ws_key',
              selectable: true,
              children: mc.ws_list.map((item) => ({
                label: item.ws_name,
                key: item.ws_key,
              })),
            }))
          },
        },
      ],
    }
  },
  activated() {
    this.mountedFn()
    this.getData(1, this.page * this.count)
  },
  beforeRouteLeave(to, from, next) {
    this.scroll = document.querySelector('.list-container').scrollTop
    next()
  },
  computed: {
    ...mapState({
      roleMap: (state) => state.basic.roleObj,
    }),
    dateName() {
      return this.typeObj[this.type] + '日期'
    },
    filterData({ baseFileterData }) {
      switch (this.type) {
        case 'rele':
          return baseFileterData.concat([
            {
              label: '出苗状态',
              keyName: 'h_status',
              single: true,
              children: [
                {
                  label: '请选择出苗状态',
                  keyName: 'h_status',
                  single: true,
                  children: [
                    {
                      key: '0',
                      label: '未出苗',
                    },
                    {
                      key: '1',
                      label: '已出苗',
                    },
                  ],
                },
              ],
            },
            {
              label: this.dateName,
              keyName: 'date',
            },
          ])
        case 'esti':
        case 'deal':
        case 'remove':
        case 'move':
        case 'out':
        default:
          return baseFileterData.concat([
            {
              label: this.dateName,
              keyName: 'date',
            },
          ])
      }
    },
    tabTitle() {
      return this.typeObj[this.type] + '列表'
    },
  },
  methods: {
    mountedFn() {
      this.activated = true
      if (this.$route.query) {
        let query = this.$route.query
        this.type = query.type
        this.lm_id = query.lm_id
      }

      if (this.$route.params) {
        let params = this.$route.params

        if (this.type == 'rele') {
          this.date = strToArr(params.date?.re_h)
        } else {
          this.date = strToArr(params.date)
        }

        this.curKey = strToArr(params.key)

        if (this.curKey.length) {
          // 初始化筛选(目前只有 页查看关联详情 会用到这一块)
          this.page = 1
          this.count = 10
          this.kw = undefined
          this.date = []
          this.opt_user = []
        }

        this.sDate = this.date[0] ? new Date(this.date[0]) : undefined
      }
    },

    getDataKey(item) {
      switch (this.type) {
        case 'rele':
          return item.lo_key
        default:
          return 0
      }
    },
    // 接口文档：https://www.teambition.com/project/5f62bb6525c7d900446550c9/app/5eba5fba6a92214d420a3219/workspaces/5f62bb65b1a1060016c84d94/docs/60ab626d4e168300010a8d36
    getData(page, count, params) {
      let data = {
        page: page ?? this.page,
        count: count ?? this.count,
        keyword: this.kw,
        ...this.filterParams,
        ...params,
      }

      this.isLoading = true
      switch (this.type) {
        case 'esti':
          data.es_date = this.date
          this.$get('/opt/getEsOptList', data).then((res) =>
            this.dataDeal(res, count),
          )
          break
        case 'deal':
          data.d_date = this.date
          this.$get('/opt/getDisOptList', data).then((res) =>
            this.dataDeal(res, count),
          )
          break
        case 'remove':
          data.c_date = this.date
          this.$get('/opt/getCleanOptList', data).then((res) =>
            this.dataDeal(res, count),
          )
          break
        case 'move':
          data.m_date = this.date
          this.$get('/opt/getMoveOptList', data).then((res) =>
            this.dataDeal(res, count),
          )
          break
        case 'rele':
          // https://www.teambition.com/project/5f62bb6525c7d900446550c9/app/5eba5fba6a92214d420a3219/workspaces/5f62bb65b1a1060016c84d94/docs/6183829aeaa1190001a28588
          // data.re_date = this.date[0]
          // data.h_status =
          //   data.h_status != undefined ? [data.h_status] : undefined
          this.$get('/opt/getRelationOptList', {
            ...data,
            // 如果筛选参数村子, 优先使用筛选参数, 否则使用 this.date 中数据
            // this.date 可能会被来自上一个的数据而修改
            re_date: this.filterParams.date ?? this.date?.[0],
            h_status: data.h_status != undefined ? [data.h_status] : undefined,
          }).then((res) => this.dataDeal(res, count))
          break
        case 'out':
          data.h_date = this.date
          this.$get('/opt/getHarvestOptList', data).then((res) =>
            this.dataDeal(res, count),
          )
          break
      }
    },
    dataDeal(res, count) {
      if (res.code == 10000) {
        let list = res.data.list
        if (count) {
          this.list = list
        } else {
          this.list.push(...list)
        }
        this.total = res.data.total
        this.isLoading = false
      }
      this.$nextTick(() => {
        setTimeout(() => {
          if (document.querySelector('.list-container')) {
            document.querySelector('.list-container').scrollTop = this.scroll
          }
        }, 0)
      })
    },
    getMoreData() {
      if (this.list.length < this.total) {
        this.overShow = false
        this.page++
        this.getData()
      } else {
        this.overShow = true
      }
    },
    // 处理 DatetimePicker 日历格式
    dateChange(e) {
      // this.Date = e
      return this.$dayjs(e).format('YYYY-MM-DD')
    },
    fetchFilterData(e) {
      this.filterParams = e
      this.refreshData()
    },
    resetData() {
      this.filterParams = {}
      this.refreshData()
    },
    refreshData() {
      this.page = 1
      this.count = 10
      this.list = []
      this.getData()
    },
    toPage(type, item) {
      switch (type) {
        case 'detail':
          this.$toPage(this, 'recordDetail', {
            type: this.type,
            key: this.getKey(item),
            trip_type: this.trip_type,
            cached: true,
          })
          break
      }
    },
    getDate(item) {
      switch (this.type) {
        case 'esti':
          return this.$dayjs(item.e_date).format('YYYY/MM/DD')
        case 'deal':
          return this.$dayjs(item.d_date).format('YYYY/MM/DD')
        case 'remove':
          return this.$dayjs(item.c_date).format('YYYY/MM/DD')
        case 'move':
          return this.$dayjs(item.m_date).format('YYYY/MM/DD')
        case 'rele':
          return this.$dayjs(item.created_at).format('YYYY/MM/DD')
        case 'out':
          return this.$dayjs(item.h_date).format('YYYY/MM/DD')
      }
    },
    getKey(item) {
      switch (this.type) {
        case 'esti':
          return item.lmce_key
        case 'deal':
          return item.ld_key
        case 'remove':
          return item.lc_key
        case 'move':
          return item.lm_key
        case 'rele':
          return item.lo_key
        case 'out':
          return item.lo_key
      }
    },
    getWs(item) {
      return getWs(item)
    },
    getDataObj(item, type = this.type) {
      switch (type) {
        case 'esti':
          return [
            {
              data: item.launch,
              unitText: '万',
              descText: '投放量',
            },
            {
              data: item.breed_days,
              unitText: '天',
              descText: '育苗天数',
            },
            {
              data: item.count,
              unitText: '万',
              descText: '估苗量',
            },
            {
              descText: '车间',
              data: this.getMcNames(item.ws_list, 'ws_name'),
            },
          ]
        case 'deal':
          return [
            {
              data: item.d_count,
              unitText: '万',
              descText: '处理量',
            },
            {
              data: item.unit_price,
              unitText: '元/万',
              descText: '单价',
            },
            {
              data: item.sum_price,
              unitText: '元',
              descText: '金额',
            },
          ]
        case 'remove':
          return [
            {
              data: item.c_count,
              unitText: '万',
              descText: '排苗量',
            },
            {
              data: this.$store.state.basic.cleanType[item.type],
              descText: '原因',
            },
            {
              descText: '车间',
              data: this.getMcNames(item.ws_list, 'ws_name'),
            },
          ]
        case 'move':
          return [
            {
              data: this.getWs(item.mo_info),
              unitText: '原育苗池',
              descText: '处理量',
            },
            {
              data: this.getWs(item.mi_info),
              descText: '目标育苗池',
            },
            {
              data: item.mo_count,
              unitText: '万',
              descText: '搬出',
            },
            {
              data: item.mi_count,
              descText: '搬入',
              unitText: '万',
            },
          ]
        case 'rele':
          return [
            {
              data: '订单' + item.or_id,
              descText: '订单',
              show: Boolean(item.or_id),
            },
            {
              data: item.customer,
              descText: '客户',
              show:
                item.customer != undefined && this.IS_HANDLE('SALE_CLIENT_SEE'),
            },
            {
              data: item.re_count,
              unitText: '万',
              descText: '关联',
              show: item.re_count != undefined,
            },
          ]

        case 'out':
          return [
            {
              data: item.customer + '(' + (item.re_count || 0) + '万)',
              descText: '关联客户',
              show:
                item.customer != undefined && this.IS_HANDLE('SALE_CLIENT_SEE'),
            },
            {
              data: item.h_count,
              unitText: '万',
              descText: '出苗量',
            },
            {
              data: item.real_count,
              unitText: '万',
              descText: '实结量',
            },
          ]
        default:
          return []
      }
    },
  },
}
</script>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  justify-content: space-between;

  .van-search {
    flex-basis: 100%;
  }
}
.row-between {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.list {
  .list-item-wrap {
    padding: 0.5rem;
    border-bottom: 1px solid @lineColor;

    &.chosed {
      background: rgba(@mainRgb, 0.06);
    }

    .list-title {
      font-size: 1.1rem;
      font-weight: 600;
      color: @titleColor;
    }

    .wrap {
      padding: 0.2rem;
    }

    .row {
      display: flex;
      align-items: center;
      margin: 0.1rem 0;
      line-height: 1.4rem;

      .label {
        color: @textColor;
        font-weight: bold;
        opacity: 0.7;
      }

      .value {
        color: @textColor;
        font-size: 1rem;
        margin-left: 0.5rem;

        &.bold {
          font-weight: 700;
          color: @titleColor;
          font-size: 1.2rem;
        }
      }
    }

    .handles {
      display: flex;
      .handle-btn {
        border: 1px solid @lineColor;
        margin: 0 0.5rem;
        padding: 0 0.4rem;
        border-radius: 0.5rem;
        .txt {
          white-space: nowrap;
        }
      }
    }
  }
}
</style>

<template>
  <div class="main relative accept-edit" v-myLoad="isLoading">
    <yiyu-navbar :tab-title="tabTitle" />
    <yiyu-form
      :formOpts="formOpts"
      :form="form"
      :_this="thisAasia"
      @submit="confirmSubmit"
      ref="form"
    >
      <template #goodsInputSelect>
        <div class="row-between" style="padding: 0 1rem; margin-top: 1rem">
          <!-- <div style="display: flex; align-items: center">
            <span style="color: #e84a5f">*</span>
            <span style="color: #646566">物资名称</span>
          </div>
          <el-select
            v-model="goods_key"
            multiple
            filterable
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="item in goodsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <div class="main-c" @click="addGood">增加物资</div>
        </div>
      </template>
      <template v-for="(item, index) in form.check_goods_list">
        <template :slot="'topSlot' + index">
          <div class="row-between">
            <div class="my-title" @click="toGoodsDetail(item)">
              {{ item.goods_name }}
            </div>
            <div class="main-c" @click="delItem(index)">删除</div>
          </div>
          <div style="padding: 1rem" v-if="convert(item.convert)">
            规格：{{ convert(item.convert) }}
          </div>
        </template>
      </template>
      <template #footer>
        <fixed-btn
          :leftTxt="form.ca_key ? '删除' : '重置'"
          btnTxt="确定"
          btnNativeType="submit"
          :leftLoading="leftLoading"
          @leftClick="leftClick"
        />
      </template>
    </yiyu-form>
    <keep-alive>
      <b-person-picker
        ref="personPicker"
        :cur-key.sync="form.check_user_list"
        :cur-name.sync="form.checkUser"
        keyName="user_key"
      ></b-person-picker>
    </keep-alive>

    <van-popup class="popup" v-model="isUnitPopup" round position="bottom">
      <select-tag
        :opts="curAllUnits"
        v-model="curUnitKey"
        :single="true"
        @change="unitChange"
      ></select-tag>
    </van-popup>
    <van-popup class="popup" v-model="farmPickerShow" round position="bottom">
      <select-tag
        :opts="storeListOpts"
        v-model="form.wh_key"
        :single="true"
        @change="storeChange"
      ></select-tag>
    </van-popup>
    <y-dialog-page-list
      :show.sync="nextShow"
      :title="tabTitle + '成功'"
      subtitle="请选择下一步："
      :list="nextOpts"
      @confirm="nextHandle"
    />
  </div>
</template>

<script>
import yiyuNavbar from '@/components/navbar';
import yiyuForm from '@/components/form/yiyuForm.vue';
import fixedBtn from '@/components/button/fixedBtn';
import bPersonPicker from '@/bComponents/bPersonPicker.vue';
import selectTag from '@/components/tag/selectTag';
import { deepCopy } from '@/utils/utils';
import { Dialog } from 'vant';
import { convert } from '@/views/store/application/StoreApp';
import { YDialogPageList } from 'yiyu-vant-ui/lib/dialog';

export default {
  name: 'acceptEdit',
  components: {
    yiyuNavbar,
    yiyuForm,
    fixedBtn,
    bPersonPicker,
    selectTag,
    YDialogPageList,
  },
  watch: {},
  data() {
    return {
      isLoading: false,
      nextShow: false,
      convert,
      form: {
        ca_key: undefined,
        remark: '',
        wh_key: [],
        wh_name: '',
        check_user_list: [],
        checkUser: undefined,
        check_goods_list: [],
      },
      setLoading: false,
      goods_key: [],
      goodsList: [],
      btnLoading: false,
      thisAasia: this,
      isUnitPopup: false,
      curAllUnits: [],
      curUnitKey: [],
      curIndex: 0,
      farmPickerShow: false,
      storeListOpts: [],
      leftLoading: false,
      edit_ca_key: undefined,
      nextOpts: [
        {
          name: '查看详情',
          key: 'detail',
        },
        {
          name: '继续新增',
          key: 'continueAdd',
        },
      ],
    };
  },
  computed: {
    tabTitle() {
      return this.form.ca_key ? '编辑验收单' : '新增验收单';
    },
    formOpts() {
      let _this = this;
      let arrOpts = [];
      this.form.check_goods_list.forEach((item, index) => {
        arrOpts.push({
          topSlot: 'topSlot' + index,
          child: [
            // {
            //   required: true,
            //   colon: true,
            //   formType: 'select', // 默认
            //   value: this.form.check_goods_list[index].unit_name,
            //   valueKey: ['form', 'check_goods_list', index, 'unit_name'],
            //   name: 'unit_name',
            //   label: '物品单位',
            //   placeholder: '请选择物品单位',
            //   click() {
            //     _this.isUnitPopup = true;
            //     _this.curAllUnits = _this.form.check_goods_list[index]?.allUnit;
            //     _this.curUnitKey = item.unit_key ? [item.unit_key] : [];
            //     _this.curIndex = index;
            //   },
            //   rules: [{ required: true, message: '请选择物品单位' }],
            // },
            {
              required: true,
              label: '物资名称',
              formType: 'input',
              value: this.form.check_goods_list[index].goods_name,
              valueKey: ['form', 'check_goods_list', index, 'goods_name'],
            },
            {
              required: true,
              label: '数量',
              formType: 'number',
              value: this.form.check_goods_list[index].check_count,
              valueKey: ['form', 'check_goods_list', index, 'check_count'],
              change: () => {
                if (!this.setLoading) {
                  _this.getSum(
                    index,
                    this.form.check_goods_list[index].check_count,
                    this.form.check_goods_list[index].unit_price
                  );
                }
              },
            },
            {
              label: '单价',
              formType: 'number',
              value: this.form.check_goods_list[index].unit_price,
              valueKey: ['form', 'check_goods_list', index, 'unit_price'],
              change: () => {
                console.log(this.setLoading, '单价');
                if (!this.setLoading) {
                  _this.getSum(
                    index,
                    this.form.check_goods_list[index].check_count,
                    this.form.check_goods_list[index].unit_price
                  );
                }
              },
            },
            {
              label: '金额',
              formType: 'number',
              value: this.form.check_goods_list[index].sum_price,
              valueKey: ['form', 'check_goods_list', index, 'sum_price'],
              change: () => {
                if (
                  this.countDecimalPlaces(
                    this.form.check_goods_list[index].sum_price
                  ) > 2
                ) {
                  this.form.check_goods_list[index].sum_price = Number(
                    this.form.check_goods_list[index].sum_price
                  ).toFixed(2);
                }
              },
            },
            {
              label: '物品单位',
              formType: 'input',
              value: this.form.check_goods_list[index].unit_name,
              valueKey: ['form', 'check_goods_list', index, 'unit_name'],
              maxlength: 16,
            },
            {
              label: '规格',
              formType: 'input',
              value: this.form.check_goods_list[index].space,
              valueKey: ['form', 'check_goods_list', index, 'space'],
              maxlength: 16,
            },
          ],
        });
      });
      let opts = [
        ...arrOpts,
        {
          slot: 'goodsInputSelect',
        },
        {
          title: '',
          child: [
            {
              required: false,
              colon: true,
              formType: 'select',
              value: this.form.wh_name,
              name: 'wh_name',
              label: '归属仓库',
              placeholder: '请选择归属仓库',
              click() {
                _this.farmPickerShow = true;
              },
            },
            {
              required: false,
              colon: true,
              formType: 'select',
              value: this.form.checkUser,
              name: 'checkUser',
              label: '验收人',
              placeholder: '请选择验收人',
              click() {
                _this.toChose('receiver');
              },
            },
            {
              colon: true,
              formType: 'textarea', // 默认
              rows: 3,
              maxlength: 300,
              value: this.form.remark,
              name: 'remark',
              label: '备注',
              placeholder: '请输入备注',
            },
          ],
        },
      ];
      return opts;
    },
  },
  activated() {
    this.getWarehouse();
    if (this.$route.query) {
      this.form.ca_key = this.$route.query.ca_key;
    }
    if (this.form.ca_key) {
      this.getEditData();
    } else {
      this.form.wh_name = this.$store.state.basic.userInfo.ch_wh_name
      this.form.wh_key = [this.$store.state.basic.userInfo.ch_wh_key]
    }
    // this.$get('/entrepot_good/getList').then((res) => {
    //   this.goodsList =
    //     res?.data?.list.map((item) => {
    //       return {
    //         ...item,
    //         label: item.name + '(' + item.identifier + ')',
    //         value: item.goods_key,
    //       };
    //     }) || [];
    // });
  },
  methods: {
    countDecimalPlaces(num) {
      // 确保输入是数字
      if (isNaN(num)) {
        return 0;
      }
      // 将数字转换为字符串，并匹配小数点后的数字
      var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
      if (!match) {
        // 如果未找到匹配，则说明该数字不是有效的浮点数
        return 0;
      }
      // 获取小数点后的数字（如果存在）并返回其长度
      return Math.max(
        0,
        // 小数点后的数字
        (match[1] ? match[1].length : 0) -
          // 指数调整
          (match[2] ? parseInt(match[2]) : 0)
      );
    },
    nextHandle(key) {
      switch (key) {
        case 'continueAdd':
          this.nextShow = false;
          break;
        case 'detail':
          this.$replace(this, {
            path: `acceptDetail?ca_key=${this.edit_ca_key}`,
          });
      }
    },
    toGoodsDetail(item) {
      this.$toPage('goodsDetail', {
        goods_key: item.goods_key,
        cached: true,
      });
    },
    delItem(index) {
      this.form.check_goods_list.splice(index, 1);
      this.goods_key.splice(index, 1);
    },
    leftClick() {
      if (this.form.ca_key) {
        // 删除
        Dialog.confirm({
          title: '删除提醒',
          message: '删除报告后，删除后数据无法找回，是否确认删除？',
        })
          .then(() => {
            if (this.leftLoading) return;
            this.leftLoading = true;
            this.$post('/entrepot_ca/delCheckAccept', {
              ca_key: this.form.ca_key,
            }).then((res) => {
              this.leftLoading = false;
              if (res.code == 10000) {
                this.$toast.success('删除验收单成功');
                this.$replace(this, {
                  path: '/acceptList',
                });
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        // 重置
        this.form = {
          ca_key: this.form.ca_key,
          remark: '',
          wh_key: [],
          wh_name: '',
          check_user_list: [],
          checkUser: undefined,
          check_goods_list: [],
        };
        this.goods_key = [];
      }
    },
    getEditData() {
      this.setLoading = true;
      this.$get('/entrepot_ca/getCheckAccept', {
        ca_key: this.form.ca_key,
      }).then((res) => {
        if (res.code === 10000) {
          this.goods_key = res.data?.check_goods_list?.map((e) => e.goods_key);
          this.form = {
            ca_key: res.data.ca_key,
            remark: res.data.remark,
            check_user_list: res.data.check_user_list.map((e) => e.user_key),
            checkUser: res.data.check_user_list
              .map((e) => e.nickname)
              ?.join('、'),
            wh_key: res.data.wh_key ? [res.data.wh_key] : [],
            wh_name: res.data.wh_name,
            check_goods_list: res.data.check_goods_list.map((item) => {
              return {
                ...item,
                label: item.goods_name,
                allUnit: item.allUnit?.map((e) => {
                  return {
                    val: e.name,
                    key: e.key,
                  };
                }),
              };
            }),
          };
        }
        this.$nextTick(() => {
          this.setLoading = false;
        });
      });
    },
    getWarehouse() {
      this.$get('/entrepot_warehouse/getList').then((res) => {
        if (res.code == 10000) {
          this.storeListOpts = res.data?.map((item) => {
            return {
              val: item.name,
              key: item.key,
            };
          });
        }
      });
    },
    getSum(index, check_count, unit_price) {
      if (check_count && unit_price) {
        this.form.check_goods_list[index].sum_price = (
          check_count * unit_price
        ).toFixed(2);
      } else {
        this.form.check_goods_list[index].sum_price = 0;
      }
    },
    confirmSubmit() {
      if (!this.form.check_goods_list?.length) {
        this.$toast.fail('至少选中一个');
        return;
      }
      let form = {
        ca_key: this.form.ca_key,
        wh_key:
          this.form.wh_key && this.form.wh_key.length
            ? this.form.wh_key[0]
            : undefined,
        remark: this.form.remark,
        check_user_list: this.form.check_user_list,
        check_goods_list: this.form.check_goods_list.map((e) => {
          return {
            goods_name: e.goods_name,
            unit_name: e.unit_name,
            check_count: e.check_count,
            unit_price: e.unit_price,
            sum_price: e.sum_price,
            cag_key: e.cag_key,
            space: e.space,
          };
        }),
      };
      this.$post('/entrepot_ca/addCheckAccept', form).then((res) => {
        if (res.code === 10000) {
          if (this.form.ca_key) {
            this.$toast.success('编辑成功');
            this.$go(-1, this);
          } else {
            this.nextShow = true;
            this.edit_ca_key = res.data.ca_key;
          }
        }
      });
    },
    unitChange(e) {
      this.form.check_goods_list[this.curIndex].unit_key = e?.obj?.[0]?.key;
      this.form.check_goods_list[this.curIndex].unit_name = e?.obj?.[0]?.val;
      this.isUnitPopup = false;
    },
    storeChange(e) {
      this.form.wh_key = e?.obj?.[0] ? [e?.obj?.[0]?.key] : [];
      this.form.wh_name = e?.obj?.[0]?.val;
      this.farmPickerShow = false;
    },
    // selectChange() {
    //   let oldArr = deepCopy(this.form.check_goods_list);
    //   let newArr = [];
    //   this.goodsList.forEach((item) => {
    //     if (this.goods_key.includes(item.value)) {
    //       newArr.push({
    // unit_key: item.unit_key,
    // unit_name: item.unit,
    // goods_key: item.goods_key,
    // check_count: undefined,
    // unit_price: undefined,
    // sum_price: undefined,
    // cag_key: undefined,
    // label: item.label,
    // convert: item.convert,
    // allUnit: item.allUnit?.map((e) => {
    //   return {
    //     val: e.name,
    //     key: e.key,
    //   };
    // }),
    //       });
    //     }
    //   });
    //   let mergeArr = [];
    //   newArr.forEach((newItem) => {
    //     if (oldArr.some((oldItem) => oldItem.goods_key == newItem.goods_key)) {
    //       mergeArr.push(
    //         oldArr.filter(
    //           (oldItem) => oldItem.goods_key == newItem.goods_key
    //         )[0]
    //       );
    //     } else {
    //       mergeArr.push(newItem);
    //     }
    //   });
    //   this.form.check_goods_list = mergeArr;
    // },
    addGood() {
      this.form.check_goods_list.push({
        goods_name: undefined,
        unit_name: undefined,
        check_count: undefined,
        unit_price: undefined,
        sum_price: undefined,
        cag_key: undefined,
        space: undefined,
      });
    },
    toChose(type) {
      switch (type) {
        case 'receiver':
          this.$refs['personPicker'].show = true;
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.accept-edit {
  // /deep/ .yiyu-box-section {
  //   margin: 0;
  //   padding-top: 0;
  // }
}
.my-title {
  font-weight: bold;
  padding-left: 1rem;
  max-width: 15rem;
}
</style>

<template>
  <div class="schedule">
    <!-- <div class="main-c">种虾产量数据</div> -->
    <calendar-select :date.sync="date" @change="dateChange"></calendar-select>
    <div class="schedule-main">
      <van-sidebar v-model="active" @change="getData">
        <van-sidebar-item
          :title="item.name"
          v-for="(item, index) in tabObj"
          :key="index"
          :badge="getBadge(item.key)"
        />
      </van-sidebar>
      <div class="schedule-content" v-myLoad="isLoading">
        <!-- 运输 -->
        <!-- 车运 -->
        <div
          class="list"
          v-if="realActive == 'sts' || realActive == 'lts'"
          :key="realActive"
        >
          <div
            class="list-item tsp"
            v-for="(item, index) in getTripData([tripPrefix + 'tspList'])"
            :key="index + 'tsp'"
          >
            <div class="row-between" @click="toPage('transDetail', item)">
              <div class="list-item-title">
                {{
                  $dayjs(getTripData([tripPrefix + 'starting_time'])).format(
                    'HH:mm'
                  )
                }}
              </div>
              <div class="right">
                <span v-if="realActive == 'sts'">
                  第{{ item.car_num || 0 }}车
                </span>
                <span v-else>
                  {{ item.number || '无航班号' }}
                </span>
                <span>(共{{ item.h_box || item.re_box || 0 }}箱)</span>
                <van-icon name="arrow" class="main-c" />
              </div>
            </div>

            <div
              class="row"
              v-if="
                item[tripPrefix + 'start_point'] ||
                item[tripPrefix + 'end_point']
              "
            >
              {{ item[tripPrefix + 'start_point'] || '暂无' }}至{{
                item[tripPrefix + 'end_point'] || '暂无'
              }}
            </div>
            <div class="row" v-if="realActive == 'sts'">
              <div
                class="row"
                v-for="(item1, index1) in item.contacts"
                :key="index1"
              >
                <span class="name">{{ item1.name }}</span>
                <span class="contact main-c" @click="call(item1.phone)">{{
                  item.phone
                }}</span>
              </div>
            </div>
            <div
              class="batch-item"
              v-for="(item1, index1) in item.lm_list"
              :key="index1"
            >
              <div
                class="batch"
                @click="toPage('batch', item1)"
                :class="{ 'main-c': IS_PAGE('larvaProduct') }"
              >
                {{ item1.batch }}-{{ item1.mc_name }}
              </div>
              <data-field
                :data-obj="getDataObj('batch', item1)"
                type="row"
              ></data-field>
            </div>
          </div>
        </div>
        <!-- 出苗 -->
        <div class="list" v-if="realActive == 'out'">
          <div
            class="list-item rele"
            v-for="(item, index) in larvaList"
            :key="index + 'rele'"
          >
            <div class="row-between">
              <div
                class="batch"
                @click="toPage('batch', item)"
                :class="{ 'main-c': IS_PAGE('larvaProduct') }"
              >
                {{ item.batch }}-{{ item.mc_name }}
              </div>
              <div class="right">
                <div
                  class="single-status-tag"
                  :class="{ red: item.status != 3, green: item.status == 3 }"
                >
                  {{ item.status == 3 ? '已出苗' : '待出苗' }}
                </div>
                <van-icon name="arrow" class="main-c" />
              </div>
            </div>
            <data-field
              :data-obj="getDataObj('out', item)"
              type="row"
            ></data-field>
          </div>
        </div>
        <!-- 空数据 -->
        <div class="empty-wrap" v-if="!getBadge(realActive)">
          <yiyu-empty></yiyu-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { call } from '@/utils/call';
import yiyuEmpty from '@/components/layout/empty.vue';
import { formatTime } from '@/utils/date';
import calendarSelect from '@/components/calendar/calendarSelect.vue';
import { isSTripEmpty, isLTripEmpty } from '@/utils/business.js';
import { Sidebar, SidebarItem } from 'vant';
import dataField from '@/components/layout/dataField';

export default {
  name: 'schedule',
  props: {
    pScroll: {
      required: true,
    },
  },
  components: {
    calendarSelect,
    yiyuEmpty,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    dataField,
  },
  data() {
    return {
      active: 0,
      date: undefined,
      isLoading: false,
      s_tspList: [],
      l_tspList: [],
      larvaList: [],
      noticeList: [],
      matterList: [],
      totalNum: {},
      scroll: 0,
      first: true,
      tabObj: [
        { name: '出苗', handle: 'HOME_SCHEDULE_OUT', key: 'out' },
        { name: '车运', handle: 'TRANSPORT_S_TRIP_SEE', key: 'sts' },
        { name: '空运', handle: 'TRANSPORT_L_TRIP_SEE', key: 'lts' },
      ],
    };
  },
  watch: {
    pScroll(val) {
      this.scroll = val;
    },
  },
  computed: {
    realActive() {
      if (typeof this.active == 'number') {
        return this.tabObj?.[this.active]?.key;
      } else {
        return this.active;
      }
    },
    tripPrefix() {
      if (this.realActive == 'sts') {
        return 's_';
      } else {
        return 'l_';
      }
    },
  },
  mounted() {
    this.date = formatTime(new Date()).slice(0, 10);
    this.tabObj = this.FILTER_HANDLE(this.tabObj);
  },
  activated() {
    this.getScheduleStat();
    this.getData();
  },
  methods: {
    getTripData(keyName) {
      return this[keyName];
    },
    getSTripName(item) {
      if (item.s_start_point || item.s_end_point || item.car_num) {
        return `${item.s_start_point || '暂无'} 至 ${
          item.s_end_point || '暂无'
        } 第${item.car_num}车`;
      } else {
        return '查看车运详情';
      }
    },
    getLTripName(item) {
      if (item.l_start_point || item.l_end_point || item.number) {
        return `${item.l_start_point || '暂无'} 至 ${
          item.l_end_point || '暂无'
        } ${item.number}`;
      } else {
        return '查看空运详情';
      }
    },
    getDataObj(type, item) {
      switch (type) {
        case 'batch':
          return [
            {
              descText: '关联',
              data: `${item.re_count || 0}万 约${item.re_box || 0}箱`,
            },
            {
              descText: '出苗',
              data: `${item.h_count || 0}万 约${item.h_box || 0}箱`,
              show: Boolean(item.h_count),
            },
          ];
        case 'out':
          return [
            {
              descText: '客户',
              data: item.customer,
              show: this.IS_HANDLE('SALE_CLIENT_SEE'),
            },
            {
              descText: '出苗',
              data: item.h_count,
              unitText: '万',
              show: item.status == 3,
            },
            {
              descText: '密度',
              data: `${item.density || 0}万/箱 共${item.box || 0}箱`,
              show: item.status == 3,
            },
            {
              descText: '关联',
              data: `${item.re_count || 0}万 ${item.box || 0}箱`,
              show: item.status != 3,
            },

            {
              descText: '车运',
              show:
                isSTripEmpty(item) && this.IS_HANDLE('TRANSPORT_S_TRIP_SEE'),
              data: '暂无安排运输',
              callback: this.IS_HANDLE('TRANSPORT_S_TRIP_EDIT')
                ? () => {
                    this.toPage('editSTrans', item);
                  }
                : undefined,
            },
            {
              descText: '车运',
              data: this.getSTripName(item),
              show:
                !isSTripEmpty(item) && this.IS_HANDLE('TRANSPORT_S_TRIP_SEE'),
              callback: this.IS_HANDLE('TRANSPORT_S_TRIP_EDIT')
                ? () => {
                    this.toPage('transSDetail', item);
                  }
                : undefined,
            },
            {
              descText: '发车',
              show:
                !isSTripEmpty(item) && this.IS_HANDLE('TRANSPORT_S_TRIP_SEE'),
              data: this.$dayjs(item.s_starting_time).format(
                'YYYY/MM/DD HH:mm'
              ),
            },
            {
              descText: '空运',
              show:
                isLTripEmpty(item) && this.IS_HANDLE('TRANSPORT_L_TRIP_SEE'),
              data: '暂无安排运输',
              callback: this.IS_HANDLE('TRANSPORT_L_TRIP_EDIT')
                ? () => {
                    this.toPage('editLTrans', item);
                  }
                : undefined,
            },
            {
              descText: '空运',
              data: this.getLTripName(item),
              show:
                !isLTripEmpty(item) && this.IS_HANDLE('TRANSPORT_L_TRIP_SEE'),
              callback: this.IS_HANDLE('TRANSPORT_L_TRIP_EDIT')
                ? () => {
                    this.toPage('transLDetail', item);
                  }
                : undefined,
            },
            {
              descText: '起飞',
              show:
                !isLTripEmpty(item) && this.IS_HANDLE('TRANSPORT_L_TRIP_SEE'),
              data: this.$dayjs(item.l_starting_time).format(
                'YYYY/MM/DD HH:mm'
              ),
            },
            {
              descText: undefined,
              show: item.status != 3,
              data: `${item.density || 0}万/箱 ${item.salinity || 0}格 ${
                item.temperature || 0
              }℃ ${item.bag_name || '暂无'} ${
                item.ice == 1 ? '挂冰瓶' : item.ice == 0 ? '不挂冰瓶' : '暂无'
              }`,
            },
          ];
      }
    },
    dateChange() {
      this.scroll = 0;
      this.getData();
      this.getScheduleStat();
    },
    tabChange() {
      this.scroll = 0;
      this.getData();
    },
    getBadge(key) {
      return this.totalNum[key] || undefined;
    },
    toScroll() {
      this.$nextTick(() => {
        if (document.querySelector('.schedule-content')) {
          document.querySelector('.schedule-content').scrollTop = this.scroll;
        }
      });
    },
    getData() {
      if (this.realActive == 'sts') {
        this.getTspSchedule(1);
      } else if (this.realActive == 'lts') {
        this.getTspSchedule(2);
      } else if (this.realActive == 'out') {
        this.getLarvaSchedule();
      }
    },
    // 获取育苗行程
    getLarvaSchedule() {
      this.isLoading = true;
      this.$get('/schedule/getLarvaSchedule', { date: this.date }).then(
        (res) => {
          if (res.code == 10000) {
            this.isLoading = false;
            this.larvaList = res.data.list;
            this.toScroll();
          }
        }
      );
    },
    // 获取运输行程
    getTspSchedule(type) {
      this.isLoading = true;
      this.$get('/schedule/getTspSchedule', {
        date: this.date,
        type: type,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          if (type == 1) {
            this.s_tspList = res.data.list;
          } else {
            this.l_tspList = res.data.list;
          }
          this.toScroll();
        }
      });
    },
    // 获取新通知日程
    getNoticeSchedule() {
      this.isLoading = true;
      this.$get('/schedule/getNoticeSchedule', { date: this.date }).then(
        (res) => {
          if (res.code == 10000) {
            this.isLoading = false;
            this.noticeList = res.data.list;
            this.toScroll();
          }
        }
      );
    },
    //获取重要事项行程
    getMatterSchedule() {
      this.isLoading = true;
      this.$get('/schedule/getMatterSchedule', { date: this.date }).then(
        (res) => {
          if (res.code == 10000) {
            this.isLoading = false;
            this.matterList = res.data.list;
            this.toScroll();
          }
        }
      );
    },
    // 获取行程数量统计
    getScheduleStat() {
      this.isLoading = true;
      this.$get('/schedule/getScheduleStat', { date: this.date }).then(
        (res) => {
          if (res.code == 10000) {
            this.isLoading = false;
            let data = res.data;
            this.totalNum = {
              out: data.h,
              sts: data.s_tsp,
              lts: data.l_tsp,
            };

            if (this.first) {
              this.first = false;
              // 默认选中有数据的一栏
              for (let key in this.totalNum) {
                if (this.totalNum[key]) {
                  this.active = this.tabObj.findIndex(
                    (item) => item.key == key
                  );
                  break;
                }
              }
            }
          }
        }
      );
    },
    call(phone) {
      call(phone);
    },
    toPage(type, item) {
      switch (type) {
        case 'batch':
          this.$toPage(this, 'larvaProduct', item);
          break;
        case 'out':
          this.$toPage(this, 'outEdit', item);
          break;
        case 'notice':
          this.$toPage(this, 'noticeDetail', item);
          break;
        case 'esti':
          this.$toPage(this, 'estimateEdit', item);
          break;
        case 'order':
          this.$toPage(this, 'salesDetail', item);
          break;
        case 'matter':
          this.$push(this, {
            path: '/matterEdit',
            query: {
              larvaKey: item.l_key,
              farmKey: item.mc_key,
            },
          });
          break;
        case 'transSDetail':
          item.trip_type = 1;
          this.toPage('transDetail', item);
          break;
        case 'transLDetail':
          item.trip_type = 2;
          this.toPage('transDetail', item);
          break;
        case 'transDetail':
          console.log(
            item.trip_type,
            111,
            item.trip_type || this.realActive == 'sts' ? 1 : 2
          );
          this.$toPage(this, 'transDetail', {
            trip_type: item.trip_type || (this.realActive == 'sts' ? 1 : 2),
            ha_key: item.ha_key,
          });
          break;
        case 'editSTrans':
          item.type = 1;
          this.toPage('editTrans', item);
          break;
        case 'editLTrans':
          item.type = 2;
          this.toPage('editTrans', item);
          break;
        case 'editTrans':
          this.$toPage(this, 'transEdit', {
            lo_key: item.lo_key,
            ha_key: item.ha_key,
            type: item.type,
          });
          break;
      }
    },
    isSTripEmpty(item) {
      return isSTripEmpty(item);
    },
    isLTripEmpty(item) {
      return isLTripEmpty(item);
    },
  },
};
</script>

<style lang="less" scoped>
.schedule {
  background: @bgColor;
  max-height: calc(100vh - 3.75rem - 3.12rem);
  overflow-y: hidden;

  &-main {
    display: flex;

    /deep/.van-sidebar {
      // width: auto;
      width: 6rem;
      text-align: center;
    }

    .schedule-content {
      position: relative;
      flex-basis: 100%;
      background: #fff;
      overflow-y: auto;
      height: calc(100vh - 3.75rem - 3.12rem - 2.5rem);
    }
  }

  .van-sidebar-item--select::before {
    background: none;
  }

  /deep/.van-sidebar-item.van-sidebar-item--select {
    .van-sidebar-item__text {
      color: @titleColor;
      font-weight: 700;
    }
  }

  /deep/.date-select-item {
    background: #fff;
    font-size: 1.1rem;
    color: @titleColor;
    font-weight: 600;

    .van-icon {
      margin-left: 0.5rem;
    }
  }

  .empty-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .row-between {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 0.5rem;

    .right {
      display: flex;
      align-items: center;
    }
  }

  .list {
    padding: 0 0.5rem;

    .list-item {
      margin: 0.5rem 0;
      padding-bottom: 0.5rem;
      border-bottom: 0.5rem solid @bgColor;

      &-title {
        color: @titleColor;
        font-weight: 700;
        font-size: 1rem;
      }

      &.tsp {
        .list-item-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.5rem;
          .left {
            color: @titleColor;
            font-weight: 700;
          }
        }
      }

      .batch,
      .title {
        font-weight: 700;
      }

      &.notice {
        .left {
          display: flex;
          align-items: center;

          .title {
            margin-right: 0.5rem;
          }
        }
      }

      .row {
        margin-bottom: 0.5rem;
        display: flex;
        flex-wrap: wrap;
      }

      .batch-item {
        margin-bottom: 1rem;
      }

      .value {
        color: @titleColor;
        padding-right: 0.5rem;
      }
    }
  }
}
</style>

<template>
  <div class="more">
    <div class="box info-box">
      <info
        :info="$store.state.basic"
        @clickLeft="toPage('my')"
        subKey="comp_name"
      ></info>
      <div class="divide"></div>
    </div>
    <div class="box handle-box" v-if="!isEmpty(fastHandles)">
      <div class="title">快捷入口</div>
      <icon-field :data="fastHandles" @click="toPage" :num="4"></icon-field>
    </div>
    <div class="box handle-box" v-if="!isEmpty(handles)">
      <div class="title">模块管理</div>
      <icon-field
        class="handle"
        :data="handles"
        @click="toPage"
        :num="4"
      ></icon-field>
    </div>
    <div class="box handle-box" v-if="!isEmpty(recordHandles)">
      <div class="title">操作记录</div>
      <icon-field :data="recordHandles" @click="toPage" :num="4"></icon-field>
    </div>
    <div class="box">
      <div class="loginout" @click="loginOut">退出登录</div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
import info from '@/bComponents/info.vue';
import tabbar from '@/components/tabbar.vue';
import { removeToken } from '@/utils/cookie';
import iconField from '@/components/layout/iconField.vue';
export default {
  name: 'FF_more',
  components: {
    tabbar,
    iconField,
    info,
  },
  data() {
    return {
      searchValue: '',
      notice: [],
      readObj: {
        lb_no_read: 0, //'育苗投喂未读数',
        sb_no_read: 0, //'种虾投喂未读数',
        lc_no_read: 0, //'排苗未读数',
        ld_no_read: 0, //'处理苗未读数',
        les_no_read: 0, //'估苗未读数',
        lh_no_read: 0, //'出苗未读数',
        lm_no_read: 0, //'搬苗未读数',
        lo_no_read: 0, //'关联未读数',
      },
    };
  },
  activated() {
    this.getData();
  },
  computed: {
    handles() {
      return [
        {
          txt: '基地管理',
          fn: 'farmMgt',
          src: require('@/assets/images/field_mgt.png'),
          show: this.IS_PAGE('farmMgt'),
        },
        {
          txt: '质检管理',
          fn: 'dectMgt',
          src: require('@/assets/images/dect_mgt.png'),
          show: this.IS_PAGE('dectMgt'),
        },
        {
          txt: '通知管理',
          fn: 'notice',
          src: require('@/assets/images/notice.png'),
          show: this.IS_PAGE('notice'),
        },
        {
          txt: '成员管理',
          fn: 'memberList',
          src: require('@/assets/images/team_mgt.png'),
          show: this.IS_PAGE('memberList'),
        },
        {
          txt: '库存管理',
          fn: 'storeMgt',
          src: require('@/assets/images/order_mgt.png'),
          show: this.IS_PAGE('storeMgt'),
        },
        {
          txt: '考勤管理',
          fn: 'attendanceClock',
          src: require('@/assets/images/order_mgt.png'),
          show: this.IS_PAGE('attendanceClock'),
        },
        {
          txt: '自定义配置',
          fn: 'setting',
          src: require('@/assets/images/set_mgt.png'),
          show: this.IS_PAGE('setting'),
        },
        {
          txt: '运输管理',
          fn: 'transRecord',
          src: require('@/assets/images/record9.png'),
          show: this.IS_PAGE('transport'),
        },
        {
          txt: '丰年虫管理',
          fn: 'fairyMgt',
          src: require('@/assets/images/dect_mgt.png'),
          class: 'fairy',
          //   show: this.IS_PAGE('fairyMgt'),
        },
      ];
    },
    recordHandles() {
      return [
        {
          txt: '育苗投喂',
          fn: 'culRecord1',
          src: require('@/assets/images/record2.png'),
          show: this.IS_HANDLE('RECORD_LARVA'),
          badge: this.readObj.lb_no_read,
        },
        {
          txt: '种虾投喂',
          fn: 'culRecord2',
          src: require('@/assets/images/record2.png'),
          show: this.IS_HANDLE('RECORD_SEED'),
          badge: this.readObj.sb_no_read,
        },
        {
          txt: '估苗记录',
          fn: 'estiRecord',
          src: require('@/assets/images/record3.png'),
          show: this.IS_HANDLE('RECORD_LARVA'),
          badge: this.readObj.les_no_read,
        },
        {
          txt: '处理记录',
          fn: 'dealRecord',
          src: require('@/assets/images/record4.png'),
          show: this.IS_HANDLE('RECORD_LARVA'),
          badge: this.readObj.ld_no_read,
        },
        {
          txt: '排苗记录',
          fn: 'removeRecord',
          src: require('@/assets/images/record5.png'),
          show: this.IS_HANDLE('RECORD_LARVA'),
          badge: this.readObj.lc_no_read,
        },
        {
          txt: '搬苗记录',
          fn: 'moveRecord',
          src: require('@/assets/images/record6.png'),
          show: this.IS_HANDLE('RECORD_LARVA'),
          badge: this.readObj.lm_no_read,
        },
        {
          txt: '关联记录',
          fn: 'releRecord',
          src: require('@/assets/images/record7.png'),
          show: this.IS_HANDLE('RECORD_LARVA'),
          badge: this.readObj.lo_no_read,
        },
        {
          txt: '出苗记录',
          fn: 'outRecord',
          src: require('@/assets/images/record8.png'),
          show: this.IS_HANDLE('RECORD_LARVA'),
          badge: this.readObj.lh_no_read,
        },
      ];
    },
    fastHandles() {
      return [
        {
          txt: '基地列表',
          fn: 'farmMgt',
          src: require('@/assets/images/field_mgt.png'),
          show: this.IS_PAGE('farmMgt'),
        },
        {
          txt: '自定义配置',
          fn: 'setting',
          src: require('@/assets/images/set_mgt.png'),
          show: this.IS_PAGE('setting'),
        },

        {
          txt: '发布通知',
          fn: 'publish',
          src: require('@/assets/images/notice.png'),
          show: true,
        },
        {
          txt: '邀请同事',
          fn: 'memberInvite',
          src: require('@/assets/images/notice.png'),
          show: this.IS_PAGE('memberInvite'),
        },
      ];
    },
  },
  methods: {
    getData() {
      this.$get('/opt/getRecordRead', {}).then((res) => {
        if (res.code == 10000) {
          this.readObj = res.data;
        }
      });
    },
    seeMore() {
      this.$push(this, { path: '/notice' });
    },
    isEmpty(arr) {
      return arr.every((item) => item.show == false);
    },
    // 路由跳转
    toPage(val) {
      switch (val) {
        case 'my':
          this.$toPage(this, 'memberInfo', {
            user_key: this.$store.state.basic.user_key,
          });
          break;
        case 'invite':
          this.$toPage(this, 'memberInvite');
          break;
        case 'addLarva':
          this.$toPage(this, 'larvaLaunch', { status: 'add' });
          break;
        case 'addClient':
          this.$toPage(this, 'clientAdd', {
            status: 'add',
          });
          break;
        case 'addOrder':
          this.$toPage(this, 'salesAdd', { status: 'add' });
          break;
        case 'testMgt1':
          this.$toPage(this, 'techMgt', { test_type: '1', ws_type: '1' });
          break;
        case 'testMgt2':
          this.$toPage(this, 'techMgt', { test_type: '1', ws_type: '2' });
          break;
        case 'prodMgt1':
          this.$toPage(this, 'techMgt', { test_type: '2', ws_type: '1' });
          break;
        case 'prodMgt2':
          this.$toPage(this, 'techMgt', { test_type: '2', ws_type: '2' });
          break;
        case 'dectRecord1':
          this.$toPage(this, 'techList', { test_type: '1', ws_type: '1' });
          break;
        case 'dectRecord2':
          this.$toPage(this, 'techList', { test_type: '1', ws_type: '2' });
          break;
        case 'culRecord1':
          this.$toPage(this, 'techList', { test_type: '2', ws_type: '1' });
          break;
        case 'culRecord2':
          this.$toPage(this, 'techList', { test_type: '2', ws_type: '2' });
          break;
        case 'estiRecord':
          this.$toPage(this, 'recordList', {
            type: 'esti',
          });
          break;
        case 'dealRecord':
          this.$toPage(this, 'recordList', {
            type: 'deal',
          });
          break;
        case 'removeRecord':
          this.$toPage(this, 'recordList', {
            type: 'remove',
          });
          break;
        case 'moveRecord':
          this.$toPage(this, 'recordList', {
            type: 'move',
          });
          break;
        case 'releRecord':
          this.$toPage(this, 'recordList', {
            type: 'rele',
          });
          break;
        case 'outRecord':
          this.$toPage(this, 'recordList', {
            type: 'out',
          });
          break;
        case 'transRecord':
          this.$toPage(this, 'transport');
          break;
        default:
          this.$toPage(this, val);
      }
    },
    loginOut() {
      this.$post('/login/logout', {}).then((res) => {
        if (res.code === 10000) {
          removeToken();
          location.reload();
          // 触发后禁止浏览器的后退键
          history.pushState(null, null, document.URL);
          window.addEventListener(
            'popstate',
            function () {
              history.pushState(null, null, document.URL);
            },
            false
          );
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.more {
  background: @bgColor;
  min-height: 100%; // 100vh;
  display: flex;
  flex-direction: column;

  /deep/ .yiyu-icon-field {
    .txt {
      color: @titleColor;
      font-size: 1rem;
    }
  }

  .search {
    background: #fff;
    padding-top: 0.5rem;
  }

  .top-handle {
    margin: 1rem 0;
  }

  .remind {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    .left {
      width: 90%;
      .list {
        display: flex;
        flex-grow: 0;
        line-height: 1.3rem;
        height: 1.3rem;
        width: 100%;

        .content-wrap {
          display: flex;
          flex-basis: 70%;
          flex-shrink: 0;
          align-items: center;
          overflow-x: hidden; // 非常重要

          .dot {
            display: flex;
            flex-shrink: 0;
            width: 0.5rem;
            height: 0.5rem;
            background: #e84a5f;
            border-radius: 50%;
            margin: auto 4px auto 0;
          }
          .content {
            color: @titleColor;
            font-size: 1rem;
            font-weight: 500;
            margin-right: 4px;
            .Ellipsis;
          }
        }

        .time {
          flex-basis: 30%;
          font-size: 1rem;
          color: @textColor;
          opacity: 0.7;
        }
      }
    }
    .right {
      .FlexCenter;
      color: @mainColor;
      white-space: nowrap;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .box {
    // border-radius: 8px;
    background: #fff;
    // margin: 12px 10px 0;
    padding: 1rem 1rem;

    &.handle-box {
      flex-basis: 100%;
      flex-grow: 1;
    }
  }

  /deep/.yiyu-icon-field {
    padding: 0 1rem;
    justify-content: space-between;
  }

  .title {
    font-weight: 600;
    font-size: 1.1rem;
    color: @titleColor;
    margin-bottom: 1rem;
  }

  .loginout {
    height: 48px;
    background: @bgColor;
    border: 1px solid @lineColor;
    border-radius: 8px;
    color: @textColor;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    line-height: 48px;
  }
  .handle {
    /deep/.fairy {
      margin-left: -0.6rem;
    }
  }
}
</style>

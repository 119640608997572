<template>
  <div class="rele-info">
    <div class="rele-item" v-for="(item, index) in tmpList" :key="index">
      <div class="label">{{ noticeTypeObj[item.type]['title'] }}</div>
      <div class="value">
        <div
          class="main-c"
          v-for="(item1, index1) in item.children"
          :key="index1"
          @click="toPage(item1)"
        >
          {{ item1.text || '暂无标题' }}
        </div>
      </div>
    </div>
    <div class="label"></div>
  </div>
</template>

<script>
import noticeMixin from '@/views/notice/noticeMixin'
import { classifyData } from '@/utils/utils'
export default {
  name: 'releInfo',
  mixins: [noticeMixin],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    tmpList() {
      return classifyData(this.list, 'type', 'type')
    },
  },
  methods: {
    toPage(item) {
      let typeObj = this.noticeTypeObj[item.type]
      let name = typeObj.routeName(obj)
      let obj = item.re_info || {}

      let keys = typeObj.keys
      if (Array.isArray(keys)) {
        keys.forEach((i) => {
          if (typeof i == 'object') {
            for (let key in i) {
              obj[i[key]] = obj[key]
            }
          }
        })
      }

      Object.assign(obj, typeObj.obj)
      this.$toPage(this, name, obj)
    },
  },
}
</script>

<style lang="less" scoped>
.rele-item {
  display: flex;

  .label {
    .ShallowText;
    font-size: 1rem;
    padding-right: 0.5rem;
    opacity: 0.7;
    white-space: nowrap;
    &:after {
      content: ':';
    }
  }
}
</style>

<template>
  <div class="main relative" v-myLoad="isLoading">
    <yiyu-navbar :tab-title="tabTitle">
      <template #right>
        <handle-popver
          :actions="actions"
          @edit="edit"
          @del="del"
          @download="download"
          placement="left-start"
        ></handle-popver>
      </template>
    </yiyu-navbar>
    <div style="padding: 1rem">
      <div class="ca-identi">{{ detail.ca_identi }}</div>
      <dataField :dataObj="dataObj" type="row-between"></dataField>
    </div>
    <div class="title">
      物品{{
        detail.check_goods_list ? '(' + detail.check_goods_list.length + ')' : 0
      }}
    </div>
    <el-table :data="detail.check_goods_list || []" border style="width: 500px">
      <el-table-column prop="goods_name" label="物资名称" />
      <el-table-column width="80" prop="space" label="规格" />
      <el-table-column width="80" prop="unit_name" label="物品单位" />
      <el-table-column width="60" prop="check_count" label="数量" />sum_price
      <el-table-column width="80" prop="sum_unit" label="金额(单价)" />
    </el-table>
  </div>
</template>

<script>
import yiyuNavbar from '@/components/navbar';
import dataField from '@/components/layout/dataField.vue';
import handlePopver from '@/components/feedback/handlePopver.vue';
import { Dialog } from 'vant';
// import { convert } from '@/views/store/application/StoreApp';
import exportExcel from '@/utils/exportExcel';

export default {
  name: 'acceptDetail',
  components: {
    yiyuNavbar,
    dataField,
    handlePopver,
  },
  watch: {},
  mounted() {
    if (this.$route.query) {
      this.ca_key = this.$route.query.ca_key;
    }
    if (this.ca_key) {
      this.getData();
    }
  },
  data() {
    return {
      isLoading: false,
      ca_key: undefined,
      detail: {
        check_goods_list: [],
      },
      actions: [
        {
          text: '导出',
          fn: 'download',
        },
        {
          text: '编辑',
          fn: 'edit',
        },
        {
          text: '删除',
          fn: 'del',
        },
      ],
      postLoading: false,
    };
  },
  computed: {
    tabTitle() {
      return '验收单详情';
    },
    dataObj() {
      return [
        {
          descText: '验收人',
          data: this.detail.check_user_list,
        },
        {
          descText: '归属仓库',
          data: this.detail.wh_name,
        },
        {
          descText: '创建人',
          data: this.detail.nickname,
        },
        {
          descText: '创建时间',
          data: this.detail.created_at,
        },
        {
          descText: '备注',
          data: this.detail.remark,
        },
      ];
    },
  },
  methods: {
    download() {
      let title =
        '验收单' + this.$dayjs(this.detail.created_at).format('YYYY年MM月DD日');
      let sheetDatas = [
        {
          title,
          header: [
            {
              label: '物资名称',
              prop: 'goods_name',
            },
            {
              label: '规格',
              prop: 'space',
            },
            {
              label: '物品单位',
              prop: 'unit_name',
            },
            {
              label: '数量',
              prop: 'check_count',
            },
            // {
            //   label: '单价',
            //   prop: 'unit_price',
            // },
            {
              label: '金额(单价)',
              prop: 'sum_unit',
            },
            {
              label: '备注',
              prop: 'remark',
            },
            {
              label: '验收人',
              prop: 'check_user_list',
            },
          ],
          data: this.detail.check_goods_list,
        },
      ];
      exportExcel({ fileName: title, sheetDatas });
    },
    getData() {
      this.$get('/entrepot_ca/getCheckAccept', {
        ca_key: this.ca_key,
      }).then((res) => {
        if (res.code === 10000) {
          this.detail = res.data;
          this.detail.check_goods_list =
            this.detail.check_goods_list?.map((item) => {
              return {
                goods_name: item.goods_name,
                space: item.space,
                unit_name: item.unit_name,
                check_count: item.check_count,
                unit_price: item.unit_price,
                sum_price: item.sum_price,
                sum_unit: item.sum_price + '(' + item.unit_price + ')',
                remark: item.remark,
                check_user_list: item.check_user_list,
                // ...item,
                check_user_list: this.detail.check_user_list
                  ?.map((e) => e.nickname)
                  ?.join('、'),
                remark: this.detail.remark,
              };
            }) || [];
          this.detail.check_user_list = this.detail.check_user_list
            ?.map((e) => e.nickname)
            ?.join('、');
        }
      });
    },
    edit() {
      this.$toPage(this, 'acceptEdit', {
        ca_key: this.ca_key,
      });
    },
    del() {
      Dialog.confirm({
        title: '删除提醒',
        message: '删除报告后，删除后数据无法找回，是否确认删除？',
      })
        .then(() => {
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/entrepot_ca/delCheckAccept', {
            ca_key: this.ca_key,
          }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.$toast.success('删除验收单成功');
              this.$go(-1, this);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table__body-wrapper {
  overflow-x: hidden;
}
.title {
  padding: 0.5rem;
  font-weight: bold;
}
.ca-identi {
  font-weight: bold;
  padding: 0.5rem 0;
}
</style>

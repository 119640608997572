<template>
  <div class="rele">
    <div class="row-between">
      <div class="left title row">
        <calendar-select
          :date.sync="curDate.re_h"
          @change="getRehData"
        ></calendar-select>
        {{ curDate.re_h == tomorrow ? '(明天)' : '' }}
      </div>
      <div class="right handles">
        <div class="handle-btn" @click="changeDate(-1)">前一天</div>
        <div class="handle-btn" @click="changeDate(1)">后一天</div>
      </div>
    </div>
    <div class="main-content">
      <section class="positive" v-myLoad="isLoading.re_h">
        <rele-table
          :tableData="tableData['stat']"
          :header="header['stat']"
        ></rele-table>
        <div class="row-between">
          <div class="title">关联详情</div>
          <div class="handles">
            <div class="handle-txt" @click="toPage('releRecord')">更多</div>
          </div>
        </div>
        <rele-table
          :tableData="tableData['re']"
          :header="header['re']"
        ></rele-table>
      </section>
      <section class="positive" v-myLoad="isLoading.bat">
        <div class="title">批次详情</div>
        <div class="row-between">
          <calendar-select
            :date.sync="curDate.bat"
            @change="getBatchData"
          ></calendar-select>
          <div class="handles">
            <div
              class="handle-tip"
              v-if="curHandle == 'oneBatch' && curBatch.length == 0"
            >
              请选择批次
            </div>
            <div
              class="handle-txt"
              v-if="curHandle == 'oneBatch' && curBatch.length == 0"
              @click="handle('cancelHandle')"
            >
              取消关联
            </div>
            <div
              class="handle-txt"
              v-if="curHandle == 'oneBatch' && curBatch.length"
              @click="handle('reChoseBatch')"
            >
              重选批次
            </div>
            <div
              class="handle-tip"
              v-if="
                curHandle == 'oneBatch' && curBatch.length && !curOrder.length
              "
            >
              请选择订单
            </div>
            <div
              class="handle-tip"
              v-if="
                curHandle == 'oneBatch' && curBatch.length && curOrder.length
              "
              @click="handle('confirmOrder')"
            >
              确认订单
            </div>
            <div
              class="handle-txt"
              @click="handle('releOrder')"
              v-if="!curHandle"
            >
              +关联订单
            </div>
            <!-- <div
              class="handle-txt"
              @click="toPage('import')"
              v-if="!curHandle && IS_HANDLE('LARVA_BATCH_IMPORT')"
            >
              +外调苗
            </div> -->
          </div>
        </div>
        <rele-table
          ref="batchTable"
          :tableData="tableData['batch']"
          :header="header['batch']"
          :select="batchShow"
          :single="batchSingle"
          @change="selectChange($event, 'batch')"
          :selection="curBatch"
        ></rele-table>
      </section>
      <section class="positive" v-myLoad="isLoading.ord">
        <div class="title">订单详情</div>
        <div class="row-between">
          <calendar-select
            :date.sync="curDate.ord"
            @change="getOrderData"
          ></calendar-select>
          <div class="handles">
            <div
              class="handle-tip"
              v-if="
                curHandle == 'oneOrder' &&
                curBatch.length == 0 &&
                curOrder.length
              "
            >
              请选择批次
            </div>
            <div
              class="handle-txt"
              v-if="curHandle == 'oneOrder' && curOrder.length == 0"
              @click="handle('cancelHandle')"
            >
              取消关联
            </div>
            <div
              class="handle-txt"
              v-if="curHandle == 'oneOrder' && curOrder.length"
              @click="handle('reChoseOrder')"
            >
              重选订单
            </div>
            <div
              class="handle-tip"
              v-if="curHandle == 'oneOrder' && !curOrder.length"
            >
              请选择订单
            </div>
            <div
              class="handle-tip"
              v-if="
                curHandle == 'oneOrder' && curBatch.length && curOrder.length
              "
              @click="handle('confirmOrder')"
            >
              确认订单
            </div>
            <div
              class="handle-txt"
              @click="handle('releBatch')"
              v-if="!curHandle"
            >
              +关联批次
            </div>
            <div
              class="handle-txt"
              @click="toPage('addOrder')"
              v-if="!curHandle"
            >
              +订单
            </div>
          </div>
        </div>
        <rele-table
          ref="orTable"
          :tableData="tableData['or']"
          :header="header['or']"
          :select="orderShow"
          :single="orderSingle"
          @change="selectChange($event, 'order')"
          :selection="curOrder"
        ></rele-table>
      </section>
    </div>
    <van-dialog
      v-model="reShow"
      title="关联量填写"
      show-cancel-button
      cancelButtonText="继续关联"
      @confirm="handle('addRele')"
      @cancel="handle('cancelRele')"
    >
      <div class="input-item" v-for="(item, index) in re_info" :key="index">
        <div class="input-item-label">
          {{ item.label }}
        </div>
        <van-field
          label=""
          type="number"
          placeholder="请输入关联量"
          v-model="item.re_count"
        ></van-field>
      </div>
    </van-dialog>
    <success-popup
      :show="addSuccessShow"
      v-if="addSuccessShow"
      :header="addHeader"
      :content="addContent"
      @cancel="successHandle('cancel')"
      @confirm="successHandle('confirm')"
    >
      <template #content>
        <div
          class="batch-item"
          v-for="(item, index) in curBatch"
          :key="index + 'b'"
        >
          {{ item.batch.value }}-{{ item.mc_name.value }}
        </div>
        <div
          class="order-item"
          v-for="(item, index) in curOrder"
          :key="index + 'o'"
        >
          订单{{ item.or_id.value }}-{{ item.customer.value }}
        </div>
      </template>
      <template #bottom>
        <div class="success-popup-handle">
          <van-button
            class="success-popup-handle-btn"
            @click="toPage('releRecord')"
          >
            关联详情
          </van-button>
          <van-button
            class="success-popup-handle-btn"
            type="primary"
            @click="handle('continue')"
          >
            继续关联
          </van-button>
        </div>
      </template>
    </success-popup>
  </div>
</template>

<script>
import successPopup from '@/components/popup/success'
import { getFuture, formatTime } from '@/utils/date'
import releTable from './releTable'
import { getKeyObj } from '@/utils/utils'
import calendarSelect from '@/components/calendar/calendarSelect.vue'
export default {
  name: 'homeRele',
  props: {
    pScroll: {
      required: true,
    },
  },
  components: {
    // boxSection,
    releTable,
    successPopup,
    calendarSelect,
  },
  data() {
    return {
      scroll: 0,
      tableData: {},
      header: {},
      isLoading: {
        re_h: false,
        bat: false,
        ord: false,
      },
      tomorrow: undefined,
      curDate: {
        re_h: undefined,
        bat: undefined,
        ord: undefined,
      },

      batchShow: false, // 表格选择
      orderShow: false, // 表格选择
      batchSingle: false,
      orderSingle: false,
      curHandle: undefined, // oneBatch oneOrder
      curOrder: [],
      curBatch: [],
      reShow: false, // 关联量填写
      re_info: [],
      curLoKey: [], // 已关联成功的key
      curCount: 0, // 已关联的总关联量
      addSuccessShow: false,
      addHeader: {
        title: '关联成功',
        left: {
          txt: '返回',
          fn: 'cancel',
        },
        right: {
          txt: '发送通知',
          fn: 'confirm',
        },
      },
      autoChosedLmId: undefined, // 自动选中的lmid
      autoChosedOrId: undefined, // 自动选中的orid
    }
  },
  computed: {
    addContent() {
      return {
        title: '以下信息关联成功',
        subTitle: '关联量' + this.curCount + '万',
      }
    },
  },
  watch: {
    pScroll(val) {
      this.scroll = val
    },
    re_info: {
      deep: true,
      handler() {
        this.curCount = 0
        this.re_info.forEach((item) => {
          this.curCount += Number(item.re_count || 0)
        })
      },
    },
  },
  mounted() {
    this.init()
    this.getData()
  },
  activated() {
    if (this.$route.params) {
      let params = this.$route.params
      this.curDate.re_h =
        (params.date && params.date.slice(0, 10)) || this.curDate.re_h
      this.curDate.bat =
        (params.date && params.date.slice(0, 10)) || this.curDate.bat
      this.curDate.ord =
        (params.date && params.date.slice(0, 10)) || this.curDate.ord
      this.autoChosedLmId = params.lm_id
      this.autoChosedOrId = params.or_id
    }
  },
  methods: {
    init() {
      this.tomorrow = formatTime(new Date(getFuture(Date(), 1)), '-').slice(
        0,
        10,
      )
      this.curDate.re_h = this.tomorrow
      this.curDate.bat = this.tomorrow
      this.curDate.ord = this.tomorrow
    },
    changeDate(num) {
      this.initHandle()
      this.curDate.re_h = formatTime(
        new Date(getFuture(new Date(this.curDate.re_h), num)),
        '-',
      ).slice(0, 10)
      this.getRehData()
    },
    toScroll() {
      this.$nextTick(() => {
        if (document.querySelector('.schedule-content')) {
          document.querySelector('.schedule-content').scrollTop = this.scroll
        }
      })
    },
    getData() {
      this.getRehData()
      this.getBatchData()
      this.getOrderData()
    },
    getRehData() {
      this.isLoading.re_h = true
      this.$get('/homeStat/getDataBatch', {
        main_type: 're_h', // re_h关联和关联详情 ，bat 批次，ord 订单
        date_range: {
          start: this.curDate.re_h,
        },
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading.re_h = false
          let data = res.data
          this.tableData.stat = data.list.stat
          this.header.stat = data.title.stat
          this.tableData.re = data.list.re
          this.header.re = data.title.re
        }
      })
    },
    getBatchData() {
      this.isLoading.bat = true
      this.$get('/homeStat/getDataBatch', {
        main_type: 'bat', // re_h关联和关联详情 ，bat 批次，ord 订单
        date_range: {
          start: this.curDate.bat,
        },
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading.bat = false

          this.curBatch = [] // 不能选择不同天的批次

          let data = res.data
          this.tableData.batch = data.list.batch
          this.header.batch = data.title.batch

          if (this.autoChosedLmId) {
            this.$nextTick(() => {
              setTimeout(() => {
                this.batchShow = true
                this.curHandle = 'oneBatch'
                let row =
                  this.tableData['batch'] &&
                  this.tableData['batch'].filter(
                    (item) => item['lm_id']['value'] == this.autoChosedLmId,
                  )[0]
                this.$refs.batchTable.$refs.table.toggleRowSelection(row)
                this.autoChosedLmId = undefined
              }, 0)
            })
          }
        }
      })
    },
    getOrderData() {
      this.isLoading.bat = true
      this.$get('/homeStat/getDataBatch', {
        main_type: 'ord', // re_h关联和关联详情 ，bat 批次，ord 订单
        date_range: {
          start: this.curDate.ord,
        },
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading.ord = false

          this.curOrder = [] // 不能选择不同天的订单

          let data = res.data
          this.tableData.or = data.list.or
          this.header.or = data.title.or

          if (this.autoChosedOrId) {
            this.$nextTick(() => {
              setTimeout(() => {
                this.orderShow = true
                this.curHandle = 'oneOrder'
                let row =
                  this.tableData['or'] &&
                  this.tableData['or'].filter(
                    (item) => item['or_id']['value'] == this.autoChosedOrId,
                  )[0]
                this.$refs.orTable.$refs.table.toggleRowSelection(row)
                this.autoChosedOrId = undefined
              }, 0)
            })
          }
        }
      })
    },
    toPage(type) {
      switch (type) {
        // 关联记录
        case 'releRecord':
          this.$toPage(this, 'recordList', {
            type: 'rele',
            date: this.curDate,
            key: this.curLoKey,
          })
          break
        case 'addOrder':
          this.$toPage(this, 'salesAdd', { status: 'add' })
          break
        case 'import':
          this.$toPage(this, 'larvaImport', { status: 'add' })
          break
      }
    },
    handle(type) {
      switch (type) {
        // 取消操作
        case 'cancelHandle':
          this.initHandle()
          break
        // 关联批次：先单选订单再关联多个批次
        case 'releBatch':
          this.curHandle = 'oneOrder'
          this.orderShow = true
          this.orderSingle = true
          break
        // 关联订单：先单选批次再关联多个订单
        case 'releOrder':
          this.curHandle = 'oneBatch'
          this.batchShow = true
          this.batchSingle = true
          break
        // 重选批次
        case 'reChoseBatch':
          this.curHandle = 'oneBatch'
          this.batchShow = true
          this.batchSingle = true
          this.curBatch = []
          break
        // 重选订单
        case 'reChoseOrder':
          this.curHandle = 'oneOrder'
          this.orderShow = true
          this.orderSingle = true
          this.curOrder = []
          break
        // 确认订单
        case 'confirmOrder':
          {
            this.reShow = true
            let re_info = []
            if (this.curHandle == 'oneBatch') {
              this.curOrder.forEach((item) => {
                let customer = (item.customer && item.customer.value) || '暂无'
                let quantity = (item.quantity && item.quantity.value) || 0
                let re_count = (item.re_count && item.re_count.value) || 0
                re_info.push({
                  label: `${customer}(${
                    Number(quantity || 0) - Number(re_count || 0)
                  }万)`,
                  re_count: getKeyObj(item.or_id.value, this.re_info, 'or_id')
                    .re_count,
                  or_id: item.or_id.value,
                })
              })
            } else if (this.curHandle == 'oneOrder') {
              this.curBatch.forEach((item) => {
                let batch = (item.batch && item.batch.value) || '暂无'
                let mc_name = (item.mc_name && item.mc_name.value) || '暂无'
                let e_count = (item.e_count && item.e_count.value) || 0
                re_info.push({
                  label: `${batch}-${mc_name}(${Number(e_count || 0)}万)`,
                  re_count: undefined,
                  lm_id: item.lm_id.value,
                })
              })
            }
            this.re_info = re_info
          }

          break
        // 下单请求
        case 'addRele':
          this.addRelation()
          break
        // 取消下单
        case 'cancelRele':
          this.reShow = false
          break
        // 下单后继续关联
        case 'continue':
          this.addSuccessShow = false
          this.curOrder = []
          this.curBatch = []
          break
      }
    },
    selectChange(val, type) {
      switch (type) {
        case 'batch':
          this.curBatch = val
          if (this.curHandle == 'oneBatch' && val.length) {
            this.orderShow = true
          }
          break
        case 'order':
          this.curOrder = val
          if (this.curHandle == 'oneOrder' && val.length) {
            this.batchShow = true
          }
          break
      }
    },
    // 确认订单
    addRelation() {
      if (this.curHandle == 'oneBatch') {
        // 一个批次 关联 多个订单
        this.$post('/po/addRelation', {
          lm_id: this.curBatch[0]['lm_id'].value,
          re_info: this.re_info,
        }).then((res) => {
          if (res.code == 10000) {
            this.releSuccess(res)
          }
        })
      } else if (this.curHandle == 'oneOrder') {
        let re_info = JSON.parse(JSON.stringify(this.re_info))
        re_info.forEach((item) => {
          item.or_id = this.curOrder[0]['or_id'].value
        })
        // 一个订单 关联 多个批次
        this.$post('/po/addRelationV2', {
          re_info,
        }).then((res) => {
          if (res.code == 10000) {
            this.releSuccess(res)
          }
        })
      }
    },
    // 确认订单成功
    releSuccess(res) {
      // this.$toast.success('确认订单成功！');
      this.reShow = false
      // this.initHandle();
      this.getBatchData()
      this.getOrderData()
      this.addSuccessShow = true
      this.curLoKey = res.data.lo_key
    },
    // 初始化 关联操作 的数据
    initHandle() {
      this.curHandle = undefined
      this.batchShow = false
      this.orderShow = false
      this.batchSingle = false
      this.orderSingle = false
      this.curOrder = []
      this.curBatch = []
      this.re_info = []
      this.curLoKey = []
    },
    successHandle(type) {
      switch (type) {
        case 'cancel':
          this.initHandle()
          this.addSuccessShow = false
          break
        case 'confirm':
          {
            let p = '1'
            let title = '批次订单关联'
            let batch = []
            let order = []
            let re = []
            this.curBatch.forEach((item) => {
              re.push({
                type: '1',
                re_info: {
                  l_key: item?.l_key?.value,
                },
              })
            })

            this.curOrder.forEach((item) => {
              re.push({
                type: '4',
                re_info: {
                  or_id: item?.or_id?.value,
                },
              })
            })
            let remark = [
              {
                label: '批次',
                value: batch.join('、'),
              },
              {
                label: '订单',
                value: order.join('、'),
              },
              {
                label: '关联量',
                value: this.curCount,
                extra: '万',
              },
            ]
            this.toNotice({ p, title, remark, re })
            this.initHandle()
            this.addSuccessShow = false
          }

          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.rele {
  padding: 0.5rem;

  .row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .main-content {
    position: relative;
  }

  .row-between {
    align-items: center;
    padding: 0.5rem 0;
  }

  /deep/.date-select-item,
  .title {
    color: @titleColor;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .handles {
    display: flex;

    .handle-btn {
      color: #fff;
      background: @mainColor;
      padding: 0.1rem 0.2rem;
      border-radius: 0.2rem;
      margin-left: 0.5rem;
    }
    .handle-txt {
      font-size: 0.875rem;
      font-family: PingFang SC;
      font-weight: 600;
      line-height: 1.25rem;
      color: @textColor;
      opacity: 0.6;
      margin-left: 0.5rem;
    }
    .handle-tip {
      color: @mainColor;
      line-height: 1.25rem;
      font-size: 0.875rem;
      margin-left: 0.5rem;
    }
  }
}
</style>

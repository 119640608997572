<template>
  <van-popup
    class="form-popup"
    @close="clickFn('cancel')"
    @closed="clickFn('cancel')"
    v-model="showVisible"
    :round="!isPage"
    position="bottom"
    :style="{ height: isPage ? '100vh' : 'auto' }"
  >
    <div class="popup-header">
      <div class="popup-header-cancel" @click="clickFn('cancel')">
        {{ leftTxt }}
      </div>
      <div class="popup-header-title">{{ title }}</div>
      <div
        class="popup-header-confirm"
        native-type="submit"
        @click="clickFn('confirm')"
      >
        {{ rightTxt }}
      </div>
    </div>
    <div class="popup-content">
      <slot name="top"></slot>
      <yiyu-form
        :inputAlign="inputAlign"
        @submit="onSubmit"
        ref="form"
        v-bind="$props"
      ></yiyu-form>
      <slot name="bottom"></slot>
      <fixed-btn btnTxt="保存" @click="clickFn('confirm')"></fixed-btn>
    </div>
  </van-popup>
</template>

<script>
import yiyuForm from '@/components/form/yiyuForm.vue';
import fixedBtn from '@/components/button/fixedBtn';
export default {
  name: 'formPopup',
  components: {
    yiyuForm,
    fixedBtn,
  },
  inheritAttrs: false, // 必不可少的代码,避免el-input的属性被添加到根元素div上
  props: {
    inputAlign: {
      type: String,
      default: 'center',
    },
    formOpts: {
      type: Array,
      default: () => [],
    },
    _this: {
      required: true,
    },
    show: {
      // sync
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    leftTxt: {
      type: String,
      default: '取消',
    },
    rightTxt: {
      type: String,
      default: '确认',
    },
    isPage: {
      // 是否以页面的形式展示
      type: Boolean,
      default: false,
    },
    formName: {
      type: String,
      default: 'form',
    },
  },
  data() {
    return { showVisible: this.show };
  },
  watch: {
    show() {
      this.showVisible = this.show;
    },
    showVisible(val) {
      this.$emit('update:show', val);
    },
  },
  methods: {
    clickFn(type) {
      switch (type) {
        case 'cancel':
          this.$emit('update:show', false);

          this.$emit('cancel');
          break;
        case 'confirm':
          this.$refs.form.$refs.yiyuForm.submit();
          break;
      }
    },
    onInput(val, key) {
      this['_this'][this.formName][key] = val;
    },
    onSubmit(values) {
      this.$emit('confirm', values);
    },
  },
};
</script>

<style lang="less" scoped>
.form-popup {
  .popup-header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    padding: 0 1rem;
    height: 64px;
    line-height: 64px;
    &-cancel {
      font-size: 1.1rem;
      color: @titleColor;
    }
    &-title {
      font-size: 20px;
      color: @titleColor;
    }
    &-confirm {
      font-size: 1.1rem;
      color: @mainColor;
    }
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  .extra-txt {
    margin-left: 16px;
  }
}
</style>

<template>
  <div>
    <yiyu-navbar tab-title="成活率设置"></yiyu-navbar>
    <div class="survival_rate">
      <van-form input-align="center" center="true" error-message-align="center">
        <van-field
          v-model="survival_rate"
          label="成活率"
          placeholder="请输入成活率"
          type="number"
        >
          <template #extra>
            <div class="extra-txt">%</div>
          </template>
        </van-field>
      </van-form>
      <van-button @click="editSetting" type="primary">完成</van-button>
    </div>
  </div>
</template>

<script>
import yiyuNavbar from '@/components/navbar'
export default {
  name: 'surivalRateSet',
  data() {
    return {
      postLoading: false,
      survival_rate: undefined,
      s_key: undefined,
    }
  },
  mounted() {
    this.getSetting()
  },
  components: {
    yiyuNavbar,
  },
  methods: {
    getSetting() {
      this.$get('/setting/getSetting', {}).then((res) => {
        if (res.code == 10000) {
          this.survival_rate = res.data.survival_rate
          this.s_key = res.data.s_key
        }
      })
    },
    editSetting() {
      if (this.postLoading) return
      this.postLoading = true
      this.$post('/setting/addSetting', {
        s_key: this.s_key,
        survival_rate: this.survival_rate,
      }).then((res) => {
        this.postLoading = false
        if (res.code == 10000) {
          this.$toast.success('修改成功')
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.survival_rate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  .extra-txt {
    color: @textColor;
  }

  /deep/.van-cell__title.van-field__label {
    font-size: 1.1rem;
    font-weight: 500;
    color: @titleColor;
    margin-bottom: 20px;
  }

  /deep/.van-field__control {
    font-size: 1.1rem;
    font-weight: 500;
    color: @titleColor;
  }
}
</style>

<template>
  <div class="member-accept">
    <div class="content">
      <img class="banner" src="@/assets/images/invite_worker.png" alt="" />
      <section class="list">
        <div class="title">成员信息</div>
        <yiyu-form
          :formOpts="formOpts"
          :form="form"
          :_this="thisAasia"
          @submit="submit"
          ref="form"
        >
          <template #code>
            <proof :only-button="true" :form="proofList"></proof>
          </template>
          <template #footer>
            <div class="footer relative">
              <div
                class="btn"
                @click="accept"
                :disabled="loginDisabled"
                native-type="submit"
                v-myLoad="postLoading"
              >
                验证并登录
              </div>
            </div>
          </template>
        </yiyu-form>
      </section>
    </div>
    <van-overlay :show="mark" @click="back">
      <div class="mark-con" v-if="isWeiXin()">
        <div class="mark-con-txt">
          <van-icon
            name="share"
            color="#fff"
            size="30"
            class="share-icon"
          ></van-icon>
          <span>点击右上角</span>
          <span>分享给同事吧</span>
        </div>
      </div>
      <div class="mark-center" v-else>
        <div>分享该链接</div>
        <div>邀请同事吧</div>
      </div>
      <van-popup v-model="linkShow" position="bottom" class="link-popup" round>
        <div class="title">邀请链接</div>
        <input v-model="link" ref="copy_link" class="hide" />
        <div class="link ellipsis">{{ link }}</div>
        <van-button class="btn" type="primary" @click="copyLink"
          >复制链接</van-button
        >
      </van-popup>
    </van-overlay>
  </div>
</template>

<script>
import { rules } from '@/utils/mixins'
import { getKeyArr } from '@/utils/utils'
import { isWeiXin } from '@/utils/weixin'
import yiyuForm from '@/components/form'
import { Overlay } from 'vant'
import { getToken, setToken } from '@/utils/cookie'
import proof from '@/components/proof/proof'
export default {
  name: 'memberAccept',
  mixins: [rules],
  data() {
    return {
      postLoading: false,
      shareKey: undefined,
      thisAasia: this,
      mark: false,
      form: {
        nickname: undefined,
        role: undefined,
        role_type: undefined, // 技术负责人 还分 测试 育苗 出苗
        charge_mc: undefined, // 负责基地[]
        roleName: undefined,
        account: undefined,
        code: undefined,
      },
      linkShow: true,
      link: window.location.href,
    }
  },
  components: {
    yiyuForm,
    proof,
    [Overlay.name]: Overlay,
  },
  computed: {
    codeDisabled() {
      if (!this.form.account) return true
      return !this.yiyuPhone(this.form.account)
    },
    loginDisabled() {
      return this.codeDisabled && !this.form.code
    },
    formOpts() {
      // let _this = this;
      let opts = [
        {
          title: '',
          child: [
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              value: this.form.nickname,
              name: 'nickname',
              label: '成员姓名',
              placeholder: '请输入成员姓名',
              rules: [{ required: true, message: '请输入成员姓名' }],
            },
            {
              required: false,
              colon: true,
              readonly: true,
              type: 'text', // 默认，可以不写
              value: this.form.roleName,
              name: 'roleName',
              label: '成员角色',
              placeholder: '请选择成员角色',
              rules: [{ required: false, message: '请选择成员角色' }],
            },
            {
              required: false,
              colon: true,
              readonly: true,
              type: 'text', // 默认，可以不写
              value: this.form.charge_mcName,
              name: 'roleName',
              label: '负责基地',
              placeholder: '负责基地',
              show: this.form.charge_mc && this.form.charge_mc.length,
            },
            {
              required: false,
              colon: true,
              readonly: true,
              type: 'text', // 默认，可以不写
              value: this.form.ch_wh_name,
              name: 'ch_wh_name',
              label: '默认基地',
              placeholder: '默认基地'
            },
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              value: this.form.account,
              name: 'account',
              label: '手机号',
              placeholder: '请输入手机号',
              rules: [
                { required: true, message: '请输入手机号' },
                { validator: this.yiyuPhone, message: '请输入正确的手机号' },
              ],
            },
            {
              required: true,
              colon: true,
              slot: 'code',
              formType: 'input', // 默认
              value: this.form.code,
              name: 'code',
              label: '验证码',
              placeholder: '请输入',
              rules: [{ required: true, message: '请输入' }],
            },
          ],
        },
      ]
      return opts
    },
    proofList() {
      return { account: this.form.account }
    },
  },
  methods: {
    getShareInfo() {
      // 校验邀请码 403d6a8dc6ead6c8e11d0800597f1c54
      this.$get('/member/checkShareKey', {
        share_key: this.shareKey,
      }).then((res) => {
        if (res.code === 10000) {
          let data = res.data

          data.account = undefined
          data.code = undefined

          let tmp = []
          data.charge_mc &&
            data.charge_mc.forEach((item) => {
              tmp.push(item.value)
            })
          data.charge_mcName = tmp.join('、')
          data.ch_wh_name = res.data.ch_wh_name
          this.form = data

          this.form.roleName = getKeyArr(this.form.roles, 'role_value').join(
            '、',
          )

          if (getToken()) {
            this.mark = true
          }
        } else if (res.code === 10003) {
          this.$toast.fail(res.msg)
          this.$replace(this, { path: '/FF_more' })
        } else {
          this.$replace(this, { path: '/FF_login' })
        }
      })
    },
    submit() {
      // 接受邀请
      if (this.postLoading) return
      this.postLoading = true

      let charge_mc = []
      this.form.charge_mc.forEach((item) => {
        charge_mc.push(item.key)
      })
      this.$post('/member/acceptInvitation', {
        account: this.form.account,
        code: this.form.code,
        nickname: this.form.nickname,
        share_key: this.shareKey,
        charge_mc: charge_mc,
      }).then((res) => {
        this.postLoading = false
        if (res.code === 10000) {
          setToken(res.data.token)
          this.$push(this, { path: '/' })
        }
      })
    },
    accept() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    // getCode() {
    //   // 获取验证码
    //   this.$get('/login/sendLoginPhoneMsg', {
    //     account: this.form.account,
    //   }).then((res) => {
    //     if (res.code === 10000) {
    //       this.$toast.success('验证码已发送')
    //     }
    //   })
    // },
    isWeiXin() {
      return isWeiXin()
    },
    copyLink() {
      this.$refs['copy_link'].select()
      let flag = document.execCommand('Copy', 'false', null)

      if (flag) {
        this.$toast.success('复制成功！')
        this.linkShow = false
        this.$go(-1, this, {
          name: 'memberList',
        })
      } else {
        this.$toast.fail('复制失败！请重试')
      }
    },
    back() {
      this.linkShow = false
      this.mark = false
      // this.$go(-1, this, {
      //   name: 'memberList',
      // })
    },
  },
  created() {
    if (this.$route.query) {
      this.shareKey = this.$route.query.s
      if (this.shareKey) this.getShareInfo()
    }
  },
}
</script>

<style lang="less" scoped>
.member-accept {
  background-color: @mainColor;
  padding: 20px 16px;
  font-size: 0;
  box-sizing: border-box;
  min-height: 100%; // 100vh;

  .content {
    background: #fff;
    border-radius: 8px;

    .banner {
      width: 100%;
    }

    .list {
      padding: 0 30px 24px;
      .title {
        font-size: 1.1rem;
        color: @titleColor;
        font-weight: 500;
        height: 70px;
        line-height: 70px;
        border-bottom: 1px solid @lineColor;
      }

      .footer {
        margin-top: 2.5rem;
        .btn {
          background: @mainColor;
          color: #fff;
          font-size: 1.1rem;
          height: 48px;
          border-radius: 8px;
          line-height: 48px;
          text-align: center;
        }
      }
    }
  }

  /deep/ .yiyu-box-section {
    padding: 0;
    margin: 0;
    border-radius: 0;
    .title {
      display: none;
    }

    /deep/ .van-cell__title.van-field__label {
      width: auto;
    }
  }

  .code-btn {
    color: #fff;
    background: @mainColor;
    height: 40px;
    font-size: 1rem;
    border-radius: 8px;
    margin-left: 4px;
  }

  .mark-center {
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
    .FlexCenter;
    flex-direction: column;
    height: 100%; // 100vh;

    div {
      font-size: 1.2rem;
    }
  }

  .mark-con {
    height: 100%; // 100vh;
    .FlexCenter;
    // position: absolute;
    // right: 16px;
    // top: 16px;

    &-txt {
      font-size: 1.2rem;
      font-weight: 500;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 20px;
      .share-icon {
        transform: rotate(-45deg);
        margin-bottom: 4px;
        margin-left: 20px;
      }
    }

    span {
      font-size: 1.2rem;
      margin-bottom: 4px;
    }
  }

  /deep/.van-overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .link-popup {
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;

    .title {
      font-size: 1.2rem;
      color: @titleColor;
      font-weight: 500;
    }

    .link {
      margin: 32px 16px;
      padding: 16px 8px;
      background: @bgColor;
      border: 1px solid @lineColor;
      border-radius: 4px;
    }

    .btn {
      width: 100%;
      border-radius: 4px;
    }

    .hide {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
  }
}
</style>

<template>
  <div class="supplier grey-bg">
    <yiyu-navbar tab-title="运输设置"></yiyu-navbar>
    <div class="content" v-myLoad="isLoading" style="position: relative">
      <div v-for="(item, index) in list" :key="index" class="list-item">
        <div class="list-item-left" @click="edit(item)">
          <div class="row">
            <div class="left">
              <div class="name">{{ item.title }}</div>
            </div>
            <div class="right contact">
              <yiyu-phone
                :icon="true"
                :phone="item.phone"
                v-if="item.phone"
              ></yiyu-phone
              >{{ item.name }}
            </div>
          </div>
          <div class="row">
            <div class="left traffic">
              <span>{{ item.starting_point || '暂无' }}</span>
              <span class="line">————</span>
              <img
                src="@/assets/icons/truck.png"
                alt=""
                v-if="item.type == 1"
                class="w20"
              />
              <img
                src="@/assets/icons/plane.png"
                alt=""
                v-if="item.type == 2"
                class="w20"
              />
              <span class="line">————</span>
              <span>{{ item.ending_point || '暂无' }}</span>
            </div>
            <div class="right">
              <span v-if="item.freight">¥{{ item.freight }}</span>
            </div>
          </div>
          <div class="row">
            <div class="left">预计用时：{{ item.duration }}时</div>
          </div>
        </div>
        <div class="list-item-right" @click="delData(item.t_key)">
          <img
            src="@/assets/icons/del_blue.png"
            alt=""
            width="28px"
            height="28px"
            class="img-filter del"
          />
        </div>
      </div>
      <yiyu-empty v-if="list.length == 0"></yiyu-empty>
      <drag-btn @add="addData"></drag-btn>
    </div>

    <form-popup
      :show.sync="addShow"
      v-if="addShow"
      :_this="thisAlias"
      :form-opts="formOpts"
      @cancel="addShow = false"
      @confirm="editData"
    ></form-popup>

    <yiyu-picker
      v-if="showContactPicker"
      :curKey="form.contacts"
      :show.sync="showContactPicker"
      :columns="contactColumns"
      value-key="name"
      keyName="c_key"
      @confirm="confirmPicker($event, 'contact')"
      @cancel="cancelPicker('contact')"
      :loading="contactLoading"
    >
      <template #empty>
        <div class="contact-empty">
          <span class="btn" @click="addContact">去添加联系人</span>
        </div>
      </template>
    </yiyu-picker>
    <yiyu-picker
      v-if="showTypePicker"
      :curKey="form.type"
      :show.sync="showTypePicker"
      :columns="typeColumns"
      @confirm="confirmPicker($event, 'type')"
      @cancel="cancelPicker('type')"
    >
    </yiyu-picker>
  </div>
</template>

<script>
import yiyuPhone from '@/components/feedback/phone.vue'
import dragBtn from '@/components/button/dragBtn'
import yiyuEmpty from '@/components/layout/empty.vue'
import yiyuNavbar from '@/components/navbar'
import formPopup from '@/components/popup/formPopup'
import yiyuPicker from '@/components/popup/picker'
import { Dialog } from 'vant'
import { numToStr, getKeyValue } from '@/utils/utils'
export default {
  name: 'transportSet',
  components: {
    yiyuEmpty,
    yiyuNavbar,
    dragBtn,
    formPopup,
    yiyuPicker,
    yiyuPhone,
  },
  data() {
    return {
      postLoading: false,
      contactLoading: false,
      toAdd: false,
      btnLoading: false,
      list: [],
      addShow: false,
      thisAlias: this,
      form: {
        t_key: undefined,
        title: undefined, // 标题
        starting_point: undefined, // 起点
        ending_point: undefined, // 终点
        duration: undefined, // 耗时
        contacts: undefined, // 联系人key
        contactsName: undefined,
        freight: undefined, // 费用
        type: undefined, // 类型 1 短途 2 长途
        typeName: undefined,
        number: undefined, // 长途行程需要 航班号
      },
      isLoading: true,
      showContactPicker: false,
      showTypePicker: false,
      typeColumns: [
        {
          key: '1',
          text: '车运',
        },
        {
          key: '2',
          text: '空运',
        },
      ],
      status: undefined,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path.indexOf('setting') !== -1) {
        vm.toAdd = false
      } else {
        vm.toAdd = true
      }
    })
  },
  mounted() {
    this.getData()
    if (this.toAdd) {
      this.addData()
    }
  },
  activated() {
    if (this.toAdd) {
      this.status = 'add'
      this.addShow = true
    }
  },
  computed: {
    contactColumns() {
      let list = this.$store.state.setting.contacts
      return list
    },
    formOpts() {
      let _this = this
      return [
        {
          required: true,
          colon: true,
          formType: 'select',
          value: _this.form.typeName,
          name: 'typeName',
          label: '运输类型',
          placeholder: '请选择运输类型',
          click() {
            _this.showTypePicker = true
          },
          rules: [{ required: true, message: '请选择运输类型' }],
        },
        {
          required: true,
          colon: true,
          formType: 'input',
          value: this.form.title,
          name: 'title',
          label: '标题',
          placeholder: '可由出发地、目的地自动生成',
          rules: [{ required: true, message: '请输入标题' }],
          show: _this.form.type != undefined,
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          value: this.form.starting_point,
          name: 'starting_point',
          label: '出发地',
          placeholder: '请输入出发地',
          show: _this.form.type != undefined,
          change: () => {
            if (this.form.starting_point && this.form.ending_point) {
              this.form.title =
                this.form.starting_point + '至' + this.form.ending_point
            }
          },
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          value: this.form.ending_point,
          name: 'ending_point',
          label: '目的地',
          placeholder: '请输入目的地',
          show: _this.form.type != undefined,
          change: () => {
            if (this.form.starting_point && this.form.ending_point) {
              this.form.title =
                this.form.starting_point + '至' + this.form.ending_point
            }
          },
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          value: this.form.duration,
          name: 'duration',
          label: '预计用时',
          placeholder: '请输入预计用时',
          extra: '时',
          show: _this.form.type != undefined,
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          value: this.form.number,
          name: 'number',
          label: '航班号',
          placeholder: '请输入航班号',
          show: _this.form.type == 2,
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          value: _this.form.contactsName,
          name: 'contactsName',
          label: '联系人',
          placeholder: '请选择联系人',
          click() {
            _this.showContactPicker = true
          },
          show: _this.form.type != undefined,
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          value: this.form.freight,
          name: 'freight',
          label: '运费',
          placeholder: '请输入运费',
          extra: '元',
          show: _this.form.type != undefined,
        },
      ]
    },
  },
  watch: {
    'form.starting_point'() {
      // if (this.status == 'add') {
      if (
        (!this.form.title || this.form.title.includes('到')) &&
        this.form.starting_point &&
        this.form.ending_point
      ) {
        this.form.title =
          this.form.starting_point + '到' + this.form.ending_point
      }
      // }
    },
    'form.ending_point'() {
      // if (this.status == 'add') {
      if (
        (!this.form.title || this.form.title.includes('到')) &&
        this.form.starting_point &&
        this.form.ending_point
      ) {
        this.form.title =
          this.form.starting_point + '到' + this.form.ending_point
      }
      // }
    },
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/transport/getTransportList', {}).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.list = res.data.list
        }
      })
      if (!this.$store.state.setting.getContacts) {
        this.contactLoading = true
        this.$store.dispatch('getContacts', {}).then((res) => {
          if (res.code == 10000) {
            this.contactLoading = false
          }
        })
      }
    },
    delData(key) {
      Dialog.confirm({
        // title: "标题",
        message: '确认删除？',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/transport/delTransport', {
            t_keys: [key],
          }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success('删除成功！')
              this.getData()
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    editData() {
      if (this.btnLoading) return
      this.btnLoading = true
      this.$post('/transport/addTransport', numToStr(this.form)).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          if (this.form.t_key) {
            this.$toast.success('修改成功！')
          } else {
            this.$toast.success('新增成功！')
          }
          this.addShow = false
          this.getData()
        }
      })
    },
    addData() {
      this.form = {
        t_key: undefined,
        title: undefined, // 标题
        starting_point: undefined, // 起点
        ending_point: undefined, // 终点
        duration: undefined, // 耗时
        contacts: undefined, // 联系人key
        contactsName: undefined,
        freight: undefined, // 费用
        type: undefined, // 类型 1 短途 2 长途
        typeName: undefined,
        number: undefined, // 长途行程需要 航班号
      }
      this.status = 'add'
      this.addShow = true
      this.showTypePicker = true
    },
    edit(item) {
      for (let key in this.form) {
        item[key] = item[key] || ''
      }
      item.contactsName = getKeyValue(
        item.contacts,
        this.contactColumns,
        'name',
        'c_key',
      )
      item.typeName = item.type == 1 ? '短途' : '长途'
      this.form = JSON.parse(JSON.stringify(item)) // 会把undefined弄没
      this.status = 'edit'
      this.addShow = true
    },
    addContact() {
      this.$toPage(this, 'contactSet')
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'contact':
          this.form.contacts = e[0].c_key
          this.form.contactsName = e[0].name
          this.showContactPicker = false
          break
        case 'type':
          this.form.type = e[0].key
          this.form.typeName = e[0].text
          this.showTypePicker = false
          break
      }
    },
    cancelPicker(type) {
      switch (type) {
        case 'contact':
          this.showContactPicker = false
          break
        case 'type':
          this.showTypePicker = false
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.supplier {
  min-height: 100%; // 100vh;

  .list-item {
    background: #fff;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    &-left {
      padding-right: 0.5rem;
      flex: 1;
    }

    &-right {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      margin-right: 0.5rem;
      border-left: 1px solid @lineColor;
    }

    .name {
      color: @titleColor;
      font-weight: 500px;
      font-size: 1.1rem;
    }

    .remark {
      color: @textColor;
      font-weight: 500px;
      font-size: 1rem;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    .contact {
      display: flex;
    }
    .traffic {
      display: flex;
      align-items: center;
    }
  }

  .line {
    margin: 4px;
  }
}
/deep/ .yiyu-empty {
  margin-top: 2.5rem;
}
</style>

<template>
  <div class="member-list">
    <yiyu-navbar tab-title="成员管理"></yiyu-navbar>
    <div class="main">
      <van-collapse v-model="tabName" accordion @change="tabData">
        <van-collapse-item
          :title="value"
          v-for="(value, key) in roleObj"
          :key="key"
          :name="key"
          ><van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
            v-myLoad="loading"
            style="position: relative"
          >
            <div
              class="content"
              center
              v-for="(item, index) in list"
              :key="index"
              @click="seeMember(item.user_key)"
            >
              <info :info="item">
                <template #right>
                  <div class="status">
                    <div class="status-item-invite green" v-if="!item.account">
                      邀请中
                    </div>
                    <div class="status-item-leave" v-if="item.openid">
                      已绑定
                    </div>
                    <div
                      class="status-item-invite"
                      v-if="item.account && !item.openid"
                    >
                      未绑定
                    </div>
                    <div class="status-item-leave" v-if="item.status == 2">
                      已离职
                    </div>
                  </div>
                </template>
              </info>
            </div>
            <yiyu-empty v-if="!list.length"></yiyu-empty> </van-list
        ></van-collapse-item>
      </van-collapse>
    </div>

    <fixed-btn
      btnTxt="邀请成员"
      @click="invite"
      v-if="IS_PAGE('memberInvite')"
    ></fixed-btn>
    <drag-btn slot-name="top" :position="{ x: '90vw', y: '85vh' }"></drag-btn>
  </div>
</template>
<script>
import dragBtn from '@/components/button/dragBtn.vue'
import { List } from 'vant'
import yiyuEmpty from '@/components/layout/empty.vue'
import fixedBtn from '@/components/button/fixedBtn'
import yiyuNavbar from '@/components/navbar'
import info from '@/bComponents/info'
import { mapState } from 'vuex'

export default {
  name: 'memberList',
  components: {
    yiyuEmpty,
    fixedBtn,
    yiyuNavbar,
    dragBtn,
    info,
    [List.name]: List,
  },
  data() {
    return {
      list: [],
      value: '',
      loading: false,
      finished: false,
      page: 1,
      count: 10,
      total: 0,
      userKey: undefined,
      tabName: '',
      role: undefined,
      scroll: 0,
      activated: false,
    }
  },
  computed: {
    ...mapState({
      roleObj: (state) => state.basic.roleObj,
    }),
  },
  mounted() {
    this.activated = true
    this.refreshData()
  },
  activated() {
    if (this.activated) {
      this.activated = false
      return
    }

    this.getData(1, this.page * this.count)
    this.$nextTick(() => {
      setTimeout(() => {
        if (document.querySelector('.member-list')) {
          document.querySelector('.member-list').scrollTop = this.scroll
        }
      })
    }, 0)
  },
  beforeRouteLeave(to, from, next) {
    this.scroll =
      document.querySelector('.member-list') &&
      document.querySelector('.member-list').scrollTop
    next()
  },
  methods: {
    getData(page, count) {
      this.loading = true
      let page1 = page || this.page
      let count1 = count || this.count

      // 获取成员列表
      this.$get('/member/getMember', {
        page: page1,
        count: count1,
        role: this.role == undefined ? [] : [this.role],
      }).then((res) => {
        this.loading = false
        if (res.code == 10000) {
          let list = res.data.list
          if (count) {
            this.list = list
          } else {
            this.list.push(...list)
          }
          this.total = res.data.total

          if (this.list.length >= this.total) {
            this.finished = true
          }
        }
      })
    },
    tabData(val) {
      this.role = val
      if (this.role == '') this.role = undefined
      this.refreshData()
    },
    refreshData() {
      this.finished = false
      this.initData()
      this.getData()
    },
    initData() {
      this.page = 1
      this.count = 10
      this.list = []
    },
    onSearch() {},
    onLoad() {
      this.loading = true
      this.page++
      this.getData()
    },
    seeMember(val) {
      this.$push(this, { path: '/memberInfo', query: { userKey: val } })
    },
    invite() {
      this.$push(this, { path: '/memberInvite' })
    },
  },
}
</script>
<style lang="less" scoped>
.member-list {
  height: 100%; // 100vh;
  overflow-y: auto;
  .main {
    padding: 0 16px 0;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px;
    border-bottom: 1px solid #f6f6f6;
    .content-avatar {
      height: 40px;
    }

    .left {
      display: flex;
      align-items: center;
    }
  }
  .add {
    display: flex;
  }
  .top-icon {
    display: inline-block;
    margin-right: 12px;
  }
  .yiyu-empty {
    margin-top: 2.5rem;
  }
}
</style>

<template>
  <span class="notice-tag">
    <van-tag :color="color">{{
      $store.state.basic.noticeLevel[value]
    }}</van-tag>
  </span>
</template>

<script>
export default {
  name: 'noticeTag',
  props: {
    value: {
      type: [String, Number],
    },
  },
  computed: {
    color() {
      switch (String(this.value)) {
        case '1':
          return '#d81e06'
        case '2':
          return '#e0620d'
        case '3':
          return '#1196db'
        case '4':
          return '#36ab60'
        case '5':
          return '#db639b'
        case '6':
        default:
          return '#707070'
      }
    },
  },
}
</script>

<style></style>

import store from '@/store/index'
import { get } from '@/utils/http'

let roleObj = store.state.basic.roleObj
// 所有仓库
export const warehouseFilter = ({ label, keyName }) => ({
  label,
  remote: async () => {
    let { warehouses } = store.state.goods
    if (warehouses.length === 0) {
      const res = await store.dispatch('getWarehouses')
      warehouses = res.data
    }
    return [
      {
        label: `请选择${label}`,
        keyName,
        children: warehouses.map((warehouse) => ({
          label: warehouse.name,
          key: warehouse.key,
        })),
      },
    ]
  },
})
// 所有基地
export const MCFilter = ({ label, keyName }) => ({
  label,
  remote: async () => {
    let mc = []

    const res = await get('/mc/getMCListV2', {
      status: ['1', '2', '3'],
      type: ['0', '1', '2', '3', '4'],
    })
    mc = res.data.list
    let mc_fitler = (item) => ({
      label: item.name,
      key: item.mc_key,
    })
    let typeName = {}
    mc.forEach((item) => {
      if (store.state.basic.farmType[item.type]) {
        item.typeName = store.state.basic.farmType[item.type]
      } else {
        item.typeName = '其他'
      }
      typeName[item.typeName]
        ? typeName[item.typeName].push(mc_fitler(item))
        : (typeName[item.typeName] = [mc_fitler(item)])
    })
    let newList = Object.keys(typeName).map((item) => {
      return {
        label: item,
        keyName,
        children: typeName[item],
      }
    })
    console.log(newList)
    return newList
  },
})
// 物品类别
export const goodsFilter = ({ label, keyName }) => ({
  label,
  remote: async () => {
    const res = await store.dispatch('getUserConfList', {
      yy_type: 'goodsType',
      url: '/entrepot_conf/getNewList',
    })
    let goodsType = res.data.list

    return [
      {
        label: `请选择${label}`,
        keyName,
        children: goodsType.map((goodsType) => ({
          label: goodsType.name,
          key: goodsType.key,
        })),
      },
    ]
  },
})
// 申请部门
export const sectionFilter = ({ label, keyName }) => ({
  label,
  remote: async () => {
    const res = await store.dispatch('getUserConfList', {
      yy_type: 'section',
      url: '/entrepot_conf/getNewList',
    })
    let section = res.data.list

    return [
      {
        label: `${label}`,
        keyName,
        children: section.map((section) => ({
          label: section.name,
          key: section.key,
        })),
      },
    ]
  },
})
// 所有人员
export const memberFilter = ({ label, keyName }) => ({
  label,
  remote: async () => {
    const { data } = await get('/member/getMember')

    let memberList = {}
    const filterVale = (item) => ({
      label: item.nickname,
      key: item.user_key,
    })

    data.list.forEach((item) => {
      const _roleLabel = roleObj[item.role]
      Array.isArray(memberList[_roleLabel])
        ? memberList[_roleLabel].push(filterVale(item))
        : (memberList[_roleLabel] = [filterVale(item)])
    })
    let newList = []
    Object.keys(memberList).map((key) => {
      newList.push({
        label: `${key}(${memberList[key].length})`,
        keyName: keyName,
        children: memberList[key],
      })
    })

    return newList
  },
})

export const convert = (list) => {
  let convert = []
  if (list?.length == 0) return ''
  Array.isArray(list) &&
    list.forEach((item) => {
      convert.push(`${item.conver}${item.parent_name}/${item.name}`)
    })
  return convert.join('、')
}

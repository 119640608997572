<template>
  <div class="main">
    <yiyu-navbar
      v-title
      :data-title="tabTitle"
      :tab-title="tabTitle"
    ></yiyu-navbar>
    <div class="main-content" v-myLoad="isLoading">
      <section class="info">
        <div class="row-between">
          <div
            class="title main-c"
            @click="toPage('batch', form)"
            v-if="form.batch"
          >
            {{ form.batch }}-{{ form.mc_name }}
          </div>
          <div class="more">
            <van-popover
              v-model="showPopover"
              theme="dark"
              trigger="click"
              :actions="actions"
              @select="selectMore"
              placement="bottom-end"
            >
              <template #reference>
                <van-icon name="weapp-nav" />
              </template>
            </van-popover>
          </div>
        </div>
        <div
          class="data-item-center"
          v-if="['esti', 'deal', 'remove', 'move'].includes(type)"
        >
          <data-field :dataObj="getTopDataObj(type, form)"></data-field>
          <div class="handle-btn" @click="toPage('batch', form)">更多</div>
        </div>

        <template v-if="type == 'esti'">
          <div class="section-title">
            第{{ sectionToChinese(form.times) }}次估苗
          </div>
        </template>
        <data-field :dataObj="getDataObj(type, form)" type="row"></data-field>
      </section>
      <section class="rele" v-if="['out', 'rele'].includes(type)">
        <data-field :dataObj="getOrIdData(type, form)" type="row"></data-field>
        <!-- 查看更多 -->
        <see-more :show="showMore" v-if="form.or_id != undefined">
          <data-field
            :dataObj="getMoreData(type, form)"
            type="row"
          ></data-field>
        </see-more>
      </section>

      <section class="pool" v-if="['esti', 'deal', 'remove'].includes(type)">
        <div class="section-title">
          池数据({{ form.pool && form.pool.length }}池)
        </div>
        <div class="pool-item" v-for="(item, index) in form.pool" :key="index">
          <div class="label">{{ item.ws_name }}_{{ item.p_name }}：</div>
          <div
            class="value"
            v-if="type == 'esti' || type == 'deal' || type == 'remove'"
          >
            {{ item.count || 0 }}万
          </div>
          <div class="value" v-if="type == 'deal'">
            {{ item.p_completed == 1 ? '已完结' : '未完结' }}
          </div>
          <div class="value" v-if="type == 'esti'">
            {{ $dayjs(item.h_date).format('YYYY/MM/DD') }}
          </div>
        </div>
        <van-divider v-if="form.pool && form.pool.length == 0"
          >暂无数据</van-divider
        >
      </section>
      <section class="pool" v-if="type == 'move'">
        <div class="section-title">池数据</div>
        <div class="section-second-title">原育苗池</div>
        <div
          class="pool-item"
          v-for="(item, index) in form.mo_info"
          :key="index + 'mo'"
        >
          <div class="label">{{ item.ws_name }}_{{ item.p_name }}：</div>
          <div class="value">{{ item.count || 0 }}万</div>
        </div>
        <van-divider v-if="form.mo_info && form.mo_info.length == 0"
          >暂无数据</van-divider
        >
        <div class="section-second-title">目标育苗池</div>
        <div
          class="pool-item"
          v-for="(item, index) in form.mi_info"
          :key="index + 'mi'"
        >
          <div class="label">{{ item.ws_name }}_{{ item.p_name }}：</div>
          <div class="value">{{ item.count || 0 }}万</div>
        </div>
        <van-divider v-if="form.mi_info && form.mi_info.length == 0"
          >暂无数据</van-divider
        >
      </section>
    </div>
  </div>
</template>

<script>
import seeMore from '@/components/seeMore/seeMore'
import yiyuNavbar from '@/components/navbar'
import { classifyData, sectionToChinese, getRate } from '@/utils/utils'
import dataField from '@/components/layout/dataField'
import { isOverDay } from '@/utils/date'

import { Dialog } from 'vant'
export default {
  name: 'recordDetail',
  components: {
    yiyuNavbar,
    dataField,
    seeMore,
  },
  data() {
    return {
      key: undefined,
      type: undefined,
      isLoading: false,
      typeObj: {
        esti: '估苗',
        deal: '处理',
        remove: '排苗',
        move: '搬苗',
        rele: '关联',
        out: '出苗',
      },
      form: {},
      showPopover: false,
      confList: [],
      showMore: false,
    }
  },
  computed: {
    tabTitle() {
      return this.typeObj[this.type] + '报告'
    },
    actions() {
      let base = [
        {
          text: '新增' + this.typeObj[this.type],
          key: 'add',
          show: true,
        },
        {
          text: '编辑',
          key: 'edit',
          show: ['out', 'esti'].includes(this.type),
        },
        {
          text: '删除',
          key: 'del',
          show: true,
        },
        {
          text: '发送通知',
          key: 'notice',
          show: true,
        },
      ]

      switch (this.type) {
        case 'rele':
          return this.FILTER_HANDLE([
            {
              text: '出苗',
              key: 'out',
              handle: 'LARVA_BATCH_OUT',
            },
            {
              text: '新增关联',
              key: 'add',
              handle: 'HOME_RELE',
            },
            {
              text: '取消关联',
              key: 'del',
              handle: 'LARVA_BATCH_RElE_CANCEL',
            },
            {
              text: '发送通知',
              key: 'notice',
            },
          ])
        case 'out':
          return [
            {
              text: '编辑',
              key: 'edit',
            },
            {
              text: '删除',
              key: 'del',
            },
          ]
        default:
          return base.filter((item) => item.show == true)
      }
    },
  },
  created() {
    if (this.$route.query) {
      let query = this.$route.query
      this.type = query.type
      this.key = query.key
      if (this.key) {
        this.getData()
      }
    }
  },
  methods: {
    getRate(val, val1) {
      return getRate(val, val1)
    },
    isOverDay(val) {
      return isOverDay(val)
    },
    sectionToChinese(val) {
      return sectionToChinese(val)
    },
    getTopDataObj(type, item) {
      switch (type) {
        // 估苗数据
        case 'esti':
          return [
            [
              {
                data: item.launch,
                unitText: '万',
                descText: `投放量`,
              },
              {
                data: item.breed_days,
                unitText: '天',
                descText: `育苗天数`,
              },
            ],
          ]
        // 出苗数据
        case 'out':
          return [
            [
              {
                data: (item.h_count || 0) + '(' + (item.h_rate || 0) + '%)',
                descText: `出苗(%)`,
              },
              {
                data: item.real_count || '暂无',
                unitText: item.real_count ? '万尾' : '',
                descText: `实结`,
              },
              {
                data: item.re_count || '暂无',
                unitText: item.re_count ? '万尾' : '',
                descText: `关联`,
              },
            ],
          ]

        case 'move': // 搬苗数据
        case 'remove': // 排苗数据
        case 'deal': // 处理数据
          return [
            [
              {
                data: item.launch,
                unitText: '万',
                descText: `投放量    `,
              },
              {
                data: item.e_count,
                unitText: '万',
                descText: `估苗量`,
              },
              {
                data: item.breed_days,
                unitText: '天',
                descText: `育苗天数`,
              },
            ],
          ]
      }
    },
    getDataObj(type, item = this.form) {
      let base = [
        {
          descText: '操作人',
          data: item.opt_user,
        },
        {
          descText: '备注',
          data: item.remark,
        },
      ]

      switch (type) {
        // 估苗数据
        case 'esti':
          return [
            [
              {
                data: this.$dayjs(item.e_date).format('YYYY/MM/DD'),
                descText: `估苗日期`,
              },
              {
                data: `${item.count || 0}万(${getRate(
                  item.count,
                  item.launch,
                )}%)`,
                descText: `估苗量(%)`,
              },
              ...base,
            ],
          ]
        // 出苗数据
        case 'out':
          return [
            [
              {
                descText: '出苗量',
                data: item.h_count,
                unitText: '万',
              },
              {
                descText: '实结量',
                data: item.real_count,
                unitText: '万',
              },
              {
                descText: '箱数',
                data: item.box,
                unitText: '箱',
              },
              {
                descText: '实际密度',
                data: item.real_density,
                unitText: '万/箱',
              },
              {
                descText: '打包人数',
                data: item.pack_worker,
                unitText: '人',
              },
              {
                descText: '出苗时间',
                data: this.$dayjs(item.h_date).format('MM/DD HH:mm'),
              },
              ...base,
            ],
          ]
        case 'move': // 搬苗数据
          return [
            [
              {
                descText: '原育苗池',
                data: this.getWs(item.mo_info),
              },
              {
                descText: '目标育苗池',
                unitText: '万',
                data: this.getWs(item.mi_info),
              },
              {
                descText: '搬出',
                data: item.mo_count,
                unitText: '万',
              },
              {
                descText: '搬入',
                data: item.mi_count,
                unitText: '万',
              },
              {
                descText: '搬苗日期',
                data: this.$dayjs(item.m_date).format('YYYY/MM/DD'),
              },
              ...base,
            ],
          ]
        case 'remove': // 排苗数据
          return [
            [
              {
                descText: '完结状态',
                data: this.isOverDay(item.end_date) ? '完结' : '未完结',
              },
              {
                descText: '排苗量',
                unitText: '万',
                data: item.c_count,
              },
              {
                descText: '原因',
                data: this.$store.state.basic.cleanType[item.type],
              },
              {
                descText: '池数',
                unitText: '个',
                data: item.pool?.length,
              },
              {
                descText: '排苗日期',
                data: this.$dayjs(item.c_date).format('YYYY/MM/DD'),
              },
              ...base,
            ],
          ]
        case 'deal': // 处理数据
          return [
            [
              {
                data: this.isOverDay(item.end_date) ? '完结' : '未完结',
                descText: `完结状态`,
              },
              {
                data: item.d_count,
                unitText: '万',
                descText: `处理量`,
              },
              {
                data: item.unit_price,
                unitText: '元/万',
                descText: '单价',
              },
              {
                data: item.sum_price,
                descText: '金额',
                unitText: '元',
              },
              {
                data: this.$dayjs(item.d_date).format('YYYY/MM/DD'),
                descText: '处理日期',
              },
              ...base,
            ],
          ]
        case 'rele':
          return [
            [
              {
                descText: '本次关联',
                data: item.re_count,
                unitText: '万',
              },
              {
                descText: '批次状态',
                data: this.isOverDay(item.end_date) ? '完结' : '未完结',
              },
              {
                descText: '投放量',
                data: item.launch,
                unitText: '万',
              },
              {
                descText: '估苗量',
                data: item.e_count,
                unitText: '万',
              },
              {
                descText: '已关联量',
                data: item.lm_re_count,
                unitText: '万',
              },
            ],
          ]
      }
    },
    getOrIdData(type, item) {
      if (['out', 'rele'].includes(type)) {
        return [
          [
            {
              descText: '订单',
              data: '未关联订单,直接出苗',
              show: item.or_id == undefined,
            },
            {
              show: item.or_id != undefined,
              descText: '订单',
              data: `订单${item.or_id}`,
              callback: () => {
                this.toPage('order', item)
              },
            },
            {
              show: item.or_id != undefined && item.nickname != undefined,
              descText: '客户',
              data: item.nickname,
              callback: () => {
                this.toPage('customer', item)
              },
            },
            {
              show: item.or_id != undefined,
              descText: '预定量',
              data: item.quantity,
              unitText: '万',
            },
            {
              descText: '需求日期',
              data: this.$dayjs(item.scheduled_date).format('YYYY/MM/DD'),
            },
          ],
        ]
      }
    },
    getMoreData(type, item) {
      if (['out', 'rele'].includes(type)) {
        return [
          [
            {
              descText: '密度',
              unitText: '万/箱',
              data: item.density,
            },
            {
              descText: '盐度',
              unitText: '格',
              data: item.salinity,
            },
            {
              descText: '温度',
              unitText: '℃',
              data: item.temperature,
            },
            {
              descText: '印刷袋',
              data: item.bag_name,
            },
            {
              descText: '冰瓶',
              data: item.ice == '1' ? '需要' : '不需要',
            },
          ],
        ]
      }
    },
    getData() {
      this.isLoading = true
      switch (this.type) {
        case 'esti':
          this.$get('/opt/getEsOptDetails', {
            lmce_key: this.key,
          }).then((res) => this.dataDeal(res))
          break
        case 'deal':
          this.$get('/opt/getDisOptDetails', {
            ld_key: this.key,
          }).then((res) => this.dataDeal(res))
          break
        case 'remove':
          this.$get('/opt/getCleanOptDetails', {
            lc_key: this.key,
          }).then((res) => this.dataDeal(res))
          break
        case 'move':
          this.$get('/opt/getMoveOptDetails', {
            lm_key: this.key,
          }).then((res) => this.dataDeal(res))
          break
        case 'rele':
          this.$get('/opt/getRelationOptDetails', {
            lo_key: this.key,
          }).then((res) => this.dataDeal(res))
          break
        case 'out':
          this.$get('/opt/getHarvestOptDetails', {
            lo_key: this.key,
          }).then((res) => this.dataDeal(res))
          break
      }
    },
    dataDeal(res) {
      if (res.code == 10000) {
        this.isLoading = false
        this.form = res.data
      }
    },
    selectMore(action) {
      switch (action.key) {
        case 'add':
          this.addData()
          break
        case 'del':
          {
            let message = '确认删除？'
            if (this.type == 'out') {
              message =
                '删除后与本批次关联的订单将取消关联，且出苗的信息不可再恢复，是否确认删除确认删除？'
            } else if (this.type == 'rele') {
              message = '是否取消关联'
            }
            Dialog.confirm({
              // title: "标题",
              message,
            })
              .then(() => {
                this.delData()
              })
              .catch(() => {
                // on cancel
              })
          }

          break
        case 'edit':
          this.editData()
          break
        case 'out':
          break
        case 'notice':
          this.noticeData()
          break
      }
    },
    addData() {
      switch (this.type) {
        case 'esti':
          this.$toPage(this, 'estimateEdit', {
            lm_id: this.form.lm_id,
            status: 'add',
          })
          break
        case 'deal':
          this.$toPage(this, 'dealEdit', {
            lm_id: this.form.lm_id,
            status: 'add',
            mc_key: this.form.mc_key,
          })
          break
        case 'remove':
          this.$toPage(this, 'removeEdit', {
            lm_id: this.form.lm_id,
            status: 'add',
            mc_key: this.form.mc_key,
          })
          break
        case 'move':
          this.$toPage(this, 'moveEdit', {
            lm_id: this.form.lm_id,
            status: 'add',
          })
          break
        case 'rele':
          this.$toPage(this, 'FF_home', {
            home_type: 'rele',
            date: this.form.created_at,
            lm_id: this.form.lm_id,
          })
          break
        case 'out':
          break
      }
    },
    editData() {
      switch (this.type) {
        case 'esti':
          this.$toPage(this, 'estimateEdit', {
            lm_id: this.form.lm_id,
            lmce_key: this.form.lmce_key,
            status: 'edit',
          })
          break
        case 'deal':
          this.$toPage(this, 'dealEdit', {
            lm_id: this.form.lm_id,
            status: 'edit',
            mc_key: this.form.mc_key,
            ld_key: this.form.ld_key,
          })
          break
        case 'remove':
          this.$toPage(this, 'removeEdit', {
            lm_id: this.form.lm_id,
            status: 'add',
            mc_key: this.form.mc_key,
            lc_key: this.form.lc_key,
          })
          break
        case 'move':
          this.$toPage(this, 'moveEdit', {
            lm_id: this.form.lm_id,
            status: 'edit',
            lm_key: this.form.lm_key,
          })
          break
        case 'out':
          this.$toPage(this, 'outEdit', {
            lm_id: this.form.lm_id,
            lo_key: this.lo_key,
          })
          break
      }
    },
    delData() {
      switch (this.type) {
        case 'esti':
          this.$post('/harvest/delHA', {
            lmce_key: this.form.lmce_key,
          }).then((res) => this.delDataCb(res))
          break
        case 'deal':
          this.$post('/larva/delDisposeRecord', {
            ld_key: this.form.ld_key,
          }).then((res) => this.delDataCb(res))
          break
        case 'remove':
          this.$post('/larva/delCleanRecord', {
            lc_key: this.form.lc_key,
          }).then((res) => this.delDataCb(res))
          break
        case 'move':
          this.$post('/larva/delMove', {
            lm_key: this.form.lm_key,
          }).then((res) => this.delDataCb(res))
          break
        case 'rele':
          this.$post('/po/delRelation', {
            lo_key: this.form.lo_key,
          }).then((res) => this.delDataCb(res))
          break
        case 'out':
          this.$post('/po/delRelation', {
            lo_key: this.form.lo_key,
          }).then((res) => this.delDataCb(res))
          break
      }
    },
    delDataCb(res) {
      if (res.code == 10000) {
        this.$toast.success('删除成功')
        this.$go(-1, this, {
          name: 'recordList',
          query: {
            type: this.type,
          },
        })
      }
    },
    noticeData() {
      let p = '1'
      let title
      let remark
      switch (this.type) {
        case 'esti':
          title = '估苗'
          remark = [
            {
              label: '批次',
              value: this.form.batch,
            },
            {
              label: '投放量',
              value: this.form.launch,
              extra: '万',
            },
            {
              label: '估苗量',
              value: this.form.count,
              extra: '万',
            },
            {
              label: '估苗率',
              value: this.getRate(this.form.count, this.form.launch),
              extra: '%',
            },
          ]
          this.toNotice({
            p,
            title,
            remark,
            re: [
              {
                type: '22',
                re_info: {
                  lmce_key: this.form.lmce_key,
                },
              },
            ],
          })
          break
        case 'deal':
          title = '处理'
          remark = [
            {
              label: '批次',
              value: this.form.batch,
            },
            {
              label: '处理量',
              value: this.form.d_count,
              extra: '万',
            },
            {
              label: '单价',
              value: this.form.unit_price,
              extra: '元/万 ',
            },
            {
              label: '金额',
              value: this.form.sum_price,
              extra: '元',
            },
            {
              label: '处理日期',
              value: this.$dayjs(this.form.d_date).format('YYYY/MM/DD'),
            },
          ]
          this.toNotice({
            p,
            title,
            remark,
            re: [
              {
                type: '28',
                re_info: {
                  ld_key: this.form.ld_key,
                },
              },
            ],
          })
          break
        case 'remove':
          title = '排苗'
          remark = [
            {
              label: '批次',
              value: this.form.batch,
            },
            {
              label: '排苗量',
              value: this.form.c_count,
              extra: '万',
            },
            {
              label: '排苗日期',
              value: this.$dayjs(this.form.c_date).format('YYYY/MM/DD'),
            },
          ]
          this.toNotice({
            p,
            title,
            remark,
            re: [
              {
                type: '27',
                re_info: {
                  lc_key: this.form.lc_key,
                },
              },
            ],
          })
          break
        case 'move':
          {
            title = '搬苗'
            let ori = []
            this.form.mi_info.forEach((item) => {
              ori.push(item.ws_name + '_' + item.p_name)
            })
            let tar = []
            this.form.mo_info.forEach((item) => {
              tar.push(item.ws_name + '_' + item.p_name)
            })
            remark = [
              {
                label: '批次',
                value: this.form.batch,
              },
              {
                label: '原育苗池',
                value: ori.join('、'),
              },
              {
                label: '目标育苗池',
                value: tar.join('、'),
              },
              {
                label: '搬出量',
                value: this.form.mo_count,
                extra: '万',
              },
              {
                label: '搬入量',
                value: this.form.mi_count,
                extra: '万',
              },
              {
                label: '搬苗日期',
                value: this.form.m_date,
              },
            ]
            this.toNotice({
              p,
              title,
              remark,
              re: [
                {
                  type: '26',
                  re_info: {
                    lm_key: this.form.lm_key,
                  },
                },
              ],
            })
          }

          break
        case 'rele':
          title = '关联报告'
          remark = []
          this.toNotice({
            p,
            title,
            remark,
            re: [
              {
                type: '1',
                re_info: {
                  l_key: this.form.l_key,
                },
              },
              {
                type: '4',
                re_info: {
                  or_id: this.form.or_id,
                },
              },
            ],
          })
          break
      }
    },
    getWs(item) {
      let arr = []

      let ws = classifyData(item, 'ws_key', 'ws_name')

      ws.forEach((item1) => {
        let arr1 = []
        item1.children.forEach((item2) => {
          arr1.push(item2.p_name)
        })
        arr.push(`${item1.ws_name}(${arr1.join('、')})`)
      })

      return arr.join('、') || '暂无'
    },
    toPage(type, item) {
      switch (type) {
        // 批次详情
        case 'batch':
          this.$toPage(this, 'larvaProduct', {
            l_key: item.l_key,
            mc_key: item.mc_key,
          })
          break

        // 订单详情
        case 'order':
          this.$toPage(this, 'salesDetail', item)
          break
        // 客户详情
        case 'customer':
          this.$toPage(this, 'clientDetail', item)
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.main-content {
  position: relative;

  .row-between {
    align-items: center;
  }

  .title {
    font-size: 1.2rem;
    color: @titleColor;
    font-weight: 600;
  }

  section {
    padding: 0.5rem;

    .section-title {
      color: @titleColor;
      font-size: 1.2rem;
      font-weight: 700;
    }

    .section-second-title {
      color: @titleColor;
      font-size: 1.1rem;
      font-weight: 600;
      opacity: 0.8;
    }

    .label {
      color: @textColor;
      font-weight: bold;
      opacity: 0.7;
      line-height: 1.2rem;
      font-size: 1rem;
    }

    &.info {
      border-bottom: 0.5rem solid @bgColor;

      .title {
        font-size: 1.2rem;
        font-weight: 700;
      }

      .title.main-c {
        color: @mainColor;
      }
    }

    &.pool {
      border-bottom: 0.5rem solid @bgColor;

      .pool-item {
        display: flex;
        align-items: center;
        margin: 0.2rem 0;
        line-height: 1.4rem;

        .value {
          color: @textColor;
          font-size: 1rem;
          margin-right: 0.5rem;
          line-height: 1.4rem;
        }
      }
    }

    &.rele {
      border-bottom: 0.5rem solid @bgColor;
    }
  }

  .data-item-center {
    display: flex;
    padding: 0.5rem;

    .yiyu-data-field {
      flex-basis: 100%;
    }

    .handle-btn {
      flex-basis: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: @bgColor;
    }
  }
}
</style>

<template>
  <div class="yiyu-opt">
    <div class="fixed yiyu-fixed">
      <div
        class="left"
        :style="{
          width:
            (leftObj.length > 2 ? 60 / leftObj.length : 40 / leftObj.length) +
            '%',
        }"
        v-for="(item, key) in leftObj"
        :key="key"
        @click="ck(item.callback)"
      >
        <div>
          <template v-if="item.src">
            <img :src="item.src" alt="" width="25" height="25" />
          </template>
          <template v-else-if="item.iconName">
            <van-icon :name="item.iconName" :color="item.color" size="25" />
          </template>
          <template v-else-if="item.slot">
            <slot :name="item.slot" />
          </template>
        </div>
        <div>{{ item.text }}</div>
      </div>
      <div
        class="right"
        :style="{
          backgroundColor: rightObj.bgcolor,
          borderRadius: leftObj.length ? '30px 0 0 30px' : '30px',
        }"
        @click="ck(rightObj.callback)"
        v-if="rightShow"
        v-permit="role"
      >
        <div>{{ rightObj.text }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { MAIN_COLOR } from '@/utils/globar'
export default {
  name: 'bottomFixedOpt',
  props: {
    leftObj: {
      type: Array,
      default: () => {
        return [
          {
            iconName: 'delete',
            color: 'red',
            text: '删除',
            callback: 'delOpt',
          },
          {
            iconName: 'edit',
            color: '#666',
            text: '编辑',
            callback: 'editOpt',
          },
          {
            iconName: 'records',
            color: '#666',
            text: '备注',
            callback: 'remarkOpt',
          },
        ]
      },
    },
    rightObj: {
      type: Object,
      default: () => {
        return {
          bgcolor: MAIN_COLOR,
          text: '提交',
          callback: 'upOpt',
        }
      },
    },
    rightShow: {
      type: Boolean,
      default: true,
    },
    leftShow: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    role() {
      return this.rightObj.role
    },
  },

  methods: {
    ck(e) {
      this.$emit(e)
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-opt {
  height: 4.375rem;
  .fixed {
    position: fixed;
    display: flex;
    justify-content: space-around;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    // height: 60px;
    height: 3.75rem;
    align-items: center;
    background: #fff;
  }
  .left {
    width: 20%;
    color: @textColor;
    font-size: 10px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .right {
    line-height: 3.75rem;
    height: 3.75rem;
    flex: 1;
    color: #fff;
    font-size: 1.1rem;
  }
}
</style>

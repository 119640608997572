<template>
  <div class="home">
    <div class="home-header">
      <div class="header"></div>
      <van-tabs
        v-model="form.main_type"
        @change="tabChange"
        class="top-tabs"
        swipe-threshold="3"
      >
        <van-tab
          v-for="(value, key, index) in FILTER_HANDLE(mainTypeObj)"
          :key="index"
          :title="value.name"
          :name="key"
        ></van-tab>
      </van-tabs>
    </div>

    <div class="main" v-if="!['day', 'rele', 'data'].includes(form.main_type)">
      <!-- 筛选区域 -->
      <van-sticky @scroll="stickyScroll" offset-top="0rem" z-index="1000">
        <div class="select-title lhj-watch" @click="handleSelect">
          <div v-if="!selectShow.length" class="closed-title-wrap">
            <span class="select-item-title">出苗日期：</span>
            <span class="select-item-value" v-if="form.date_type != 'all'">
              {{ form.date_range['start'] }}
              至
              {{ form.date_range['end'] }}</span
            >
            <template v-if="form.dimension_type">
              <span class="select-item-title" style="margin-left: 0.5rem"
                >聚合：</span
              >
              <span class="select-item-value">{{
                dimensionTypeObj[form.dimension_type]
              }}</span>
            </template>
          </div>
          <div v-else>
            <span class="select-item-title">筛选</span>
          </div>
          <van-icon
            name="arrow-down lhj-watch-icon"
            size="1.1rem"
            v-if="!selectShow.length"
          />
          <van-icon name="arrow-up lhj-watch-icon" size="1.1rem" v-else />
        </div>
        <van-collapse v-model="selectShow" @change="collapseChange">
          <van-collapse-item
            ref="collapse"
            name="1"
            right-icon=""
            :border="false"
            :is-link="false"
          >
            <template #title> </template>
            <div class="main-select" v-show="selectShow">
              <div class="tag-area">
                <div
                  class="tag-area-item date-range"
                  v-if="form.date_type != 'all'"
                >
                  <div class="left main-c">出苗日期</div>
                  <calendar-select-range
                    @change="defaultDate = false"
                    :start.sync="form.date_range['start']"
                    :end.sync="form.date_range['end']"
                  ></calendar-select-range>
                </div>
              </div>
              <div class="tag-area">
                <div class="tag-area-item">
                  <div class="left main-c">聚合</div>
                  <div class="right">
                    <scroll-tag
                      class="right-scroll-tag"
                      v-if="form.main_type != 'plan'"
                      :opts="dimensionTypeObj"
                      :chosed.sync="form.dimension_type"
                      @click="tagClick($event, 'dimension')"
                      :single="true"
                    ></scroll-tag>
                  </div>
                </div>
                <div class="tag-area-item date-tag">
                  <div class="right">
                    <scroll-tag
                      :opts="dateType"
                      :chosed.sync="form.date_type"
                      @click="tagClick($event, 'date')"
                      :single="true"
                      swipe-threshold="3"
                    ></scroll-tag>
                  </div>
                </div>
              </div>
              <!-- <div style="height: 140px"></div>-->
              <div class="search-area">
                <van-search
                  v-model="form.search_input"
                  placeholder="请输入搜索关键词"
                  @focus="focus"
                  @blur="blur"
                />
                <!-- @input="getData" -->
                <van-button
                  class="search-btn lhj-watch-search"
                  type="primary"
                  plain
                  @click="toSearch"
                  size="small"
                  >确认查询</van-button
                >
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div class="table-header">
          <div class="table-header-title">{{ title }}</div>
          <div class="table-header-handles">
            <div class="change-table" @click="add">
              <van-icon name="plus" class="change-table-icon" />
              <span class="change-table-txt">新增</span>
            </div>
            <div
              class="change-table"
              v-if="tableData && tableData.length"
              @click="exportTable"
            >
              <download-excel
                :data="exportData"
                :fields="exportHeader"
                :name="exportFileName"
              >
                <van-icon name="upgrade" class="change-table-icon" />
                <span class="change-table-txt"> 导出</span>
              </download-excel>
            </div>
            <div
              class="change-table bold"
              @click="changeTable"
              v-if="tableData && tableData.length"
            >
              <img src="@/assets/icons/exchange.png" alt="" class="img" />
              <span class="change-table-txt">{{
                tableType == 'row' ? '竖排' : '横排'
              }}</span>
            </div>
          </div>
        </div>
        <sticky-table
          :sticky="true"
          ref="table"
          class="sticky-table"
          :tableData="tableData"
          :header="header"
          :type="tableType"
          v-myLoad="isLoading"
          style="position: relative"
          v-if="form.main_type == 'plan'"
        ></sticky-table>
        <sticky-table
          :sticky="true"
          ref="table"
          class="sticky-table"
          :tableData="tableData"
          :header="header"
          :type="tableType"
          v-myLoad="isLoading"
          style="position: relative"
          v-if="form.main_type == 'order'"
        ></sticky-table>
        <sticky-table
          :sticky="true"
          ref="table"
          class="sticky-table"
          :tableData="tableData"
          :header="header"
          :type="tableType"
          v-myLoad="isLoading"
          style="position: relative"
          v-if="form.main_type == 'batch'"
        ></sticky-table>
        <tabbar v-show="tabbarShow"></tabbar>
      </van-sticky>
    </div>
    <div
      class="main"
      v-show="!['plan', 'batch', 'order'].includes(form.main_type)"
    >
      <!-- 因为日程在第一个，肯定回渲染，就show吧 -->
      <schedule
        :pScroll="pScroll"
        ref="schedule"
        v-show="form.main_type == 'day'"
      ></schedule>
      <rele
        :pScroll="pScroll"
        ref="rele"
        v-if="form.main_type == 'rele'"
      ></rele>
      <data-chart
        :pScroll="pScroll"
        ref="rele"
        v-if="form.main_type == 'data'"
      ></data-chart>
      <tabbar></tabbar>
    </div>

    <yiyu-tip :show.sync="tipShow" v-if="tipShow" :tipData="tipData">
    </yiyu-tip>

    <yiyu-picker
      v-if="addShow"
      :show.sync="addShow"
      title="请选择年"
      :columns="addColumn"
      @confirm="confirmDate($event, 'add')"
      @cancel="addShow = false"
    ></yiyu-picker>
  </div>
</template>

<script>
import dataChart from '././components/dataChart';
import rele from './components/rele.vue';
import schedule from './components/schedule';
import JsonExcel from 'vue-json-excel';
import tabbar from '@/components/tabbar.vue';
import { getFuture, formatTime } from '@/utils/date';
import yiyuPicker from '@/components/popup/picker';
import yiyuTip from '@/components/popup/tip';
import stickyTable from '@/components/table/stickyTable.vue';
import scrollTag from '@/components/scroll/tag';
import { arrToObj, isPc } from '@/utils/utils';
import { isWeiXin } from '@/utils/weixin';
import calendarSelectRange from '@/components/calendar/calendarSelectRange.vue';

export default {
  name: 'FF_home',
  components: {
    scrollTag,
    tabbar,
    yiyuPicker,
    yiyuTip,
    stickyTable,
    calendarSelectRange,
    schedule,
    rele,
    dataChart,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      tabbarShow: true,
      scroll: 0,
      addShow: false,
      tableType: 'row',
      autoClose: false,
      empty: '',
      isLoading: false,
      isActive: false,
      selectShow: [], // 默认收起
      // selectShow: ["1"], // 默认展开
      dateType: {
        // 默认都不选
        day: '日',
        week: '周',
        month: '月',
        year: '年',
      },
      form: {
        main_type: 'day', //常用
        dimension_type: undefined, //维度，基地 = farm, 客户 = customer, 区域 = area, 育苗批次 = batch, 育苗来源 = supplier, 成本=cost, 育苗类型=larva_type
        date_range: { start: undefined, end: undefined }, //时间范围{'start': '2021-01-01', 'end': '2021-0'}
        date_type: undefined, //时间类型，日 = day, 月 = month 年 = year  总 = all , 默认 日
        search_input: undefined,
      },
      tipShow: false,
      tipData: {},
      farmOpt: [],
      tableData: [],
      header: [],
      defaultDate: true, // 在客户没有编辑过日期前都使用默认日期
      pScroll: 0,
    };
  },
  computed: {
    mainTypeObj() {
      return {
        day: { name: '日程', handle: 'HOME_SCHEDULE' },
        data: { name: '总览', handle: 'HOME_DATA' },
        plan: { name: '计划', handle: 'HOME_PLAN' },
        batch: { name: '育苗', handle: 'HOME_BATCH' },
        order: { name: '订单', handle: 'HOME_ORDER' },
        rele: { name: '关联', handle: 'HOME_RELE' },
      };
    },
    dimensionTypeObj() {
      switch (this.form.main_type) {
        case 'batch':
          return {
            supplier: '育苗来源',
            larva_type: '育苗类型',
            farm: '基地',
            ws: '车间',
            l_manager: '育苗负责人',
          };
        case 'order':
          return {
            customer: '客户',
            area: '区域',
          };
        case 'farm':
          return {
            technician: '技术员',
          };
        case 'cost':
          return {
            farm: '基地',
          };
        case 'plan':
        default:
          return {};
      }
    },
    title() {
      if (this.form.dimension_type) {
        return this.dimensionTypeObj[this.form.dimension_type];
      } else {
        return this.mainTypeObj[this.form.main_type]['name'] + '总览';
      }
    },
    exportHeader() {
      return JSON.parse(JSON.stringify(arrToObj(this.header, 'show', 'field')));
    },
    exportData() {
      let data = [];
      this.tableData.forEach((item) => {
        let obj = {};
        for (let key in item) {
          obj[key] = item[key]['value'];
        }
        data.push(obj);
      });
      return JSON.parse(JSON.stringify(data));
    },
    exportFileName() {
      return this.title + '数据';
    },
    addColumn() {
      switch (this.form.main_type) {
        case 'plan':
          return [
            {
              text: '新增计划',
              key: 'plan',
            },
            {
              text: '新增育苗',
              key: 'larva',
            },
            {
              text: '新增订单',
              key: 'order',
            },
          ];
        default:
          return [];
      }
    },
  },
  activated() {
    if (this.$route.query) {
      let query = this.$route.query;
      if (query.type) {
        this.form.main_type = query.type || this.form.main_type;
      }
    }

    if (this.$route.params) {
      let params = this.$route.params;
      if (params.kw) {
        this.form.search_input = params.kw;
      }
    }

    this.$nextTick(() => {
      if (this.form.main_type == 'day') {
        this.$refs['schedule'].getData();
      } else if (this.form.main_type == 'rele') {
        this.$refs['rele'].getData();
      } else if (this.form.main_type == 'data') {
        // 不处理
      } else {
        this.getData();
      }
    });
  },
  beforeRouteLeave(to, form, next) {
    this.scroll =
      document.querySelector('.el-table__body-wrapper') &&
      document.querySelector('.el-table__body-wrapper').scrollTop;
    if (document.querySelector('.schedule-content')) {
      this.pScroll = document.querySelector('.schedule-content').scrollTop;
    }
    next();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.defaultDate) {
        if (this.form.main_type == 'plan' || this.form.main_type == 'order') {
          this.form.date_range['start'] = formatTime(
            new Date(getFuture(Date(), 30, 'past')),
            '-'
          ).slice(0, 10);
          this.form.date_range['end'] = formatTime(
            new Date(getFuture(Date(), 30)),
            '-'
          ).slice(0, 10);
        } else {
          this.form.date_range['start'] = formatTime(
            new Date(getFuture(Date(), 51, 'past')),
            '-'
          ).slice(0, 10);
          this.form.date_range['end'] = formatTime(
            new Date(getFuture(Date(), 7)),
            '-'
          ).slice(0, 10);
        }
      }
    },
    focus() {
      // 有用，但是有可能触发筛选收起
      // 得找个个苹果端一样效果的解决方案
      // this.$$nextTick(() => {
      //   // document.activeElement.scrollIntoView({ block: 'center' });//焦点元素滚到可视区域的问题
      //   setTimeout(() => {
      //     document.activeElement.scrollIntoView(false);
      //   }, 300);
      // })
      if (!isPc()) {
        this.tabbarShow = false;
      }
    },
    blur() {
      this.tabbarShow = true;
    },
    tabChange() {
      this.init();
      this.selectShow = [];
      this.form.dimension_type = undefined;
      this.form.search_input = undefined;
      this.getData();
    },
    getData() {
      if (['day', 'rele', 'data'].includes(this.form.main_type)) return;
      if (
        !this.IS_HANDLE('HOME_PLAN') &&
        !this.IS_HANDLE('HOME_BATCH') &&
        !this.IS_HANDLE('HOME_ORDER')
      )
        return;

      if (this.form.date_range.start && this.form.date_range.end) {
        if (
          new Date(this.form.date_range.start.replace(/-/g, '/')).getTime() >
          new Date(this.form.date_range.end.replace(/-/g, '/')).getTime()
        ) {
          [this.form.date_range.start, this.form.date_range.end] = [
            this.form.date_range.end,
            this.form.date_range.start,
          ];
        }
      }
      this.isLoading = true;
      this.$get('/homeStat/getData', this.form).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          let data = res.data;
          this.tableData = data.list;
          this.header = data.title;
          if (this.tableData.length == 0) {
            this.tableType = 'row';
          }

          this.$nextTick(() => {
            setTimeout(() => {
              if (document.querySelector('.el-table__body-wrapper')) {
                document.querySelector('.el-table__body-wrapper').scrollTop =
                  this.scroll;
              }
            });
          }, 0);
        }
      });
    },
    add() {
      switch (this.form.main_type) {
        case 'plan':
          this.addShow = true;
          break;
        case 'batch':
          this.toPage('larva');
          break;
        case 'order':
          this.toPage('order');
          break;
      }
    },
    toPage(type) {
      switch (type) {
        case 'plan':
          this.$toPage(this, 'plan', {
            year:
              this.form.date_range['start'] &&
              this.form.date_range['start'].slice(0, 4),
          });
          break;
        case 'larva':
          this.$toPage(this, 'larvaLaunch', { status: 'add' });
          break;
        case 'order':
          this.$toPage(this, 'salesAdd', { status: 'add' });
          break;
      }
    },
    exportTable() {
      if (isWeiXin()) {
        alert(
          '微信浏览器无法使用下载功能，如需导出，请点击右上角使用其他浏览器打开后进行导出下载。'
        );
      }
      return;
    },
    changeTable() {
      this.tableType = this.tableType == 'row' ? 'column' : 'row';
    },
    toSearch() {
      this.tabbarShow = true;
      this.$nextTick(() => {
        this.$refs['collapse'] && this.$refs['collapse'].toggle(false);
        this.getData();
      });
    },
    tagClick() {},
    collapseChange() {},
    confirmDate(e, type) {
      switch (type) {
        case 'add':
          this.toPage(e[0].key);
          this.addShow = false;
          break;
      }
    },
    stickyScroll(obj) {
      // 置顶要允许可以手动展开筛选
      let sticky = document.querySelector('.van-sticky');

      if (obj.isFixed && sticky && sticky.getBoundingClientRect().top == 0) {
        this.$refs['collapse'] && this.$refs['collapse'].toggle(false);
      }
    },
    handleSelect() {
      if (this.selectShow && this.selectShow.length) {
        this.$refs['collapse'] && this.$refs['collapse'].toggle(false);
      } else {
        this.$refs['collapse'] && this.$refs['collapse'].toggle(true);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  height: 100%; // 100vh;
  min-height: 100%; // 100vh;
  background: #fff;
  .home-header {
    background: linear-gradient(25deg, #fd7739 0%, #faab35 100%);
    text-align: center;
    .header {
      height: 1rem;
      line-height: 2rem;
      font-size: 1.5rem;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .top-tabs {
      .van-tabs--line .van-tabs__wrap {
        height: 2.75rem;
      }
      /deep/.van-tabs__nav.van-tabs__nav--line {
        background: transparent;
        .van-tab.van-tab--active {
          .van-tab__text {
            font-size: 1.5rem;
            font-family: PingFang SC;
            font-weight: 700;
            line-height: 2.75rem;
            color: #ffffff;
            opacity: 1 !important;
          }
        }
        .van-tabs__line {
          height: 0.25rem;
          background: #fff;
        }
      }
      /deep/.van-tab {
        font-size: 1rem;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 1.375rem;
        color: #ffffff;
      }
    }
  }
  /deep/ .van-popup {
    /deep/ .yiyu-box-section {
      .title {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select-item-title {
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 2.5rem;
    color: @titleColor;
  }
  .select-item-value {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 2.5rem;
    box-sizing: border-box;
    color: @textColor;
  }
  .lhj-watch-icon {
    font-weight: 900;
    line-height: 2.5rem;
    margin-right: 0.9rem;
    color: @titleColor;
  }
  .main-select {
    .search-area {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-search {
        flex-basis: 100%;
      }

      .search-btn {
        white-space: nowrap;
        border-radius: 0.5rem;
        padding: 0.5rem;
      }
    }
    .tag-area {
      &-item {
        margin: 0.5rem 0;
        .left {
          white-space: nowrap;
          font-size: 1rem;
          font-family: PingFang SC;
          font-weight: 600;
          line-height: 1.375rem;
          color: @titleColor;
          margin-bottom: 1rem;
        }
        .right {
          flex-basis: 100%;
          display: flex;
          width: 100%;
          /deep/ .right-scroll-tag {
            width: 100%;
            .van-tab {
              flex: none;
            }
            .van-tabs__nav {
              padding: 0 0 1rem;
            }
            .van-tabs__nav .van-tab {
              padding: 0;
              margin-right: 1.5rem;
            }
          }
        }
      }
    }
    .date-range {
      .right {
        line-height: 100%;
      }
    }
    .van-icon.van-icon-arrow-down {
      padding: 0 0.5rem;
    }
  }
  .align-center {
    display: flex;
    align-items: center;
  }
  .table-header {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      font-size: 1.125rem;
      font-family: PingFang SC;
      font-weight: 600;
      line-height: 1.5625rem;
      color: @titleColor;
    }

    &-handles {
      display: flex;
    }
  }
  .sticky-table {
    margin: 0 1rem;
  }
  .select-title {
    height: 2.5rem;
    box-sizing: border-box;
    display: flex;
    padding: 0.2rem 0.9rem 0;
    background: #fff;
    justify-content: space-between;
  }
  /deep/.van-cell.van-cell--borderless.van-collapse-item__title.van-collapse-item__title--expanded.van-collapse-item__title--borderless {
    display: none;
  }
  /deep/.van-cell.van-cell--borderless.van-collapse-item__title.van-collapse-item__title--borderless {
    display: none;
  }
  /deep/.van-collapse-item__content {
    padding-top: 0;
  }
  /deep/.van-collapse-item__wrapper {
    background: #fff;
  }
  .change-table {
    margin: 0.5rem 0.875rem;
    display: flex;
    align-items: center;

    &.bold {
      .change-table-icon {
        opacity: 1 !important;
      }
      .change-table-txt {
        opacity: 1;
        font-size: 1rem;
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    &-icon {
      color: @textColor;
      opacity: 0.6;
      font-size: 1rem;
    }

    &-txt {
      font-size: 0.875rem;
      font-family: PingFang SC;
      font-weight: 600;
      line-height: 1.25rem;
      color: @textColor;
      opacity: 0.6;
    }
    .img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .closed-title-wrap {
    white-space: nowrap;
    overflow-x: auto;
  }
  .date-tag {
    /deep/ .scroll-tag {
      .van-tabs__nav--complete {
        padding: 0 0 1rem;
      }
      .van-tabs__nav--complete .van-tab {
        padding: 0;
        margin-right: 1.5rem;
      }
    }
  }
}
</style>

<template>
  <div class="member-info">
    <yiyu-navbar tab-title="成员信息"></yiyu-navbar>
    <div class="content">
      <info :info="form" sub-key="comp_name">
        <template #right>
          <div class="info-right status row">
            <div class="status-item-invite" v-if="!form.account">邀请中</div>
            <div class="status-item-leave" v-if="form.status == 2">已离职</div>
          </div>
        </template>
      </info>
      <yiyu-detail :formOpts="formOpts" :form="form" style="position: relative">
        <template #bind>
          <span class="main-c" @click="bind" v-if="!form.openid"
            >绑定公众号</span
          >
          <span class="main-c" @click="cancelBind" v-else>解除绑定</span>
        </template>
      </yiyu-detail>
    </div>

    <bottomFixedOpt
      :leftObj="leftObj"
      @editOpt="editOpt"
      @delOpt="delOpt"
      @inviteOpt="inviteOpt"
      :rightShow="false"
    />

    <van-popup v-model="linkShow" position="bottom" class="link-popup" round>
      <div class="title">邀请链接</div>
      <input v-model="link" ref="copy_link" class="hide" />
      <div class="link">{{ link }}</div>
      <van-button class="btn" type="primary" @click="copyLink"
        >复制链接</van-button
      >
    </van-popup>
    <!-- closeOnClickOverlay -->
    <van-dialog
      v-model="bindShow"
      title="绑定公众号"
      show-cancel-button
      confirmButtonText="保存图片"
      @confirm="saveImg"
      @cancel="getData"
      :show-confirm-button="!isWeiXin()"
    >
      <div class="dialog-content" v-myLoad="imgLoading">
        <span v-if="isWeiXin()">长按图片识别，前往公众号绑定</span>
        <img :src="qr_code_url" ref="img" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import bottomFixedOpt from '@/components/feedback/bottomFixedOpt';
import { Cell } from 'vant';
import { isWeiXin } from '@/utils/weixin';
import yiyuDetail from '@/components/detail';
import { Dialog } from 'vant';
import yiyuNavbar from '@/components/navbar';
import { MAIN_COLOR } from '@/utils/globar';
import info from '@/bComponents/info';
export default {
  name: 'memberInfo',
  components: {
    bottomFixedOpt,
    yiyuDetail,
    yiyuNavbar,
    info,
    [Cell.name]: Cell,
  },
  data() {
    return {
      postLoading: false,
      form: {},
      leftObj: [
        {
          src: require('@/assets/icons/edit.png'),
          color: this.$mainColor,
          text: '编辑',
          callback: 'editOpt',
        },
      ],
      linkShow: false,
      link: location.origin + '/#/',
      qr_code_url: undefined,
      qr_code_base64: undefined,
      bindShow: false,
      imgLoading: false,
    };
  },
  created() {
    if (this.$route.query) {
      this.userKey = this.$route.query.userKey;
      if (this.userKey) this.getData();
    }
  },
  computed: {
    formOpts() {
      return [
        {
          title: '成员信息',
          child: [
            {
              label: '是否绑定',
              slot: 'bind',
            },
            {
              label: '成员性别',
              val: this.form.sexName,
            },
            {
              label: '出生日期',
              val: this.$dayjs(this.form.birthday).format('YYYY/MM/DD'),
            },
            {
              label: '住址',
              val: this.form.address,
            },
            {
              label: '手机',
              val: this.form.account,
            },
            {
              label: '在职状态',
              val: this.form.statusName,
            },
            {
              label: '入职时间',
              val:
                this.form.entry_date == '0000-00-00'
                  ? '暂无'
                  : this.form.entry_date,
            },
            {
              label: '负责基地',
              val: this.form.charge_mcName,
              show: this.form.charge_mcName != undefined,
            },
            {
              label: '默认基地',
              val: this.form.ch_wh_name
            },
          ],
        },
      ];
    },
  },
  methods: {
    isWeiXin() {
      return isWeiXin();
    },
    bind() {
      this.bindShow = true;
      this.imgLoading = true;
      this.$get('/wx/getQrCode', {}).then((res) => {
        if (res.code == 10000) {
          this.imgLoading = false;
          this.qr_code_url = res.data.qr_code_url;
          this.qr_code_base64 = res.data.qr_code_base64;
        }
      });
    },
    cancelBind() {
      Dialog.confirm({
        title: '解除绑定',
        message: '解除绑定微信将无法收到公众号消息推送，是否解除绑定？',
      })
        .then(() => {
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/wx/unbinding', {
            user_id: String(this.form.uid),
          }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.$toast.success('解除绑定');
              this.getData();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    getData() {
      // 查看成员
      this.$get('/member/getMember', {
        user_key: this.userKey,
      }).then((res) => {
        if (res.code == 10000) {
          let data = res.data.list[0];
          if (data) {
            data.sexName =
              data['sex'] == 1 ? '女' : data['sex'] == 2 ? '男' : '未知';
            data.statusName = data['status'] == 1 ? '正常' : '离职';

            let tmp = [];
            data['charge_mc'] &&
              data['charge_mc'].forEach((item) => {
                tmp.push(item.value);
              });
            data['charge_mcName'] = tmp.join('、');
          }
          this.form = data;
        }
        if (!this.form.account) {
          this.leftObj.push({
            color: MAIN_COLOR,
            text: '再次邀请',
            callback: 'inviteOpt',
            src: require('@/assets/icons/share.png'),
          });
        }
        if (
          this.form.account != this.$store.state.basic.account &&
          this.IS_HANDLE('MEMBER_DEL') &&
          this.leftObj?.[0]?.text != '删除'
        ) {
          this.leftObj.unshift({
            text: '删除',
            callback: 'delOpt',
            iconName: 'delete',
          });
        }
      });
    },
    editOpt() {
      this.$push(this, {
        path: '/memberEdit',
        query: { userKey: this.form.user_key },
      });
    },
    inviteOpt() {
      if (window.navigator.userAgent == 'flutterApp') {
        this.linkShow = true;
        this.link += `memberAccept?s=${this.form.user_key}`;
      } else {
        this.$push(this, {
          path: '/memberAccept',
          query: { s: this.form.user_key },
        });
      }
    },
    delOpt() {
      // 删除成员
      Dialog.confirm({
        // title: "标题",
        message: '确认删除？',
      })
        .then(() => {
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/member/delMember', {
            user_key: this.userKey,
          }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.$toast.success('删除成功');
              this.$go(-1, this, {
                name: 'memberList',
              });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    copyLink() {
      this.$refs['copy_link'].select();
      let flag = document.execCommand('Copy', 'false', null);

      if (flag) {
        this.$toast.success('复制成功！');
        this.linkShow = false;
      } else {
        this.$toast.fail('复制失败！请重试');
      }
    },
    saveImg() {
      // let Url = this.qr_code_url;
      let Url = this.qr_code_base64;
      Url = 'data:image/ipg;base64,' + Url;
      if (Url) {
        // 服务器上的图片需要转化成base64才可以
        // 只能保存本地静态图片
        var a = document.createElement('a');
        var blob = new Blob([''], { type: 'application/octet-stream' });
        var url = URL.createObjectURL(blob);
        a.href = Url;
        a.download = '绑定公众号.jpg';
        var e = document.createEvent('MouseEvents');
        e.initMouseEvent(
          'click',
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        a.dispatchEvent(e);
        URL.revokeObjectURL(url);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.member-info {
  .content {
    padding: 0px 0px 16px;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
    .info-left {
      display: flex;
      align-items: center;

      .info-txt {
        margin-left: 8px;

        .info-txt-top {
          display: flex;
          align-items: center;
          .name {
            color: @titleColor;
            font-size: 1.1rem;
          }

          .tag {
            padding: 1px 8px;
            font-size: 10px;
            margin-left: 8px;
            color: #fff;
            border-radius: 4px;
            background: linear-gradient(89deg, #00b9b9 0%, #00cba0 100%);
          }
        }

        .info-txt-bottom {
          font-size: 1rem;
        }
      }
    }
  }

  /deep/ .yiyu-box-section {
    .title {
      display: none;
    }
  }

  .link-popup {
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;

    .title {
      font-size: 1.2rem;
      color: @titleColor;
      font-weight: 500;
    }

    .link {
      margin: 32px 16px;
      padding: 16px 8px;
      background: @bgColor;
      border: 1px solid @lineColor;
      border-radius: 4px;
    }

    .btn {
      width: 100%;
      border-radius: 4px;
    }

    .hide {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
  }

  .dialog-content {
    position: relative;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      height: 8rem;
      width: 8rem;
    }
  }
}
</style>

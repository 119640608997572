<template>
  <list-container
    @more="getMoreData"
    @refresh="refreshData"
    :pullDisable="true"
  >
    <yy-navbar></yy-navbar>
    <div class="main" v-myLoad="isLoading">
      <van-tabs v-model="tabName" @change="refreshData">
        <van-tab name="0">
          <template #title>
            <div class="tab-title">
              {{ labelName
              }}<van-popover
                v-model="showPopover"
                trigger="click"
                :actions="getActions()"
                @select="onSelect"
              >
                <template #reference>
                  <van-icon name="arrow-down" />
                </template>
              </van-popover>
            </div>
          </template>
        </van-tab>
        <van-tab name="1">
          <template #title>我发起的({{ getLabelNum(0, 1) }})</template>
        </van-tab>
      </van-tabs>
      <div class="search">
        <van-search
          v-model="kw"
          :placeholder="placeholder"
          @search="refreshData"
        ></van-search>
      </div>
      <div class="list">
        <div
          class="list-item-wrap"
          v-for="(item, index) in list"
          :key="index"
          @click="toPage('detail', item)"
          :class="{
            active: getStatus(item) == '未读消息',
            green: getStatus(item) == '未读留言',
          }"
        >
          <div class="content">
            <div class="row-between">
              <div class="title">
                {{ item['title'] }}
                <notice-tag v-model="item.priority"></notice-tag>
              </div>
              <div class="status">
                {{ getStatus(item) != '已读' ? getStatus(item) : '' }}
              </div>
            </div>
            <div class="list-item-content">
              <div
                class="list-item"
                v-for="(item1, index1) in getDataObj(item)"
                :key="index1"
              >
                <div class="label">{{ item1.label }}</div>
                <div class="value">{{ item1.value }}</div>
              </div>
            </div>
          </div>
        </div>
        <yiyu-empty v-if="!list.length"></yiyu-empty>
      </div>
    </div>

    <drag-btn @add="toPage('publish')"></drag-btn>
  </list-container>
</template>

<script>
import noticeMixin from '@/views/notice/noticeMixin'
import noticeTag from '@/bComponents/noticeTag.vue'
import yiyuEmpty from '@/components/layout/empty.vue'
import listContainer from '@/components/layout/listContainer'
// import fixedBtn from "@/components/button/fixedBtn";
import dragBtn from '@/components/button/dragBtn'
import { classfiyRole } from '@/utils/business'
// import iconField from '@/components/layout/iconField.vue'
import { classifyData } from '@/utils/utils'
export default {
  name: 'notice',
  mixins: [noticeMixin],
  components: {
    listContainer,
    yiyuEmpty,
    // fixedBtn,
    noticeTag,
    dragBtn,
    // iconField,
  },
  data() {
    return {
      isLoading: false,
      kw: undefined,
      tabName: '1',
      list: [],
      page: 1,
      count: 10,
      total: 0,
      labelObj: {
        active: 0,
        0: '消息',
        1: '未读消息',
        2: '未读留言',
        3: '已读',
      },
      totalNum: {
        notice_total: 0, // 消息总数
        msg_no_read: 0, // 留言未读数
        notice_no_read: 0, // 消息未读数
        all_read: 0, // 已读数
        send: 0, // 我发起的通知数
      },
      scroll: 0,
      showPopover: false,
      is_read: undefined,
      msg_is_read: undefined,
    }
  },
  activated() {
    if (this.$route.params) {
      let params = this.$route.params
      this.tabName = params.tab || '0'
    }
    this.getTotalNum()
    this.getData(1, this.page * this.count)
    this.$nextTick(() => {
      if (document.querySelector('.list-container')) {
        document.querySelector('.list-container').scrollTop = this.scroll
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    this.scroll = document.querySelector('.list-container').scrollTop
    next()
  },
  computed: {
    labelName() {
      return (
        this.labelObj[this.labelObj.active] +
        '(' +
        this.getLabelNum(this.labelObj.active) +
        ')'
      )
    },
    placeholder() {
      if (this.tabName == 0) {
        return '请输入标题、发送人、批次/订单搜索'
      } else {
        return '请输入标题、批次/订单搜索'
      }
    },
  },
  methods: {
    getTotalNum() {
      this.$get('/notice/getNoticeListStat').then((res) => {
        if (res.code == 10000) {
          this.totalNum = res.data
        }
      })
    },
    getLabelNum(index, tabName = 0) {
      if (tabName == 0) {
        switch (index) {
          case 0:
            return this.totalNum.notice_total
          case 1:
            return this.totalNum.notice_no_read
          case 2:
            return this.totalNum.msg_no_read
          case 3:
            return this.totalNum.all_read
        }
      } else {
        return this.totalNum.send
      }
    },
    getData(page, count) {
      let page1 = page || this.page
      let count1 = count || this.count
      let data = {
        author: this.tabName,
        page: page1,
        count: count1,
        keyword: this.kw,
      }

      if (this.tabName == 0) {
        data['is_read'] = this.is_read
        data['msg_is_read'] = this.msg_is_read
      }
      this.isLoading = true
      this.$get('/notice/getNoticeList', data).then((res) => {
        if (res.code == 10000) {
          let list = res.data.list
          if (count) {
            this.list = list
          } else {
            this.list.push(...list)
          }
          this.isLoading = false
          this.total = res.data.total
        }
      })
    },
    getDataObj(item) {
      let rele = []
      let re_list = classifyData(item.re_list, 'type', 'type')
      re_list.forEach((item) => {
        let value = []
        item.children.forEach((item1) => {
          value.push(item1.text || '暂无标题')
        })
        rele.push({
          label: this.noticeTypeObj[item.type].title,
          value: value.join('、'),
        })
      })
      let base = [
        {
          label: '接收情况',
          value: `${item.rece_nr_count || 0}/${item.rece_count || 0}已读  ${
            item.msg_count || 0
          }留言`,
        },
        {
          label: '发布时间',
          value: item.time && item.time.slice(0, 16),
        },
      ]
      if (this.tabName == 0) {
        return [
          ...rele,
          {
            label: '发布人',
            value: item.nickname,
          },
          ...base,
        ]
      } else {
        return [
          ...rele,
          {
            label: `接收人(${item.receive_user.length || 0})`,
            value: this.getReceiver(item),
          },
          ...base,
        ]
      }
    },
    getMoreData() {
      if (this.list.length < this.total) {
        this.page++
        this.getData()
      }
    },
    refreshData() {
      this.page = 1
      this.count = 10
      this.list = []
      this.getData()
    },
    toPage(type, item) {
      switch (type) {
        case 'publish':
          this.$toPage(this, 'publish', { status: 'add' })
          break
        case 'detail':
          this.$toPage(this, 'noticeDetail', item)
          break
      }
    },
    getStatus(item) {
      if (item.is_read == 0) {
        return '未读消息'
      } else if (item.msg_is_read == 0) {
        return '未读留言'
      } else {
        return '已读'
      }
    },
    onSelect(action) {
      this.labelObj.active = action.index
      switch (String(action.index)) {
        case '0':
          this.is_read = undefined
          this.msg_is_read = undefined
          break
        case '1':
          this.is_read = 0
          this.msg_is_read = undefined
          break
        case '2':
          this.is_read = undefined
          this.msg_is_read = 0
          break
        case '3':
          this.is_read = 1
          this.msg_is_read = 1
          break
      }
      this.refreshData()
      // 原来的
      // this.list = []
      // this.getData()
    },
    getActions() {
      let arr = ['消息', '未读消息', '未读留言', '已读']
      let actions = []
      arr.forEach((item, index) => {
        if (index != this.labelObj.active) {
          actions.push({
            text: item + '(' + this.getLabelNum(index) + ')',
            index,
          })
        }
      })
      return actions
    },
    getReceiver(item) {
      let obj = classfiyRole(item.receive_user)
      let arr = []
      for (let i in obj) {
        arr.push(
          this.$store.state.basic.roleObj[i] +
            '(' +
            obj[i].child.length +
            '人)',
        )
      }
      return arr.join('、')
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  padding: 12px;
  position: relative;
}

.list-item-wrap {
  border: 1px solid @lineColor;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem;
  background: @bgColor;

  &.active {
    background: rgba(@mainRgb, 0.1);
  }

  &.green {
    background: rgba(244, 231, 11, 0.1);
  }

  .row-between {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .content {
    flex-basis: 100%;
    width: 100%;

    .title {
      color: @titleColor;
      font-size: 1.1rem;
      font-weight: 600;
      display: flex;
      align-items: center;

      .notice-tag {
        margin-left: 0.5rem;
      }
    }

    .list-item-content {
      font-size: 1rem;
      color: @textColor;
      padding: 0.5rem;

      .list-item {
        display: flex;

        .label {
          .ShallowText;
          font-size: 1rem;
          padding-right: 0.5rem;
          opacity: 0.7;
          white-space: nowrap;
          &:after {
            content: ':';
          }
        }

        .value {
          font-size: 1rem;
          color: @textColor;
          .Ellipsis;
        }
      }
    }
  }
}

/deep/.yiyu-tabs {
  justify-content: space-around;
}

.tab-title {
  display: flex;
  align-items: center;
}

.van-popover__wrapper {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.info-item {
  margin-right: 0.5rem;
}
</style>

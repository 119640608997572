<template>
  <div class="member-invite">
    <yiyu-navbar tab-title="邀请成员"></yiyu-navbar>
    <div class="content">
      <img class="banner" src="@/assets/images/invite_worker.png" alt="" />
      <section class="list">
        <div class="title">成员信息</div>
        <yiyu-form
          :formOpts="formOpts"
          :form="form"
          :_this="thisAasia"
          @submit="onSubmit"
          ref="form"
        >
          <template #footer>
            <div class="footer">
              <div class="btn" @click="upOpt" native-type="button">
                生成邀请链接
              </div>
            </div>
          </template>
        </yiyu-form>
      </section>
    </div>
    <van-popup v-model="linkShow" position="bottom" class="link-popup" round>
      <div class="title">邀请链接</div>
      <input v-model="link" ref="copy_link" class="hide" />
      <div class="link ellipsis">{{ link }}</div>
      <van-button class="btn" type="primary" @click="copyLink"
        >复制链接</van-button
      >
    </van-popup>
    <keep-alive>
      <farm-picker
        ref="farmPicker"
        :curKey.sync="form.charge_mc"
        :curName.sync="form.charge_mcName"
        :type="ws_type"
      ></farm-picker>
    </keep-alive>
    <van-popup class="popup" v-model="isShowPopup" round position="bottom">
      <div class="header">
        <div class="row-between">
          <div class="header-left" @click="isShowPopup = false">取消</div>
        </div>
      </div>
      <select-tag
        v-if="wh_list && wh_list.length != 0"
        :opts="wh_list"
        v-model="form.ch_wh_key"
        :single="true"
        @change="whChange"
      ></select-tag>
      <div v-else>
        <yiyu-empty></yiyu-empty>
      </div>
      <div class="main-c txt-c" @click="toPage('warehousesAdd')">
        前往添加仓库
      </div>
    </van-popup>
    <b-role-picker
      :show.sync="showRolePicker"
      :roles.sync="form.role"
      :objs.sync="form.roleObj"
      :names.sync="form.roleName"
    ></b-role-picker>
  </div>
</template>

<script>
import bRolePicker from '@/bComponents/bRolePicker';
import farmPicker from '@/bComponents/farmPicker.vue';
import yiyuForm from '@/components/form';
import yiyuNavbar from '@/components/navbar';
import selectTag from '@/components/tag/selectTag.vue';

export default {
  name: 'invite',
  data() {
    return {
      showRolePicker: false,
      share_key: undefined,
      mcOpts: [], // 基地选项
      isShowPopup: false,
      form: {
        nickname: undefined,
        role: undefined,
        role_type: undefined, // 技术负责人 还分 测试 育苗 出苗
        charge_mc: [], // 负责基地[]
        charge_mcName: undefined,
        roleName: undefined,
        roleObj: [],
        ch_wh_key: [],
        ch_wh_name: '',
      },
      wh_list: [],
      thisAasia: this,
      linkShow: false,
      link: location.origin + '/#/',
    };
  },
  components: {
    yiyuForm,
    yiyuNavbar,
    farmPicker,
    bRolePicker,
    selectTag,
  },
  mounted() {
    this.$get('/entrepot_warehouse/getAllList').then((res) => {
      this.wh_list =
        res.data?.map((item) => {
          return {
            val: item.name,
            key: item.key,
          };
        }) || [];
    });
  },
  computed: {
    formOpts() {
      let _this = this;
      let opts = [
        {
          title: '',
          child: [
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              value: this.form.nickname,
              name: 'nickname',
              label: '成员姓名',
              placeholder: '请输入成员姓名',
              rules: [{ required: true, message: '请输入成员姓名' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.roleName,
              name: 'roleName',
              label: '成员角色',
              placeholder: '请选择成员角色',
              click() {
                _this.showRolePicker = true;
              },
              rules: [{ required: true, message: '请选择成员角色' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.charge_mcName,
              name: 'charge_mcName',
              label: '负责基地',
              placeholder: '请选择负责基地',
              click() {
                if (_this.$refs['farmPicker']) {
                  _this.$refs['farmPicker'].show = true;
                }
              },
              rules: [{ required: true, message: '请选择负责基地' }],
              show:
                this.form.role &&
                !(this.form.role.length == 1 && this.form.role[0] == 2),
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.ch_wh_name,
              name: 'ch_wh_name',
              label: '默认基地',
              placeholder: '请选择默认基地',
              click() {
                _this.isShowPopup = true;
              },
              rules: [{ required: true, message: '请选择默认基地' }],
              show:
                this.form.role &&
                !(this.form.role.length == 1 && this.form.role[0] == 2),
            },
          ],
        },
      ];
      return opts;
    },
    ws_type() {
      return undefined;
    },
  },
  methods: {
    toPage(url) {
      switch (url) {
        case 'warehousesAdd':
          this.$toPage(this, 'warehousesAdd', {
            cached: true,
          });
      }
    },
    whChange(e) {
      if (e.obj && e.obj[0]) {
        this.form.ch_wh_name = e.obj[0].val;
        this.form.ch_wh_key = [e.obj[0].key];
      } else {
        this.form.ch_wh_name = '';
        this.form.ch_wh_key = [];
      }
      this.isShowPopup = false;
    },
    onSubmit() {
      // 邀请成员
      this.$get('/member/getShareKey', {
        nickname: this.form.nickname,
        role: this.form.role,
        charge_mc: this.form.charge_mc,
        ch_wh_key: this.form.ch_wh_key[0],
        ch_wh_name: this.form.ch_wh_name,
      }).then((res) => {
        if (res.code == 10000) {
          this.share_key = res.data.share_key;
          if (window.navigator.userAgent == 'flutterApp') {
            this.linkShow = true;
            this.link += `memberAccept?s=${this.share_key}`;
          } else {
            this.$replace(this, {
              path: '/memberAccept',
              query: { s: this.share_key },
            });
          }
        }
      });
    },
    copyLink() {
      this.$refs['copy_link'].select();
      let flag = document.execCommand('Copy', 'false', null);

      if (flag) {
        this.$toast.success('复制成功！');
        this.linkShow = false;
      } else {
        this.$toast.fail('复制失败！请重试');
      }
    },
    // 添加成员
    upOpt() {
      this.$refs.form.$refs.yiyuForm.submit();
    },
  },
};
</script>
<style lang="less" scoped>
.member-invite {
  background-color: @mainColor;
  padding: 20px 16px;
  font-size: 0;
  box-sizing: border-box;
  min-height: 100%; // 100vh;

  .content {
    background: #fff;
    border-radius: 8px;

    .banner {
      width: 100%;
    }

    .list {
      padding: 0 30px 24px;
      .title {
        font-size: 1.1rem;
        color: @titleColor;
        font-weight: 500;
        height: 70px;
        line-height: 70px;
        border-bottom: 1px solid @lineColor;
      }

      .footer {
        margin-top: 2.5rem;
        .btn {
          background: @mainColor;
          color: #fff;
          font-size: 1.1rem;
          height: 48px;
          border-radius: 8px;
          line-height: 48px;
          text-align: center;
        }
      }
    }
  }

  /deep/ .yiyu-box-section {
    padding: 0;
    margin: 0;
    border-radius: 0;
    .title {
      display: none;
    }

    /deep/ .van-cell__title.van-field__label {
      width: auto;
    }
  }

  // 导航栏纯白色
  /deep/ .yiyu-height {
    background: transparent;
  }
  /deep/ #yiyu-navbar {
    background: transparent;
    span {
      color: #fff;
    }
  }
  /deep/ .van-nav-bar {
    background: transparent;
    .van-icon,
    .van-nav-bar__title {
      color: #fff;
    }
  }

  .link-popup {
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;

    .title {
      font-size: 1.2rem;
      color: @titleColor;
      font-weight: 500;
    }

    .link {
      margin: 32px 16px;
      padding: 16px 8px;
      background: @bgColor;
      border: 1px solid @lineColor;
      border-radius: 4px;
    }

    .btn {
      width: 100%;
      border-radius: 4px;
    }

    .hide {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
  }
}
</style>

<template>
  <div>
    <yiyu-navbar tab-title="实结率设置"></yiyu-navbar>
    <div class="settlement_percent">
      <van-form input-align="center" center="true" error-message-align="center">
        <van-field
          v-model="settlement_percent"
          label="实结率"
          placeholder="请输入实结率"
          type="number"
        >
          <template #extra>
            <div class="extra-txt">%</div>
          </template>
        </van-field>
      </van-form>
      <van-button @click="editSetting" type="primary">完成</van-button>
    </div>
  </div>
</template>

<script>
import yiyuNavbar from '@/components/navbar'
export default {
  name: 'surivalRateSet',
  data() {
    return {
      postLoading: false,
      settlement_percent: undefined,
      s_key: undefined,
    }
  },
  mounted() {
    this.getSetting()
  },
  components: {
    yiyuNavbar,
  },
  methods: {
    getSetting() {
      this.$get('/setting/getSetting', {}).then((res) => {
        if (res.code == 10000) {
          this.settlement_percent = res.data.settlement_percent
          this.s_key = res.data.s_key
        }
      })
    },
    editSetting() {
      if (this.postLoading) return
      this.postLoading = true
      this.$post('/setting/addSetting', {
        s_key: this.s_key,
        settlement_percent: this.settlement_percent,
      }).then((res) => {
        this.postLoading = false
        if (res.code == 10000) {
          this.$toast.success('修改成功')
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.settlement_percent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  .extra-txt {
    color: @textColor;
  }

  /deep/.van-cell__title.van-field__label {
    font-size: 1.1rem;
    font-weight: 500;
    color: @titleColor;
    margin-bottom: 20px;
  }

  /deep/.van-field__control {
    font-size: 1.1rem;
    font-weight: 500;
    color: @titleColor;
  }
}
</style>

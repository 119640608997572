<template>
  <div class="supplier grey-bg">
    <yiyu-navbar tab-title="来源设置"></yiyu-navbar>
    <yiyu-tabs v-model="tabName" width="100%" class="tab">
      <yiyu-tab-pane name="0" label="全部"></yiyu-tab-pane>
      <yiyu-tab-pane
        v-for="(item, key, index) in tabOpts"
        :key="index"
        :name="key"
        :label="item"
      ></yiyu-tab-pane>
    </yiyu-tabs>
    <div class="content" v-myLoad="isLoading" style="position: relative">
      <div
        v-for="(item, index) in list[tabName]"
        :key="index"
        class="list-item"
      >
        <div class="list-item-left" @click="edit(item)">
          <div class="name">{{ item.name }}</div>
          <div class="remark">{{ item.remark }}</div>
        </div>
        <div class="list-item-right" @click="delData(item.s_key)">
          <img
            src="@/assets/icons/del_blue.png"
            alt=""
            width="28px"
            height="28px"
            class="img-filter"
          />
        </div>
      </div>
      <yiyu-empty
        v-if="list[tabName] && list[tabName].length == 0"
      ></yiyu-empty>
      <drag-btn @add="addData"></drag-btn>
    </div>

    <form-popup
      :show.sync="addShow"
      v-if="addShow"
      :_this="thisAlias"
      :form-opts="formOpts"
      @cancel="addShow = false"
      @confirm="editData"
    ></form-popup>

    <yiyu-picker
      v-if="showTypePicker"
      :curKey="form.type"
      value-key="value"
      :show.sync="showTypePicker"
      :columns="tabOpts"
      @confirm="confirmPicker($event)"
      @cancel="showTypePicker = false"
    ></yiyu-picker>
  </div>
</template>

<script>
import yiyuTabs from '@/components/tab/tabs.vue'
import yiyuTabPane from '@/components/tab/tabPane.vue'
import dragBtn from '@/components/button/dragBtn'
import yiyuEmpty from '@/components/layout/empty.vue'
import yiyuNavbar from '@/components/navbar'
import formPopup from '@/components/popup/formPopup'
import yiyuPicker from '@/components/popup/picker'
import { Dialog } from 'vant'
export default {
  name: 'supplierSet',
  components: {
    yiyuTabs,
    yiyuTabPane,
    yiyuEmpty,
    yiyuNavbar,
    dragBtn,
    formPopup,
    yiyuPicker,
  },
  data() {
    return {
      postLoading: false,
      toAdd: false,
      btnLoading: false,
      addShow: false,
      thisAlias: this,
      form: {
        name: undefined,
        remark: undefined,
        s_key: undefined,
        type: undefined,
      },
      isLoading: false,
      tabName: '0',
      tabOpts: {
        1: '育苗',
        // 2: '饲料',
        // 3: '饵料',
        // 4: '动保',
        5: '其他',
      },
      showTypePicker: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path.indexOf('setting') !== -1) {
        vm.toAdd = false
      } else {
        vm.toAdd = true
      }
    })
  },
  mounted() {
    this.getData()
    if (this.toAdd) {
      this.addData()
      if (this.$route.query) {
        // 不需要写在 activated里
        this.form.type = this.$route.query.type
      }
    }
  },
  computed: {
    formOpts() {
      let _this = this
      return [
        {
          required: true,
          colon: true,
          formType: 'input',
          value: this.form.name,
          name: 'name',
          label: '来源',
          placeholder: '请输入来源',
          rules: [{ required: true, message: '请输入来源' }],
        },
        {
          required: true,
          colon: true,
          formType: 'select',
          type: 'text', // 默认，可以不写
          value: (this.form.type && this.tabOpts[this.form.type]) || undefined,
          name: 'type',
          label: '来源类别',
          placeholder: '请选择来源类别',
          click() {
            _this.showTypePicker = true
          },
          rules: [{ required: true, message: '请选择来源类别' }],
        },
        {
          required: false,
          colon: true,
          formType: 'input',
          value: this.form.remark,
          name: 'remark',
          label: '备注',
          placeholder: '请输入备注',
          rules: [{ required: false, message: '请输入备注' }],
        },
      ]
    },
    list() {
      let list = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
      }
      list[0] = this.$store.state.setting.suppliers
      this.$store.state.setting.suppliers.forEach((item) => {
        list[item.type] && list[item.type].push(item)
      })
      return list
    },
  },
  methods: {
    getData() {
      if (!this.$store.state.setting.getSuppliers) {
        this.isLoading = true
        this.$store.dispatch('getSupplier', {}).then((res) => {
          if (res.code == 10000) {
            this.isLoading = false
          }
        })
      }
    },
    tabData() {},
    delData(key) {
      Dialog.confirm({
        // title: "标题",
        message: '确认删除？',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$store
            .dispatch('delSupplier', {
              s_key: key,
            })
            .then((res) => {
              this.postLoading = false
              if (res.code == 10000) {
                this.$toast.success('删除成功！')
              }
            })
        })
        .catch(() => {
          // on cancel
        })
    },
    editData() {
      if (this.btnLoading) return
      this.btnLoading = true

      this.$store
        .dispatch('addSupplier', {
          s_key: this.form.s_key,
          name: this.form.name,
          remark: this.form.remark,
          type: String(this.form.type),
        })
        .then((res) => {
          this.btnLoading = false
          if (res.code == 10000) {
            if (this.form.s_key) {
              this.$toast.success('修改成功！')
            } else {
              this.$toast.success('新增成功！')
            }
            this.addShow = false
          }
        })
    },
    addData() {
      this.form = {
        name: undefined,
        remark: undefined,
        type: this.tabName || undefined,
      }

      this.addShow = true
    },
    edit(item) {
      this.form = {
        name: item.name,
        remark: item.remark,
        s_key: item.s_key,
        type: item.type,
      }
      this.addShow = true
    },
    confirmPicker(e) {
      this.form.type = e[0].key
      this.showTypePicker = false
    },
  },
}
</script>

<style lang="less" scoped>
.supplier {
  min-height: 100%; // 100vh;
  background: #fff;
  padding: 0 1rem;
  .list-item {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;

    &-left {
      padding-right: 0.5rem;
      flex: 1;
    }

    &-right {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      margin-right: 0.5rem;
      border-left: 1px solid @lineColor;
    }

    .name {
      color: @titleColor;
      font-weight: 500px;
      font-size: 1.1rem;
    }

    .remark {
      color: @textColor;
      font-weight: 500px;
      font-size: 1rem;
    }
  }

  .list-item-right {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
}
/deep/ .yiyu-empty {
  margin-top: 2.5rem;
}
</style>

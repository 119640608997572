var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yiyu-opt"},[_c('div',{staticClass:"fixed yiyu-fixed"},[_vm._l((_vm.leftObj),function(item,key){return _c('div',{key:key,staticClass:"left",style:({
        width:
          (_vm.leftObj.length > 2 ? 60 / _vm.leftObj.length : 40 / _vm.leftObj.length) +
          '%',
      }),on:{"click":function($event){return _vm.ck(item.callback)}}},[_c('div',[(item.src)?[_c('img',{attrs:{"src":item.src,"alt":"","width":"25","height":"25"}})]:(item.iconName)?[_c('van-icon',{attrs:{"name":item.iconName,"color":item.color,"size":"25"}})]:(item.slot)?[_vm._t(item.slot)]:_vm._e()],2),_c('div',[_vm._v(_vm._s(item.text))])])}),(_vm.rightShow)?_c('div',{directives:[{name:"permit",rawName:"v-permit",value:(_vm.role),expression:"role"}],staticClass:"right",style:({
        backgroundColor: _vm.rightObj.bgcolor,
        borderRadius: _vm.leftObj.length ? '30px 0 0 30px' : '30px',
      }),on:{"click":function($event){return _vm.ck(_vm.rightObj.callback)}}},[_c('div',[_vm._v(_vm._s(_vm.rightObj.text))])]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
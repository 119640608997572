<template>
  <div class="main">
    <yiyu-navbar :tab-title="tabTitle"> </yiyu-navbar>
    <yiyu-form
      :formOpts="formOpts"
      :form="form"
      :_this="thisAasia"
      @submit="publish"
      ref="form"
    >
      <template #push>
        <div class="row-between push_wc">
          <div class="label">公众号推送</div>
          <van-switch v-model="form.push_wc_bool" size="16px" />
        </div>
      </template>
      <template #rele>
        <section class="rele relative" v-myLoad="reLoading">
          <select-tag
            value-key="text"
            key-name="index"
            :opts="form.re_list"
            v-model="rele"
          ></select-tag>
        </section>
      </template>
      <template #footer>
        <fixed-btn
          btnTxt="发布"
          @click="handle"
          :loading="btnLoading"
          :disabled="btnLoading"
        ></fixed-btn>
      </template>
    </yiyu-form>
    <keep-alive>
      <b-person-picker
        ref="personPicker"
        :cur-key.sync="form.receive_user"
        :cur-name.sync="form.receiveUser"
        keyName="user_key"
        :default-chosed-role="defaultReceiver"
        :remove-keys="[$store.state.basic.user_key]"
      ></b-person-picker>
    </keep-alive>

    <yiyu-picker
      v-if="showPicker"
      :curKey="form.priority"
      :show.sync="showPicker"
      :columns="$store.state.basic.noticeLevel"
      @confirm="confirmPicker($event)"
      @cancel="showPicker = false"
    ></yiyu-picker>
  </div>
</template>

<script>
import bPersonPicker from '@/bComponents/bPersonPicker.vue'
import noticeMixin from '@/views/notice/noticeMixin'
import selectTag from '@/components/tag/selectTag.vue'
import yiyuPicker from '@/components/popup/picker'
import yiyuNavbar from '@/components/navbar'
import fixedBtn from '@/components/button/fixedBtn'
import yiyuForm from '@/components/form/yiyuForm.vue'
export default {
  name: 'publish',
  mixins: [noticeMixin],
  components: {
    fixedBtn,
    yiyuNavbar,
    yiyuForm,
    yiyuPicker,
    selectTag,
    bPersonPicker,
  },
  data() {
    return {
      btnLoading: false,
      person: {},
      personList: [],
      personMap: {}, // key -> value
      show: false,
      chosed: {},
      form: {
        title: undefined,
        remark: undefined,
        receive_user: [],
        receiveUser: undefined,
        priority: '6',
        push_wc: 1,
        push_wc_bool: true,
        re_list: [
          {
            text: '', // 要展示的文字
            type: '', // 要跳转的类型
            re_info: {}, // 需要用到的各种key，如：l_key, ss_key等
          },
        ],
        url: undefined, // 公众号要跳转的链接
      },
      thisAasia: this,
      defaultReceiver: undefined, // 1 表示默认选中管理员
      status: 'add',
      showPicker: false,
      rele: [], // 存储要关联的信息key
      reLoading: false,
    }
  },
  computed: {
    tabTitle() {
      if (this.status == 'add') {
        return '新增通知'
      } else {
        return '编辑通知'
      }
    },
    formOpts() {
      let _this = this
      let opts = [
        {
          title: '',
          child: [
            {
              required: true,
              colon: true,
              formType: 'i pnput', // 默认
              value: this.form.title,
              name: 'title',
              label: '标题',
              placeholder: '请输入标题',
              rules: [{ required: true, message: '请输入标题' }],
            },
            {
              required: true,
              colon: true,
              formType: 'textarea', // 默认
              rows: 3,
              maxlength: 500,
              value: this.form.remark,
              name: 'remark',
              label: '内容',
              placeholder: '请输入内容',
              rules: [{ required: true, message: '请输入内容' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              value: this.form.receiveUser,
              name: 'receiveUser',
              label: '接收人',
              placeholder: '请选择接收人',
              click() {
                _this.toChose('receiver')
              },
              rules: [{ required: true, message: '请选择接收人' }],
            },
            {
              required: false,
              colon: true,
              formType: 'select',
              value: this.$store.state.basic.noticeLevel[this.form.priority],
              name: 'priority',
              label: '优先级',
              placeholder: '请选择优先级',
              click() {
                _this.toChose('priority')
              },
              bottomSlot: 'push',
            },
          ],
        },
        {
          title: '关联信息',
          slot: 'rele',
          show: this.form.re_list.length != 0,
        },
      ]
      return opts
    },
  },
  watch: {
    'form.push_ws_bool'(val) {
      this.form.push_wc = val ? '1' : '2'
    },
    'form.push_ws'(val) {
      this.form.push_wc_bool = val == '1' ? true : false
    },
  },
  mounted() {
    if (this.$route.query) {
      let query = this.$route.query
      this.form.notice_key = query.k

      if (this.form.notice_key) {
        this.status = 'edit'
      } else {
        this.status = 'add'
      }

      if (this.status == 'add') {
        if (this.$route.params) {
          let params = this.$route.params
          this.form.title = params.title
          this.form.remark = params.remark
          this.form.priority = params.priority
          this.form.receive_user = params.user_key || []

          this.defaultReceiver = Array.isArray(params.p)
            ? params.p
            : params.p
            ? [params.p]
            : []
          this.form.re_list = params.re || []
          if (this.form.re_list.length) {
            this.form.re_list.forEach((item, index) => {
              item.index = index
            })
            this.getReleTxt()
          }
        }
      } else {
        if (this.form.notice_key) {
          this.getData()
        }
      }
    }
  },
  methods: {
    getData() {
      this.$get('notice/getNotice', {
        notice_key: this.form.notice_key,
      }).then((res) => {
        if (res.code == 10000) {
          let data = res.data.notice
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key]
          }

          let tmp = []
          let tmpName = []
          data.receive_user &&
            data.receive_user.forEach((item) => {
              tmp.push(item.user_key)
              tmpName.push(item.nickname)
            })
          data.receive_user = tmp
          data.receiveUser = tmpName.join('/')

          data.re_list.forEach((item, index) => {
            item.index = index
            item.text = item.text || this.noticeTypeObj[item.type].title
            if (item.re_status == 2) {
              this.rele.push(index)
            }
          })

          this.form = data
        }
      })
    },
    getReleTxt() {
      this.reLoading = true
      this.$get('/conf/getSelectConf', {
        select_items: ['Notice_Text'],
        params: {
          re_list: this.form.re_list,
        },
      }).then((res) => {
        if (res.code == 10000) {
          this.reLoading = false
          this.form.re_list = res.data?.Notice_Text?.value || []
          this.rele = []
          this.form.re_list.forEach((item, index) => {
            item.index = index
            item.text = item.text || this.noticeTypeObj[item.type].title
            this.rele.push(index)
          })
        }
      })
    },
    handle() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    toChose(type) {
      switch (type) {
        case 'receiver':
          this.$refs['personPicker'].show = true
          break
        case 'batch':
          this.$toPage(this, 'batchSelect', {
            lm_id: this.form.lm_id,
          })
          break
        case 'order':
          this.$toPage(this, 'orderSelect', {
            or_id: this.form.or_id,
          })
          break
        case 'priority':
          this.showPicker = true
          break
      }
    },
    publish() {
      this.btnLoading = true
      let form = JSON.parse(JSON.stringify(this.form))
      form.re_list.forEach((item) => {
        if (this.rele.includes(item.index)) {
          item.re_status = 2
        } else {
          item.re_status = 1 //未关联
        }
      })
      this.$post('/notice/addNotice', form).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          this.$toast.success('发布成功')
          this.$replace(this, {
            name: 'notice',
            params: { tab: '1' },
          })
        }
      })
    },
    confirmPicker(e) {
      this.form.priority = e[0].key
      this.showPicker = false
    },
  },
}
</script>

<style lang="less" scoped>
.push_wc {
  &.row-between {
    padding: 0.5rem 1rem;
  }
}
</style>

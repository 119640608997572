<template>
  <div class="setting">
    <yiyu-navbar tab-title="设置"></yiyu-navbar>
    <van-tabs v-model="active">
      <template v-for="(item, index) in FILTER_HANDLE(tabData)">
        <van-tab :title="item.title" :key="index">
          <div class="tab-content">
            <icon-field
              :data="item.child"
              @click="toPage"
              :num="3"
            ></icon-field>
          </div>
        </van-tab>
      </template>
    </van-tabs>
  </div>
</template>

<script>
import yiyuNavbar from '@/components/navbar'
import iconField from '@/components/layout/iconField.vue'

export default {
  name: 'setting',
  components: {
    yiyuNavbar,
    iconField,
  },
  data() {
    return {
      active: undefined,
    }
  },
  computed: {
    tabData() {
      return [
        {
          title: '种虾',
          handle: 'MORE_SETTING_SEED',
          child: [
            {
              txt: '种虾别名',
              fn: '',
              to: {
                path: 'confSet',
                query: { type: 'seedAlias' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '种虾名称',
              fn: '',
              to: {
                path: 'confSet',
                query: { type: 'seedName' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '种虾品系',
              fn: '',
              to: {
                path: 'confSet',
                query: { type: 'seedStrain' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '投喂配置',
              to: {
                path: 'techMgt',
                query: { ws_type: '2', test_type: '2' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '投喂指标类别',
              fn: '',
              to: {
                path: 'confSet',
                query: { type: 'seedFeedTargetType' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '阶段',
              to: {
                path: 'confSet',
                query: { type: 'seedStage' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
          ],
        },
        {
          title: '育苗',
          handle: 'MORE_SETTING_LARVA',
          child: this.FILTER_HANDLE([
            {
              txt: '成活率',
              fn: 'surivalRateSet',
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_SURIVAL',
            },
            {
              txt: '实结率',
              fn: 'settlementSet',
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_SETTLE',
            },
            {
              txt: '育苗天数',
              fn: 'breedDaySet',
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_BREED',
            },
            {
              txt: '印刷袋',
              fn: 'bagSet',
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_BAG',
            },
            {
              txt: '投喂配置',
              to: {
                path: 'techMgt',
                query: { ws_type: '1', test_type: '2' },
              },
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_TECHCONF',
            },
            {
              txt: '投喂指标类别',
              fn: '',
              to: {
                path: 'confSet',
                query: { type: 'growFeedTargetType' },
              },
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_TECHTYPE',
            },
            {
              txt: '阶段',
              to: {
                path: 'confSet',
                query: { type: 'larvalStage' },
              },
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_PHASE',
            },
            {
              txt: '来源',
              fn: 'supplierSet',
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_SUPPLIER',
            },
            {
              txt: '运输行程',
              fn: 'transportSet',
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_TRANSPORT',
            },
            {
              txt: '联系人',
              fn: 'contactSet',
              src: require('@/assets/images/set_mgt.png'),
              handle: 'MORE_SETTING_LARVA_CONTACT',
            },
          ]),
        },
        {
          title: '藻类',
          handle: 'MORE_SETTING_ALGA',
          child: [
            {
              txt: '藻种来源',
              fn: '',
              to: {
                path: 'confSet',
                query: { type: 'algaeSource' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '品种',
              fn: '',
              to: {
                path: 'confSet',
                query: { type: 'algalSpecies' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '容器管理',
              fn: 'containerList',
              src: require('@/assets/images/set_mgt.png'),
            },
          ],
        },
        {
          title: '质检',
          handle: 'MORE_SETTING_DECT',
          child: [
            {
              txt: '样本类型',
              fn: '',
              to: {
                path: 'confSet',
                query: { type: 'dectObjType' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '质检配置',
              fn: 'dectSet',
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '指标类别',
              fn: '',
              to: {
                path: 'confSet',
                query: { type: 'dectConfType' },
              },
              src: require('@/assets/images/set_mgt.png'),
            },
          ],
        },
        {
          title: '销售',
          handle: 'MORE_SETTING_SALE',
          child: [
            {
              txt: '订单默认需求',
              fn: 'orderSet',
              src: require('@/assets/images/set_mgt.png'),
            },

            {
              txt: '客户区域',
              fn: 'regionSet',
              src: require('@/assets/images/set_mgt.png'),
            },
          ],
        },
        {
          title: '库存',
          handle: 'MORE_SETTING_STORE',
          child: [
            {
              txt: '物品类别',
              to: {
                path: 'confSet',
                query: { type: 'goodsType' },
              },
              show: this.IS_HANDLE('MORE_SETTING_STORE_GOODS_TYPE'),
              src: require('@/assets/images/set_mgt.png'),
            },
            {
              txt: '供应商',

              to: {
                path: 'confSet',
                query: { type: 'supplier' },
              },
              src: require('@/assets/images/set_mgt.png'),
              show: this.IS_HANDLE('MORE_SETTING_STORE_SUPPLIER'),
            },
            {
              txt: '采购审核人',

              to: {
                path: 'confSet',
                query: { type: 'examineMan' },
              },
              src: require('@/assets/images/set_mgt.png'),
              show: this.IS_HANDLE('MORE_SETTING_STORE_EXAMINE_MAN'),
            },
            {
              txt: '申请部门',
              to: {
                path: 'confSet',
                query: { type: 'section' },
              },
              src: require('@/assets/images/set_mgt.png'),
              show: this.IS_HANDLE('MORE_SETTING_STORE_SECTION'),
            },
          ],
        },
      ]
    },
  },
  methods: {
    toPage(item) {
      this.$toPage(this, item)
    },
  },
}
</script>

<style lang="less" scoped>
.main-content {
  padding: 0.5rem;
}

.tab-content {
  margin: 1rem 0;
}
</style>

<template>
  <list-container
    @more="getMoreData"
    @refresh="refreshData"
    class="list-container"
    :pullDisable="true"
  >
    <yy-navbar />
    <van-sticky offset-top="2.2rem">
      <van-search
        @search="refreshData"
        v-model="keyword"
        placeholder="可搜索验收单"
        style="position: relative"
      ></van-search>
      <y-filter
        :data="baseFilterData"
        ref="filter"
        @search="fetchFilterData"
        @reset="resetData"
      >
        <YFilterItemCalendar slot="操作日期" type="range" />
      </y-filter>
    </van-sticky>

    <div class="list relative" v-myLoad="isLoading">
      <div
        class="list-item-wrap"
        v-for="(item, index) in list"
        :key="index + 'list-item'"
        @click="toPage(item)"
      >
        <div>
          <h4>{{ item.ca_identi }}</h4>
          <dataField :dataObj="getDataObj(item)" type="row-between"></dataField>
          <YLayoutBottomInfo
            :key="index"
            :name="item.nickname"
            :time="item.created_at"
          ></YLayoutBottomInfo>
        </div>
      </div>
      <yiyu-empty v-if="!list.length"></yiyu-empty>
      <van-divider v-if="list.length >= total && total > 10"
        >已经到底啦</van-divider
      >
      <drag-btn slot-name="add" @add="acceptEdit" newPosition></drag-btn>
      <drag-btn slot-name="top"></drag-btn>
    </div>
  </list-container>
</template>

<script>
import dragBtn from '@/components/button/dragBtn';
import dataField from '@/components/layout/dataField.vue';
import { YFilter, YLayoutBottomInfo, YFilterItemCalendar } from 'yiyu-vant-ui';
import yiyuEmpty from '@/components/layout/empty.vue';
import listContainer from '@/components/layout/listContainer';
import { mapState } from 'vuex';

export default {
  name: 'transport',
  components: {
    listContainer,
    yiyuEmpty,
    YLayoutBottomInfo,
    YFilter,
    dataField,
    dragBtn,
    YFilterItemCalendar,
  },
  data() {
    return {
      isLoading: false,
      kw: undefined,
      lm_id: undefined,
      list: [],
      page: 1,
      count: 10,
      total: 0,
      scroll: 0,
      keyword: undefined,
      baseFilterData: [
        {
          label: '操作人',
          remote: async () => {
            const { data } = await this.$get('/member/getMember');

            let memberList = {};
            const filterVale = (item) => ({
              label: item.nickname,
              key: item.user_key,
            });

            data.list.forEach((item) => {
              const _roleLabel = this.roleMap[item.role];
              Array.isArray(memberList[_roleLabel])
                ? memberList[_roleLabel].push(filterVale(item))
                : (memberList[_roleLabel] = [filterVale(item)]);
            });

            return Object.keys(memberList).map((key) => {
              return {
                label: key,
                keyName: 'opt_user_key_list',
                children: memberList[key],
              };
            });
          },
        },
        {
          label: '操作日期',
          keyName: ['start_time', 'end_time'],
        },
        {
          label: '验收人',
          remote: async () => {
            const { data } = await this.$get('/member/getMember');

            let memberList = {};
            const filterVale = (item) => ({
              label: item.nickname,
              key: item.user_key,
            });

            data.list.forEach((item) => {
              const _roleLabel = this.roleMap[item.role];
              Array.isArray(memberList[_roleLabel])
                ? memberList[_roleLabel].push(filterVale(item))
                : (memberList[_roleLabel] = [filterVale(item)]);
            });

            return Object.keys(memberList).map((key) => {
              return {
                label: key,
                keyName: 'cau_user_key_list',
                children: memberList[key],
              };
            });
          },
        },
        {
          label: '归属仓库',
          keyName: 'wh_key_list',
          remote: async () => {
            const { data } = await this.$get('/entrepot_warehouse/getList');
            return [
              {
                label: '',
                keyName: 'wh_key_list',
                children: data.map((item) => ({
                  label: item.name,
                  key: item.key,
                })),
              },
            ];
          },
        },
      ],
      sDate: new Date(),
    };
  },
  computed: {
    ...mapState({
      transStatus: (state) => state.transport.transStatus,
      roleMap: (state) => state.basic.roleObj,
    }),
  },
  mounted() {
    this.mountedFn();
    // 主要是为了拿统计数量
    this.getAcceptData({ page: 1, count: this.page * this.count }, true);
  },
  activated() {
    this.mountedFn();
    this.getData(1, this.page * this.count);
    this.$nextTick(() => {
      setTimeout(() => {
        if (document.querySelector('.list-container')) {
          document.querySelector('.list-container').scrollTop = this.scroll;
        }
      }, 0);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.scroll = document.querySelector('.list-container').scrollTop;
    next();
  },
  methods: {
    mountedFn() {
      this.activated = true;
      if (this.$route.query) {
        this.lm_id = this.$route.query.lm_id;
      }
    },
    dateChange(e) {
      return this.$dayjs(e).format('YYYY/MM/DD');
    },
    getData(page, count) {
      let page1 = page || this.page;
      let count1 = count || this.count;
      let filterParams = {
        opt_user_key_list: this.filterParams?.opt_user_key_list,
        date_range:
          this.filterParams?.start_time && this.filterParams?.end_time
            ? [this.filterParams.start_time, this.filterParams.end_time]
            : [],
        cau_user_key_list: this.filterParams?.cau_user_key_list,
        wh_key_list: this.filterParams?.wh_key_list,
      };
      let data = {
        page: page1,
        count: count1,
        ...filterParams,
      };
      this.isLoading = true;
      this.getAcceptData(data, count);
    },
    getAcceptData(data, count) {
      Object.assign(data, { keyword: this.keyword });
      this.$get('/entrepot_ca/listCheckAccept', data).then((res) => {
        this.dataDeal(res, count);
        this.total = res.data.total;
        this.isLoading = false;
      });
    },

    dataDeal(res, count) {
      if (res.code == 10000) {
        let list = res.data.list;
        if (count) {
          this.list = list;
        } else {
          this.list.push(...list);
        }
      }
    },
    getMoreData() {
      if (this.list.length < this.total) {
        this.page++;
        this.getData();
      }
    },
    fetchFilterData(e) {
      this.filterParams = e;
      this.refreshData();
    },
    resetData() {
      this.filterParams = {};
      this.refreshData();
    },
    refreshData() {
      this.page = 1;
      this.count = 10;
      this.list = [];
      this.getData();
    },
    toPage(item) {
      this.$toPage(this, 'acceptDetail', {
        ca_key: item.ca_key,
      });
    },
    acceptEdit() {
      this.$toPage(this, 'acceptEdit');
    },
    getDataObj(item) {
      return [
        {
          descText: '物品',
          data: item.goods_name_list?.join('、'),
        },
        {
          descText: '验收人',
          data: item.cau_user_name_list?.join('、'),
        },
        {
          descText: '归属仓库',
          data: item.wh_name,
        },
      ];
    },
  },
};
</script>
<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  justify-content: space-between;

  .van-search {
    flex-basis: 100%;
  }
}
.row-between {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.list {
  .list-item-wrap {
    padding: 0.5rem;
    border-bottom: 1px solid @lineColor;

    .wrap {
      padding: 0.2rem;
    }

    .row {
      display: flex;
      align-items: center;
      margin: 0.1rem 0;
      line-height: 1.4rem;

      .label {
        color: @textColor;
        font-weight: bold;
        opacity: 0.7;
      }

      .value {
        color: @textColor;
        font-size: 1rem;
        margin-left: 0.5rem;

        &.bold {
          font-weight: 700;
          color: @titleColor;
          font-size: 1.1rem;
        }
      }
    }

    .handles {
      display: flex;
      .handle-btn {
        border: 1px solid @lineColor;
        margin: 0 0.5rem;
        padding: 0 0.4rem;
        border-radius: 0.5rem;
        .txt {
          white-space: nowrap;
        }
      }
    }
  }
}
</style>

<template>
  <div class="supplier grey-bg">
    <yiyu-navbar tab-title="结点设置"></yiyu-navbar>
    <div class="content" v-myLoad="isLoading" style="position: relative">
      <div v-for="(item, index) in list" :key="index" class="list-item">
        <div class="list-item-left" @click="edit(item)">
          <div class="name">{{ item.name }}</div>
          <!-- <div class="remark">{{ item.remark }}</div> -->
        </div>
        <div class="list-item-right" @click="delData(item.n_key)">
          <img
            src="@/assets/icons/del_blue.png"
            alt=""
            width="28px"
            height="28px"
            class="img-filter"
          />
        </div>
      </div>
      <yiyu-empty v-if="list.length == 0"></yiyu-empty>
      <drag-btn @add="addData"></drag-btn>
    </div>

    <form-popup
      :show.sync="addShow"
      v-if="addShow"
      :_this="thisAlias"
      :form-opts="formOpts"
      @cancel="addShow = false"
      @confirm="editData"
    ></form-popup>
  </div>
</template>

<script>
import dragBtn from '@/components/button/dragBtn'
import yiyuEmpty from '@/components/layout/empty.vue'
import yiyuNavbar from '@/components/navbar'
import formPopup from '@/components/popup/formPopup'
import { Dialog } from 'vant'
export default {
  name: 'supplier',
  components: {
    yiyuEmpty,
    yiyuNavbar,
    dragBtn,
    formPopup,
  },
  data() {
    return {
      postLoading: false,
      toAdd: false,
      btnLoading: false,
      list: [],
      addShow: false,
      thisAlias: this,
      form: {
        name: undefined,
        remark: undefined,
        n_key: undefined,
      },
      isLoading: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path.indexOf('setting') !== -1) {
        vm.toAdd = false
      } else {
        vm.toAdd = true
      }
    })
  },
  mounted() {
    this.getData()
    if (this.toAdd) {
      this.addData()
    }
  },
  computed: {
    formOpts() {
      return [
        {
          required: true,
          colon: true,
          formType: 'input',
          value: this.form.name,
          name: 'name',
          label: '结点',
          placeholder: '请输入结点',
          rules: [{ required: true, message: '请输入结点' }],
        },
        // {
        //   required: false,
        //   colon: true,
        //   formType: "input",
        //   value: this.form.remark,
        //   name: "remark",
        //   label: "备注",
        //   placeholder: "请输入备注",
        //   rules: [{ required: false, message: "请输入备注" }],
        // },
      ]
    },
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/node/getNodeList', {}).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.list = res.data.list
        }
      })
    },
    delData(key) {
      Dialog.confirm({
        // title: "标题",
        message: '确认删除？',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/node/delNode', {
            n_key: key,
          }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success('删除成功！')
              this.getData()
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    editData() {
      if (this.btnLoading) return
      this.btnLoading = true
      this.$post('/node/addNode', {
        n_key: this.form.n_key,
        name: this.form.name,
        // remark: this.form.remark,
      }).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          if (this.form.n_key) {
            this.$toast.success('修改成功！')
          } else {
            this.$toast.success('新增成功！')
          }
          this.addShow = false
          this.getData()
        }
      })
    },
    addData() {
      this.form = {
        name: undefined,
        // remark: undefined,
      }
      this.addShow = true
    },
    edit(item) {
      this.form = {
        name: item.name,
        // remark: item.remark,
        n_key: item.n_key,
      }
      this.addShow = true
    },
  },
}
</script>

<style lang="less" scoped>
.supplier {
  min-height: 100%; // 100vh;

  .list-item {
    background: #fff;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    &-left {
      flex: 1;
    }

    .name {
      color: @titleColor;
      font-weight: 500px;
      font-size: 1.1rem;
    }

    .remark {
      color: @textColor;
      font-weight: 500px;
      font-size: 1rem;
    }
  }

  .list-item-right {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
}
/deep/ .yiyu-empty {
  margin-top: 2.5rem;
}
</style>

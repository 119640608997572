<template>
  <div id="yiyu-popup-tip-dialog">
    <van-dialog v-model="showVisible" :title="tipData.title" @closed="close">
      <div class="content">
        <slot>
          <div
            class="tip-item"
            v-for="(item, index) in tipData.content"
            :key="index"
          >
            <div class="title">{{ item.label }}</div>
            <div class="txt">{{ item.value }}</div>
          </div>
        </slot>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'tipDialog',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '标题',
    },
    tipData: {
      type: Object,
      default: () => {
        return {
          title: '产销数据',
          content: [
            {
              label: `预定量`,
              value: `审核通过订单的客户预定量`,
            },
            {
              label: `估苗量`,
              value: `定义：处于生产中虾苗估苗量的总和\n作用：预估产量，可对比预定量，调节产销平衡`,
            },
            {
              label: `成活率？`,
              value: `估苗量/投放量`,
            },
            {
              label: `产能`,
              value: `定义：非空置放育苗池占所有育苗池的比例\n作用：根据产能可预估安排后续的销售和生产`,
            },
          ],
        }
      },
    },
  },
  data() {
    return {
      showVisible: this.show,
    }
  },
  methods: {
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="less">
#yiyu-popup-tip-dialog {
  .van-dialog__header {
    padding-top: 16px;
  }

  .content {
    padding: 1rem;
    max-height: 70vh;
    overflow-y: auto;

    .tip-item {
      margin: 8px 0;
      .title {
        font-size: 1.1rem;
        font-weight: 600;
        color: @titleColor;
      }

      .txt {
        color: @textColor;
        white-space: pre-wrap;
      }
    }
  }
}
</style>

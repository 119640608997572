<template>
  <div>
    <van-field
      v-if="!onlyButton"
      :border="false"
      v-model="form.account"
      name="account"
      placeholder="手机号"
      :rules="[
        { required: true, message: '请填写手机号' },
        { validator: yiyuPhone, message: '请输入正确的手机号' },
      ]"
    />
    <van-field
      v-if="!onlyButton"
      kw="code"
      :border="false"
      v-model="form.code"
      center
      clearable
      name="code"
      placeholder="短信验证码"
      :rules="[{ required: true, message: '请填写短息验证码' }]"
    >
      <template #button>
        <van-button
          size="small"
          type="primary"
          :disabled="codeLoading"
          @click.prevent="sendLoginPhoneMsg"
          >{{ codeTxt }}</van-button
        >
      </template>
    </van-field>
    <van-button
      v-if="onlyButton"
      size="small"
      type="primary"
      :disabled="codeLoading"
      @click.prevent="sendLoginPhoneMsg"
      >{{ codeTxt }}</van-button
    >
  </div>
</template>

<script>
import { rules } from '@/utils/mixins'

export default {
  name: 'login',
  mixins: [rules],
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    onlyButton: {
      type: Boolean,
      default: false,
    },
    codeTime: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      computeTime: 0,
      codeLoading: false,
      btnLoading: false,
    }
  },

  computed: {
    codeTxt() {
      return this.computeTime > 0
        ? `已发送(${this.computeTime}s)`
        : '获取验证码'
    },
  },
  methods: {
    sendLoginPhoneMsg() {
      this.$get('/login/sendLoginPhoneMsg', this.form).then((res) => {
        if (res.code == 10000) {
          this.computeTime = this.codeTime
          this.timer = setInterval(() => {
            this.codeLoading = true
            this.computeTime--
            if (this.computeTime <= 0) {
              clearInterval(this.timer)
              this.codeLoading = false
            }
          }, 1000)
          this.$toast.success('验证码发送成功')
        }
      })
    },
  },
}
</script>

<style lang="less" scoped></style>

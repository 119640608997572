<template>
  <div class="member-edit">
    <yiyu-nav-bar tabTitle="编辑信息"></yiyu-nav-bar>
    <div class="main-content" v-myLoad="isLoading" style="position: relative">
      <info :info="form" sub-key="comp_name">
        <template #right>
          <div class="info-right status row">
            <div class="status-item-invite" v-if="!initPhone">邀请中</div>
            <div class="status-item-leave" v-if="form.status == 2">已离职</div>
          </div>
        </template>
      </info>
      <yiyu-form
        :_this="thisAlias"
        :formOpts="formOpts"
        :form="form"
        ref="form"
        @submit="onSubmit"
      >
        <template #code>
          <proof :only-button="true" :form="proofList"></proof>
        </template>
        <template #footer>
          <fixed-btn
            btnTxt="保存"
            @click="toSubmit"
            :loading="btnLoading"
            :disabled="btnLoading"
          ></fixed-btn>
        </template>
      </yiyu-form>
    </div>
    <yiyu-picker
      v-if="showSexPicker"
      :curKey="form.sex"
      :show.sync="showSexPicker"
      :columns="sexColumns"
      @confirm="confirmPicker($event, 'sex')"
      @cancel="cancelPicker('sex')"
    ></yiyu-picker>
    <yiyu-picker
      v-if="showStatusPicker"
      :show.sync="showStatusPicker"
      :columns="statusColumns"
      @confirm="confirmPicker($event, 'status')"
      @cancel="cancelPicker('status')"
    ></yiyu-picker>
    <van-calendar
      v-model="showDatePicker"
      :min-date="minDate"
      :default-date="defaultDate('entry_date')"
      @confirm="confirmPicker($event, 'date')"
    />
    <datetime-popup
      :date.sync="form.birthday"
      :show.sync="showBirthPicker"
      type="date"
      :default-date="defaultDate('birthday')"
      @confirm="showBirthPicker = false"
      title="请选择出生日期"
      @cancel="showBirthPicker = false"
    />
    <keep-alive>
      <farm-picker
        ref="farmPicker"
        :curKey.sync="form.charge_mc"
        :curName.sync="form.charge_mcName"
      ></farm-picker>
    </keep-alive>
    <van-popup class="popup" v-model="isShowPopup" round position="bottom">
      <div class="header">
        <div class="row-between">
          <div class="header-left" @click="isShowPopup = false">取消</div>
        </div>
      </div>
      <select-tag
        v-if="wh_list && wh_list.length != 0"
        :opts="wh_list"
        v-model="form.ch_wh_key"
        :single="true"
        @change="whChange"
      ></select-tag>
      <div v-else>
        <yiyu-empty></yiyu-empty>
      </div>
      <div class="main-c txt-c" @click="toPage('warehousesAdd')">
        前往添加仓库
      </div>
    </van-popup>
    <b-role-picker
      :show.sync="showRolePicker"
      :roles.sync="form.role"
      :objs.sync="form.roleObj"
      :names.sync="form.roleName"
    ></b-role-picker>
  </div>
</template>

<script>
import bRolePicker from '@/bComponents/bRolePicker';
import datetimePopup from '@/components/popup/datetime.vue';
import farmPicker from '@/bComponents/farmPicker.vue';
import fixedBtn from '@/components/button/fixedBtn';
import yiyuNavBar from '@/components/navbar';
import yiyuForm from '@/components/form';
import { rules } from '@/utils/mixins';
import { formatTime } from '@/utils/date';
import proof from '@/components/proof/proof.vue';
import info from '@/bComponents/info';
import selectTag from '@/components/tag/selectTag.vue';
import yiyuPicker from '@/components/popup/picker';
import { deepCopy } from '@/utils/utils';

export default {
  name: 'memberEdit',
  mixins: [rules],
  components: {
    yiyuNavBar,
    yiyuForm,
    fixedBtn,
    yiyuPicker,
    farmPicker,
    datetimePopup,
    proof,
    bRolePicker,
    info,
    selectTag,
  },
  data() {
    return {
      btnLoading: false,
      thisAlias: this,
      initPhone: undefined, // 用来判断手机号是否修改
      form: {
        role: undefined,
        role_type: undefined, // 技术负责人 还分 测试 育苗 出苗
        charge_mc: [], // 负责基地[]
        charge_mcName: undefined,
        ch_wh_key: [],
        ch_wh_name: '',
      },
      wh_list: [],
      isShowPopup: false,
      showRolePicker: false,
      showMcPicker: false,
      showSexPicker: false,
      showStatusPicker: false,
      showDatePicker: false,
      showBirthPicker: false,
      nickname: undefined,
      share_key: undefined,
      mcOpts: [], // 基地选项
      isLoading: true,
      sexColumns: [
        {
          key: '1',
          text: '女',
        },
        {
          key: '2',
          text: '男',
        },
        {
          key: undefined,
          text: '未知',
        },
      ],
      statusColumns: [
        {
          key: '1',
          text: '正常',
        },
        {
          key: '2',
          text: '离职',
        },
      ],
      minDate: new Date(1900, 11, 1),
    };
  },
  created() {
    if (this.$route.query) {
      this.userKey = this.$route.query.userKey;
      if (this.userKey) this.getData();
    }
    this.$get('/entrepot_warehouse/getAllList').then((res) => {
      this.wh_list =
        res.data?.map((item) => {
          return {
            val: item.name,
            key: item.key,
          };
        }) || [];
    });
  },
  computed: {
    formOpts() {
      let _this = this;
      let tmp = undefined;
      if (this.initPhone != this.form.account) {
        tmp = {
          required:
            this.form.account != undefined && this.initPhone != undefined,
          colon: true,
          slot: 'code',
          formType: 'input', // 默认
          value: this.form.code,
          name: 'code',
          label: '验证码',
          placeholder: '请输入',
          rules: [
            {
              required:
                this.form.account != undefined && this.initPhone != undefined,
              message: '请输入',
            },
          ],
        };
      }

      let child = [
        {
          required: true,
          colon: true,
          formType: 'input', // 默认
          value: this.form.nickname,
          name: 'nickname',
          label: '姓名',
          placeholder: '请输入姓名',
          rules: [{ required: true, message: '请输入姓名' }],
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          click() {
            _this.showSexPicker = true;
          },
          value: this.form.sexName,
          name: 'sexName',
          label: '性别',
          placeholder: '请选择性别',
          rules: [{ required: false, message: '请选择性别' }],
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          click() {
            _this.showBirthPicker = true;
          },
          value: this.form.birthday,
          readonly: true,
          name: 'birthday',
          label: '出生日期',
          placeholder: '请选择出生日期',
          rules: [{ required: false, message: '请选择出生日期' }],
        },
        {
          required: false,
          colon: true,
          formType: 'input', // 默认
          value: this.form.address,
          name: 'address',
          label: '住址',
          placeholder: '请输入住址',
          rules: [{ required: false, message: '请输入住址' }],
        },
        {
          required:
            this.form.account != undefined && this.initPhone != undefined,
          colon: true,
          type: 'text', // 默认，可以不写
          value: this.form.account,
          name: 'account',
          label: '手机',
          placeholder: '请输入手机',
          rules: [
            {
              required:
                this.form.account != undefined && this.initPhone != undefined,
              message: '请输入输入手机',
            },
          ],
        },
        tmp,
        {
          required: false,
          colon: true,
          formType: 'select', // 默认
          value: this.form.roleName,
          name: 'roleName',
          label: '角色',
          placeholder: '请选择角色',
          rules: [{ required: false, message: '请选择角色' }],
          click() {
            _this.showRolePicker = true;
          },
          show: this.IS_HANDLE('MEMBER_ROLE_EDIT'),
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          click() {
            _this.showStatusPicker = true;
          },
          value: this.form.statusName,
          name: 'statusName',
          label: '在职状态',
          placeholder: '请选择在职状态',
          rules: [{ required: false, message: '请选择在职状态' }],
          show: this.IS_HANDLE('MEMBER_STATUS_EDIT'),
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          click() {
            _this.showDatePicker = true;
          },
          value: this.form.entry_date,
          readonly: true,
          name: 'entry_date',
          label: '入职时间',
          placeholder: '请选择入职时间',
          rules: [{ required: false, message: '请选择入职时间' }],
          show: this.IS_HANDLE('MEMBER_ENTRY_EDIT'),
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          show: this.form.status == 2,
          click() {
            _this.showDatePicker = true;
          },
          value: this.form.quit_date,
          readonly: true,
          name: 'quit_date',
          label: '离职时间',
          placeholder: '请选择离职时间',
          rules: [{ required: false, message: '请选择离职时间' }],
        },
        {
          required: false,
          colon: true,
          formType: 'select',
          type: 'text', // 默认，可以不写
          value: this.form.charge_mcName,
          name: 'charge_mcName',
          label: '负责基地',
          placeholder: '请选择负责基地',
          click() {
            if (_this.$refs['farmPicker']) {
              _this.$refs['farmPicker'].show = true;
            }
          },
          rules: [{ required: false, message: '请选择负责基地' }],
          show: this.IS_HANDLE('MEMBER_FARM_EDIT'),
        },
        {
          required: true,
          colon: true,
          formType: 'select',
          type: 'text', // 默认，可以不写
          value: this.form.ch_wh_name,
          name: 'ch_wh_name',
          label: '默认基地',
          placeholder: '请选择默认基地',
          click() {
            _this.isShowPopup = true;
          },
          rules: [{ required: false, message: '请选择默认基地' }],
          show: this.IS_HANDLE('MEMBER_FARM_EDIT'),
        },
      ];

      child = child.filter((item) => item != undefined);

      let opts = [
        {
          title: '',
          child: child,
        },
      ];
      return opts;
    },
    codeDisabled() {
      if (!this.form.account) return true;
      return !this.yiyuPhone(this.form.account);
    },
    proofList() {
      return { account: this.form.account };
    },
  },
  methods: {
    toPage(url) {
      switch (url) {
        case 'warehousesAdd':
          this.$toPage(this, 'warehousesAdd', {
            cached: true,
          });
      }
    },
    whChange(e) {
      if (e.obj && e.obj[0]) {
        this.form.ch_wh_name = e.obj[0].val;
        this.form.ch_wh_key = [e.obj[0].key];
      } else {
        this.form.ch_wh_name = '';
        this.form.ch_wh_key = [];
      }
      this.isShowPopup = false;
    },
    getData() {
      this.isLoading = true;
      // 查看成员
      this.$get('/member/getMember', {
        user_key: this.userKey,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false;
          let data = res.data.list[0];
          data.ch_wh_key = [data.ch_wh_key];
          data.sexName =
            data['sex'] == 1 ? '女' : data['sex'] == 2 ? '男' : '未知';
          data.statusName = data['status'] == 1 ? '正常' : '离职';
          data.code = undefined;
          if (data.entry_date == '0000-00-00') data.entry_date = undefined;
          if (data.quit_date == '0000-00-00') data.quit_date = undefined;
          if (data.birthday == '0000-00-00') data.birthday = undefined;

          let names = [];
          let keys = [];
          data.charge_mc &&
            data.charge_mc.forEach((item) => {
              names.push(item.value);
              keys.push(item.key);
            });

          data.charge_mc = keys;
          data.charge_mcName = names.join('、');

          data.roleObj = deepCopy(data.roles);
          data.role = [];

          this.form = data;
          this.nickname = data.nickname;
          this.initPhone = data.account;
        }
      });
    },
    // 添加成员
    onSubmit() {
      // 编辑成员
      let form = JSON.parse(JSON.stringify(this.form));
      form.ch_wh_key = form.ch_wh_key[0];
      if (this.form.status == '2') form.quit_date = undefined;
      if (this.form.account == this.initPhone) {
        form.code = undefined;
        form.account = undefined;
      } else if (form.account && !form.code) {
        this.$toast.fail('请输入验证码');
        return;
      } else {
        // 放行
      }
      this.btnLoading = true;
      this.$post('/member/setMember', form).then((res) => {
        this.btnLoading = false;
        if (res.code == 10000) {
          this.$toast.success('修改成功');
          this.$replace(this, {
            path: '/memberInfo',
            query: { userKey: this.userKey },
          });
        }
      });
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit();
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'mc':
          this.showMcPicker = false;
          break;
        case 'sex':
          this.form.sex = e[0].key;
          this.form.sexName = e[0].text;
          this.showSexPicker = false;
          break;
        case 'status':
          this.form.status = e[0].key;
          this.form.statusName = e[0].text;
          if (this.form.status == 2 && this.form.quit_date == undefined)
            this.form.quit_date = formatTime(new Date(), '-').slice(0, 10);
          this.showStatusPicker = false;
          break;
        case 'date':
          this.form.entry_date = e.toLocaleDateString().replace(/\//g, '-');
          this.showDatePicker = false;
          break;
      }
    },
    cancelPicker(type) {
      switch (type) {
        case 'role':
          this.showRolePicker = false;
          break;
        case 'mc':
          this.showMcPicker = false;
          break;
        case 'sex':
          this.showSexPicker = false;
          break;
        case 'status':
          this.showStatusPicker = false;
          break;
        case 'date':
          this.showDatePicker = false;
          break;
      }
    },
    defaultDate(val) {
      if (this.form[val]) return new Date(this.form[val].replace(/-/g, '/'));
      else return new Date();
    },
  },
};
</script>
<style lang="less" scoped>
.member-edit {
  .info {
    padding: 1rem;
  }

  .code-btn {
    color: #fff;
    background: @mainColor;
    height: 40px;
    font-size: 1rem;
    border-radius: 8px;
    margin-left: 4px;
  }

  /deep/ .yiyu-box-section {
    .title {
      display: none;
    }
  }
}
</style>

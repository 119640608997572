<template>
  <div>
    <div v-for="(pItem, pIndex) in arrs" :key="pIndex" class="wrap">
      <div class="yiyu-icon-field">
        <template v-for="(item, index) in pItem">
          <div v-if="item.opacity" :key="index">
            <div class="icon-field-item opacity" :class="item.class">
              <img class="w54 h54" :src="item.src" alt="" v-if="item.src" />
              <div class="w54 h54" v-else></div>
              <div class="txt">{{ item.txt }}</div>
            </div>
          </div>
          <div v-else :key="index" @click="toPage(item)" :class="item.class">
            <div
              class="icon-field-item"
              v-if="item.show || item.show == undefined"
            >
              <van-badge
                v-if="item.badge && item.src"
                :content="item.badge"
                max="99"
              >
                <img class="w54 h54" :src="item.src" alt="" v-if="item.src" />
              </van-badge>
              <img
                class="w54 h54"
                :src="item.src"
                alt=""
                v-else-if="item.src"
              />
              <div class="w54 h54" v-else></div>
              <div class="txt">{{ item.txt }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="divide" v-if="pIndex != arrs.length - 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'iconField',
  props: {
    // 图标列表数据
    data: {
      type: Array,
      default: () => [
        {
          txt: '新增客户',
          fn: 'addClient',
          src: require('@/assets/images/add_client.png'),
          show: false,
          badge: 5, // 徽标内容
        },
      ],
    },
    // 每栏显示的数量，默认只有一栏
    num: {
      type: Number,
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.dataDeal();
      },
    },
  },
  data() {
    return {
      column: this.num ? this.num : this.data.length,
      arrs: [], // 嵌套数组 [[],[],[]]
      dataCopy: undefined,
    };
  },
  methods: {
    toPage(item) {
      if (item.to) {
        this.$router.push(item.to);
      } else {
        this.$emit('click', item.fn);
      }
    },
    dataDeal() {
      this.arrs = [];
      let tmp = [];
      let n = this.column;
      this.dataCopy = JSON.parse(JSON.stringify(this.data));
      this.dataCopy = this.dataCopy.filter((item) => item.show != false);
      for (let i = 0; i < this.dataCopy.length; i++) {
        tmp.push(this.dataCopy[i]);
        n--;

        if (n == 0 || i == this.dataCopy.length - 1) {
          // 换行处理
          if (tmp.length != this.column) {
            // 占位不满的处理
            for (let j = 0; j < this.column - tmp.length + 1; j++) {
              // 确保空白占位一致
              let opacity = JSON.parse(JSON.stringify(this.dataCopy[0]));
              opacity.opacity = true;
              tmp.push(opacity);
            }
          }
          // push一行的数据
          this.arrs.push(JSON.parse(JSON.stringify(tmp)));
          tmp = [];
          n = this.column;
        }
      }
    },
  },
  mounted() {
    this.dataDeal();
  },
};
</script>

<style lang="less" scoped>
.yiyu-icon-field {
  display: flex;
  justify-content: space-around;
  color: @textColor;
  font-size: 1rem;

  .icon-field-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.divide {
  height: 0;
  border: 0.5px solid @lineColor;
  margin: 16px 0;
  flex-basis: 100%;
}

.txt {
  text-align: center;
  font-size: 1.1rem;
}

.opacity {
  opacity: 0;
}
</style>

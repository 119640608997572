<template>
  <div class="rele-table">
    <div
      class="single-ele-table sticky-el-table"
      v-myLoad="loading"
      style="position: relative"
    >
      <el-table
        ref="table"
        :header-cell-class-name="headerCellClassName"
        :cell-class-name="cellClassName"
        :row-class-name="rowClassName"
        :cell-style="{ color: '#666666' }"
        :data="tableData"
        size="small"
        header-row-class-name="tableHead"
        :highlight-current-row="true"
        :key="key"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="40" v-if="select">
        </el-table-column>
        <el-table-column
          v-for="(value, index) in headerData"
          :key="index"
          :prop="value.field"
          :label="value.show"
          align="center"
          :fixed="index == 0"
          :min-width="colWidthList[index]"
        >
          <template slot="header">
            <span
              class="data-cell"
              :class="{ 'main-c': value.link }"
              @click="ck(value.link)"
            >
              {{ value.show }}
            </span>
          </template>
          <template slot-scope="scope">
            <template>
              <span
                class="data-cell"
                :class="{
                  'main-c': linkKeys.includes(value.field),
                }"
                @click="ck(value.field, scope.row)"
              >
                {{ scope.row[value.field] && scope.row[value.field]['value'] }}
              </span>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { table } from '@/utils/mixins'
export default {
  name: 'releTable',
  mixins: [table],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Array,
      default: () => [],
    },
    select: {
      type: Boolean,
      default: false,
    },
    single: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columnData: [],
      colWidthList: [],
      loading: false,
      headerData: [],
      key: undefined,
      linkKeys: ['batch', 'mc_name', 'customer'], // 需要跳转的字段
      multipleSelection: [],
      re_info: [],
    }
  },
  watch: {
    selection: {
      deep: true,
      handler(val) {
        if (val.length == 0) {
          this.$refs.table.clearSelection()
          this.multipleSelection = val
        } else {
          this.multipleSelection = val
        }
      },
    },
    tableData: {
      deep: true,
      handler() {
        this.loading = true
        this.columnData = []
        this.header.forEach((item, index) => {
          if (index != 0) {
            let tmp = {}
            tmp['first-column'] = { value: item.show }
            this.tableData.forEach((item1, index1) => {
              tmp[index1] = item1[item.field]
            })
            this.columnData.push(tmp)
          }
        })
        this.$nextTick(() => {
          setTimeout(() => {
            this.colWidthList = this.adjustColumnWidth(this.$refs['table'].$el)
            this.loading = false
          })
        })
      },
    },
    header: {
      deep: true,
      handler() {
        this.key = new Date().getTime()
        this.headerData = this.getHeaderData()
        this.columnData = []
        this.header.forEach((item, index) => {
          if (index != 0) {
            let tmp = {}
            tmp['first-column'] = { value: item.show }
            this.tableData.forEach((item1, index1) => {
              tmp[index1] = item1[item.field]
            })
            this.columnData.push(tmp)
          }
        })
      },
    },
  },
  computed: {
    columnHeader() {
      let arr = []

      if (this.header && this.header.length) {
        arr = [
          {
            show: this.header[0]['show'],
            field: 'first-column',
          },
        ]

        let arrPush = true
        this.header.forEach((item, index) => {
          if (index != 0) {
            this.tableData.forEach((item1, index1) => {
              if (arrPush) {
                arr.push({
                  show: item1[this.header[0]['field']]['value'],
                  field: String(index1),
                  link: item1[this.header[0]['field']]['link'],
                })
              }
            })

            arrPush = false
          }
        })
      }
      return arr
    },
  },
  methods: {
    getHeaderData() {
      return this.header
    },
    ck(key, row) {
      switch (key) {
        case 'batch':
          this.$toPage(this, 'larvaProduct', {
            l_key: row['l_key']['value'],
            mc_key: row['mc_key']['value'],
          })
          break
        case 'mc_name':
          this.$toPage(this, 'farmDetail', {
            mc_key: row['mc_key']['value'],
          })
          break
        case 'customer':
          this.$toPage(this, 'salesDetail', {
            or_id: row['or_id']['value'],
          })
          // this.$toPage(this, 'clientDetail', {
          //   customer_key: row['customer_key']['value'],
          // })
          break
        case 'order':
          this.$toPage(this, 'salesDetail', {
            or_id: row['or_id']['value'],
          })
          break
      }
    },
    adjustColumnWidth(el) {
      let widthList = []

      el.querySelectorAll('tr').forEach((tr) => {
        tr.querySelectorAll('.data-cell').forEach((td, i) => {
          if (!widthList[i]) {
            widthList[i] = []
          }

          widthList[i].push(td.getBoundingClientRect().width + 16)
        })
      })

      return widthList.map((width) => Math.max(...width))
    },
    handleSelectionChange(val) {
      if (this.single) {
        // this.$refs.table.clearSelection();
        if (val.length > 1) {
          this.$refs.table.clearSelection()
          this.$refs.table.toggleRowSelection(val[val.length - 1])
        }
        this.multipleSelection = val[val.length - 1]
      } else {
        this.multipleSelection = val
      }

      this.$emit('change', val)
    },
  },
}
</script>

<style lang="less" scoped>
.sticky-el-table {
  /deep/ .el-table {
    .cell {
      white-space: nowrap;
      overflow: auto;
      text-overflow: initial;
    }
  }

  /deep/thead {
    .el-table-column--selection {
      .cell {
        display: none;
      }
    }
  }
}
</style>

<template>
  <tag-popup
    :cur-keys="roles"
    :show.sync="tmpShow"
    @confirmObj="confirmObj"
    @cancel="tmpShow = false"
    :opts="roleObj"
  ></tag-popup>
</template>

<script>
import tagPopup from '@/components/popup/tag'
import { compareArr, getKeyArr } from '@/utils/utils'
import { mapState } from 'vuex'
export default {
  name: 'bRolePicker',
  components: {
    tagPopup,
  },
  props: {
    // objs 的优先级高于 roles
    roles: Array, // sync 前端传给后端的
    names: String, // sync
    objs: Array, // sync 后端传给前端的
    show: Boolean, // sync
  },
  data() {
    return {
      tmpShow: false,
    }
  },
  computed: {
    ...mapState({
      roleObj: (state) => state.basic.roleObj,
    }),
  },
  created() {
    this.dataDeal()
    this.getNamesValue()
  },
  watch: {
    show(val) {
      this.tmpShow = val
    },
    tmpShow(val) {
      this.$emit('update:show', val)
    },
    roles: {
      deep: true,
      handler() {
        this.dataDeal()
        this.getNamesValue()
      },
    },
    objs: {
      deep: true,
      handler() {
        this.dataDeal()
      },
    },
  },
  methods: {
    dataDeal() {
      if (this.objs && this.objs.length) {
        let roles = getKeyArr(this.objs, 'role').map((item) => String(item))
        if (compareArr(roles, this.roles)) {
          // 不做任何处理
        } else {
          this.$emit('update:roles', roles)
        }
      } else if (this.roles && this.roles.length) {
        let objs = this.roles.map((item) => ({
          role: item,
          role_value: this.roleObj[item],
        }))
        this.$emit('update:objs', objs)
      }
    },
    confirmObj(e) {
      let roles = getKeyArr(e, 'key').sort()
      this.$emit(
        'update:objs',
        roles.map((item) => ({ role: item, role_value: this.roleObj[item] })),
      )
      this.tmpShow = false
    },
    getNamesValue() {
      let names = []
      if (Array.isArray(this.roles)) {
        this.roles.forEach((item) => {
          names.push(this.roleObj[item])
        })
      }
      this.$emit('update:names', names.join('、'))
    },
  },
}
</script>

<style></style>
